import React from 'react';
import HeaderText from '../components/HeaderText';
import { createUseStyles, useTheme } from 'react-jss';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import stockPhoto from '../images/about-us-stock.jpg';

const useStyles = createUseStyles(theme => ({
  aboutUs: {
    padding: theme.pageVerticalMargin + ' 0'
  },
  joinLink: {
    fontWeight: 'bold',
  },
  photo: {
    marginTop: '0.375rem',
    marginBottom: '1.5rem',
  }
}));

const AboutUsPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.aboutUs} data-cy={'aboutUs'}>
      <Row>
        <Col md={4}></Col>
        <Col md={8}>
          <p><HeaderText level={1}>About Us</HeaderText></p>
        </Col>
      </Row>
      <Row>
        <Col md={4}></Col>
        <Col md={8}>
          <p><HeaderText level={2} color={theme.colorPrimary}>IAAM proudly stands for It’s All About Me.</HeaderText></p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <img width="100%" className={classes.photo} src={stockPhoto} data-cy={'stockPhoto'}/>
        </Col>
        <Col xs={12} md={8}>
          <p>IAAM was created to modernize recruitment in our community and make this all about YOU. All too often great talent gets buried under a sea of resumes when applying for a job. Traditional paper resumes provide good insight into a person’s experience, but it does not speak to their personality or indicate if they are a great culture for an organization.</p>
          <p>IAAM was developed to help Employers find talented individuals through a video library and saves time screening and interviewing candidates. IAAM is all about the candidates, helping their amazing personalities to be heard and making them more than just a name of a piece of paper.</p>
          <p>The Founder has over a decade of experience in traditional recruitment and runs a successful agency in Yaletown, Vancouver. The birth of IAAM came from years of experience dealing with candidates who get passed on, based on the design and content of their paper resumes versus their personality and drive.</p>
          <p>IAAM is proudly founded and developed in Canada by an all Canadian development team.</p>
          <p><Link to="/join" className={classes.joinLink} data-cy={'joinLink'}>Click here to join IAAM</Link></p>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUsPage;
