import { APP_UPDATE_HIDE_ALERT, NEW_APP_CONTENT_AVAILABLE } from '../actionTypes';

const defaultState = {
  newAppContentAvailable: false,
  hideNewContentAlert: false,
};

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case NEW_APP_CONTENT_AVAILABLE: {
      return { ...state, newAppContentAvailable: true };
    }
    case APP_UPDATE_HIDE_ALERT: {
      return { ...state, hideNewContentAlert: true };
    }
    default:
      return state;
  }
}
