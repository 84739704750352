import { takeLatest, put } from 'redux-saga/effects';
import {
  SHORTLIST_CANDIDATE,
  RATE_CANDIDATE_VIDEO,
  RATE_CANDIDATE,
  FAVORITE_CANDIDATE,
  SHORTLIST_CANDIDATE_SUCCESS,
  SHORTLIST_CANDIDATE_FAILED,
  RATE_CANDIDATE_VIDEO_SUCCESS,
  RATE_CANDIDATE_VIDEO_FAILED,
  RATE_CANDIDATE_SUCCESS,
  RATE_CANDIDATE_FAILED,
  FAVORITE_CANDIDATE_SUCCESS,
  FAVORITE_CANDIDATE_FAILED,
  POPULATE_CANDIDATE_DETAILS, POPULATE_CANDIDATE_DETAILS_SUCCESS, POPULATE_CANDIDATE_DETAILS_FAILED
} from './actions';
import apiClient from '../../utils/api';
import {
  authenticateRequest,
  authenticateRequestWithConfig,
} from '../../auth/sagas';
import {
  UNSHORTLIST_CANDIDATE, UNSHORTLIST_CANDIDATE_FAILED,
  UNSHORTLIST_CANDIDATE_SUCCESS,
} from '../../actionTypes';

function * shortlistCandidate(action) {
  try {
    const { candidateId, employerId, categoryTag } = action.payload;
    const { history } = action.meta;

    yield authenticateRequest(apiClient.post, 'v1/candidatedetails/shortlistCandidate', { candidateId, employerId, categoryTag });

    yield put({
      type: SHORTLIST_CANDIDATE_SUCCESS,
      payload: { candidateId, employerId, categoryTag }
    });
    history.push('/shortlisted');
  } catch (error) {
    console.error(error);
    yield put({
      type: SHORTLIST_CANDIDATE_FAILED,
      payload: error,
    });
  }
}

export function * watchShortlistCandidate() {
  yield takeLatest(SHORTLIST_CANDIDATE, shortlistCandidate);
}

function * unshortlistCandidate(action) {
  try {
    const { candidateId, employerId } = action.payload;

    yield authenticateRequest(apiClient.post, 'v1/candidatedetails/unshortlistCandidate', { candidateId, employerId });

    yield put({
      type: UNSHORTLIST_CANDIDATE_SUCCESS,
      payload: { candidateId, employerId }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: UNSHORTLIST_CANDIDATE_FAILED,
      payload: error,
    });
  }
}

export function * watchUnshortlistCandidate() {
  yield takeLatest(UNSHORTLIST_CANDIDATE, unshortlistCandidate);
}

function * rateCandidateVideo(action) {
  try {
  const { candidateId, employerId, videoId, rating, } = action.payload;

  yield authenticateRequest(apiClient.post, 'v1/candidatedetails/rateCandidateVideo', { candidateId, employerId, videoId, rating });

  yield put({
    type: RATE_CANDIDATE_VIDEO_SUCCESS,
    payload: { candidateId, employerId, videoId, rating }
  });
} catch (error) {
  console.error(error);
  yield put({
    type: RATE_CANDIDATE_VIDEO_FAILED,
    payload: error,
  });
}
}

export function * watchRateCandidateVideo() {
  yield takeLatest(RATE_CANDIDATE_VIDEO, rateCandidateVideo);
}

function * rateCandidate(action) {
  try {
  const { candidateId, employerId, rating, } = action.payload;

  yield authenticateRequest(apiClient.post, 'v1/candidatedetails/rateCandidate', { candidateId, employerId, rating });

  yield put({
    type: RATE_CANDIDATE_SUCCESS,
    payload: { candidateId, employerId, rating }
  });
} catch (error) {
  console.error(error);
  yield put({
    type: RATE_CANDIDATE_FAILED,
    payload: error,
  });
}
}

export function * watchRateCandidate() {
  yield takeLatest(RATE_CANDIDATE, rateCandidate);
}

function * favoriteCandidate(action) {
  try {
  const { candidateId, employerId } = action.payload;

  yield authenticateRequest(apiClient.post, 'v1/candidatedetails/favoriteCandidate', { candidateId, employerId });

  yield put({
    type: FAVORITE_CANDIDATE_SUCCESS,
    payload: { candidateId, employerId }
  });
} catch (error) {
  console.error(error);
  yield put({
    type: FAVORITE_CANDIDATE_FAILED,
    payload: error,
  });
}
}

export function * watchFavoriteCandidate() {
  yield takeLatest(FAVORITE_CANDIDATE, favoriteCandidate);
}

function * populateCandidateDetails(action) {
  try {
    const { candidateId } = action.payload;

    const result = yield authenticateRequestWithConfig(
      apiClient.get,
      'v1/candidatedetails/query',
      {
        params: {
          candidateId,
        }
      }
    );

    yield put({
      type: POPULATE_CANDIDATE_DETAILS_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: POPULATE_CANDIDATE_DETAILS_FAILED,
      payload: error,
    });
  }
}

export function * watchPopulateCandidateDetails() {
  yield takeLatest(POPULATE_CANDIDATE_DETAILS, populateCandidateDetails);
}


