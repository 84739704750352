import {
  AUTH_INVALID_ERROR,
  AUTH_REFRESH_ERROR, AUTH_RESTORE,
  LOGIN_USER, LOGIN_USER_FAILED,
  LOGOUT_USER, AUTH_FORCE_REFRESH,
} from '../actionTypes';

export function logoutUser () {
  return { type: LOGOUT_USER };
}

export function loginUser (credentials, history, from) {
  return {
    type: LOGIN_USER,
    payload: { grant_type: 'password', ...credentials },
    meta: { history, from }
  };
}

export function loginUserFailed(payload, meta) {
  return {
    type: LOGIN_USER_FAILED,
    payload,
    meta
  };
}

export function authInvalidError (response) {
  return {
    type: AUTH_INVALID_ERROR,
    payload: response
  };
}

export function authRefreshError (payload) {
  return {
    type: AUTH_REFRESH_ERROR,
    payload
  };
}

export function authRestore (payload) {
  return {
    type: AUTH_RESTORE,
    payload
  };
}

export function authForceRefresh (validate = () => {}) {
  return {
    type: AUTH_FORCE_REFRESH,
    payload: { validate }
  };
}

function validatePayment (jwtToken) {
  if (!jwtToken.payment.userPaid) {
    console.log('User has been successfully paid but user readModel has yet to acknowledge it.');
    throw new Error('User has been successfully paid but user readModel has yet to acknowledge it.');
  }
}

export function authForcePaymentRefresh () {
  return authForceRefresh(validatePayment);
}
