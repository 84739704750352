import {
  SUBMIT_CONTACT_FORM, SUBMIT_CONTACT_FORM_FAILED,
  SUBMIT_CONTACT_FORM_SUCCESS,
} from '../actionTypes';
import { errorMessageFromPayload } from '../utils/errors';

const defaultState = Object.freeze({
  sent: false,
  error: ''
});

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case SUBMIT_CONTACT_FORM: {
      return { ...defaultState };
    }
    case SUBMIT_CONTACT_FORM_SUCCESS: {
      return { ...defaultState, sent: true };
    }
    case SUBMIT_CONTACT_FORM_FAILED: {
      return { ...defaultState, error: errorMessageFromPayload(action.payload) };
    }
    default:
      return state;
  }
}
