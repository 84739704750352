import React from 'react';

const HomeLayout = ({ AboveTheFold, BelowTheFold, Headline, BelowTheFoldHeaderText }) => {
  return (
    <>
      <main>
        <article>
          {Headline}
        </article>
        <article>
          {AboveTheFold}
        </article>
        <aside>
          {BelowTheFold}
        </aside>
      </main>
    </>
  );
};

export default HomeLayout;
