import {
  ACCEPT_CANDIDATE_OFFER_SUCCESS,
  POPULATE_CANDIDATE_OFFER_SUCCESS,
  POPULATE_CANDIDATE_OFFERS_SUCCESS, REJECT_CANDIDATE_OFFER_SUCCESS,
} from './actions';
import {
  FETCH_CANDIDATE_VIDEOS,
  FETCH_CANDIDATE_VIDEOS_FAILED,
  FETCH_CANDIDATE_VIDEOS_SUCCESS,
  POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS,
  REGISTER_CANDIDATE,
  REGISTER_CANDIDATE_FAILED,
  APPLY_PROMO_CODE
} from '../../actionTypes';
import { errorsFromPayload } from '../../utils/errors';
import update from 'immutability-helper';

const defaultState = {
  interestsVideoApprovalStatus: 'approved', // ['noVideo', 'pending', 'rejected', 'approved']
  // interestsVideoAvgRating: 4.4,
  hobbiesVideoApprovalStatus: 'approved', // ['noVideo', 'pending', 'rejected', 'approved']
  // hobbiesVideoAvgRating: 3.5,
  questions: [
    {
      questionId: 'c773617e-b387-48e2-af6f-4f7b4fe84d21',
      title: 'Introduce yourself',
      description: `Hi I am <your name>
        I am based in <location>
        My current experience is in <job title>
        And i'm looking for a role as a <job title> or <job title 2>
        I am really passionate about <two lines on your passion>
        I am available to start a new role <mention your availability>
        Thanks for viewing my video with IAAM`
      ,
    },
  ],
  selectedQuestionId: 'c773617e-b387-48e2-af6f-4f7b4fe84d21',
  videos: [], // TODO move
  offers: [],
  shortlist: [],
  offer: {}, // TODO - should contain only currentOfferId. All offer entity info needs to rest in offers,
  // and offers need to be a javaScript Map to make single record retrieval fast. One source of truth
  registrationErrors: {},
  promoCodeToApply: '',
  lastPromoCodeAttempted: 0,
  promoFromApi: {}
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case POPULATE_CANDIDATE_OFFERS_SUCCESS: {
      const offers = action.payload.result.data;
      return { ...state, offers: offers };
    }
    case POPULATE_CANDIDATE_OFFER_SUCCESS: {
      return { ...state, offer: action.payload, showAcceptedOfferConfirm: false };
    }
    case FETCH_CANDIDATE_VIDEOS: {
      return state;
    }
    case FETCH_CANDIDATE_VIDEOS_SUCCESS: {
      return { ...state, videos: action.payload };
    }
    case FETCH_CANDIDATE_VIDEOS_FAILED: {
      return state;
    }
    case POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS: {
      const shortlist = action.payload.response.data;
      return { ...state, shortlist: shortlist };
    }
    case REGISTER_CANDIDATE: {
      return { ...state, registrationErrors: {} };
    }
    case REGISTER_CANDIDATE_FAILED: {
      const { error } = action.payload;
      return { ...state, registrationErrors: errorsFromPayload(error) };
    }
    case ACCEPT_CANDIDATE_OFFER_SUCCESS: {
      return update(state, {
        offer: {
          offerStatus: {
            $set: 'accepted'
          }
        },
        showAcceptedOfferConfirm: {
          $set: true
        }
      });
    }
    case REJECT_CANDIDATE_OFFER_SUCCESS: {
      return update(state, {
        offer: {
          offerStatus: {
            $set: 'rejected'
          }
        }
      });
    }
    case APPLY_PROMO_CODE: {
      const { lastAttempt, promoCode } = action.payload;
      return update(state, {
        lastPromoCodeAttempted: {
          $set: lastAttempt
        },
        promoCodeToApply: {
          $set: promoCode
        }
      });
    }
    default:
      return state;
  }
}
