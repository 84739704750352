import immutabilityHelper from 'immutability-helper';
import {
  EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS,
  EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED,
  EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS,
  UNSHORTLIST_CANDIDATE_SUCCESS,
} from '../../actionTypes';
import { SHORTLIST_CANDIDATE_SUCCESS } from '../candidateDetails/actions';

const defaultState = Object.freeze({
  introVideos: {} // map<tag:string,sectionstate:{}>
});
const defaultSectionState = Object.freeze({
  loading: false,
  total: 0,
  results: [],
  error: ''
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS: {
      const { tag } = action.payload;
      return { ...state, introVideos: {
        ...state.introVideos,
        [tag]: { ...(state.introVideos[tag] || defaultSectionState), loading: true }
      } };
    }
    case EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS: {
      const { tag, results, total } = action.payload;
      return { ...state, fetchingIntroVideos: false, introVideos: {
        ...state.introVideos,
        [tag]: { ...state.introVideos[tag], loading: false, total, results }
      } };
    }
    case EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED: {
      const { tag, error } = action.payload;
      return { ...state, fetchingIntroVideos: false, introVideos: {
          ...state.introVideos,
          [tag]: { ...state.introVideos[tag], loading: false, error: error.message }
        } };
    }
    case SHORTLIST_CANDIDATE_SUCCESS: {
      const { candidateId, employerId } = action.payload;
      const $spec = Object.keys(state.introVideos).reduce((spec, tag) => {
        spec[tag] = {
          results: {
            $set: state.introVideos[tag].results.map(introVideo => {
              const $innerSpec = {};
              if (introVideo.candidateId === candidateId) {
                $innerSpec.shortlistedBy = {
                  $push: [employerId]
                };
              }
              return immutabilityHelper(introVideo, $innerSpec);
            })
          }
        };
        return spec;
      }, {});
      return { ...state, introVideos: immutabilityHelper(state.introVideos, $spec) };
    }
    case UNSHORTLIST_CANDIDATE_SUCCESS: {
      const { candidateId, employerId } = action.payload;
      const changes = Object.entries(state.introVideos).reduce(
        (changes, [tag, data]) => {
          if (data.results.some(
            x => x.candidateId === candidateId
              && x.shortlistedBy.includes(employerId)
          )) {
            changes[tag] = {
              ...data,
              results: data.results.map(introVideo => ({
                ...introVideo,
                shortlistedBy: introVideo.candidateId === candidateId
                  ? introVideo.shortlistedBy.filter(x => x !== employerId)
                  : introVideo.shortlistedBy,
              }))
            };
          }
          return changes;
        },
        {}
      );
      return { ...state, introVideos: { ...state.introVideos, ...changes } };
    }
    default:
      return state;
  }
}
