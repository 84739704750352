import Navbar from "../components/AppNavbar";
import Legal from "../components/Legal";
import AppUpdateAlert from "../appUpdate/AppUpdateAlert";
import React from "react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  appContainer: {
    backgroundColor: theme.colorBackground,
    minHeight: "100%",
    overflowX: "hidden",
  },
  navbarWrapper: {
    backgroundColor: theme.navbarBackgroundColor,
    boxShadow: theme.navBoxShadow,
  },
  appContent: {
    color: theme.colorText,
    ...theme.bodyText,
    margin: "auto",
    maxWidth: theme.maxWidth,
    padding: "0 1.25rem",
  },
  main: {
    margin: "0 0",
    minHeight: "calc(100vh - 12.5rem)",
  },
  footer: {
    margin: "0 0",
    maxHeight: "6rem",
  },
}));

const AppLayout = ({ TopLevelRoutes }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.appContainer}>
      <header>
        <nav className={classes.navbarWrapper}>
          <Navbar />
        </nav>
        <AppUpdateAlert />
      </header>
      <div className={classes.appContent}>
        <main className={classes.main}>
          <TopLevelRoutes />
        </main>
        <footer className={classes.footer}>
          <Legal productName={"IAAM"} />
        </footer>
      </div>
    </div>
  );
};

export default AppLayout;
