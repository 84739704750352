import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../auth/actions";
import { isLoggedIn } from "../auth/selectors";
import { createUseStyles, useTheme } from "react-jss";
import logo from "../logo.svg";
import { ReactComponent as LoginIcon } from "../icons/login.svg";

const useStyles = createUseStyles((theme) => ({
  navbar: {
    maxWidth: theme.maxWidth,
    padding: "1.6rem 1.25rem",
    margin: "auto",
    minHeight: "6rem",
  },
  navLink: {
    ...theme.navigationText,
    ...theme.link,
    padding: "1.5rem 0",
    textTransform: "uppercase",
    minWidth: "7.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.responsiveMd]: {
      padding: "0.5rem 1rem",
    }
  },
  joinNow: {
    [theme.responsiveMd]: {
      border: "solid 1px #FAFAFA",
      borderRadius: "1rem",
      boxShadow: "0px 0.125rem 0.125rem rgba(0, 0, 0, 0.1)",
    }
  },
}));

const AppNavbar = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const authenticated = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  return (
    <Navbar className={classes.navbar} expand="md" collapseOnSelect data-cy={'navbar'}>
      <Navbar.Brand as={Link} to="/">
        <img src={logo} alt="iaam" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link
            className={classes.navLink}
            eventKey={1}
            as={Link}
            to="/about-us"
          >
            <span>About Us</span>
          </Nav.Link>
          <Nav.Link
            className={classes.navLink}
            eventKey={2}
            as={Link}
            to="/contact"
          >
            <span>Contact</span>
          </Nav.Link>
        </Nav>
        <Nav>
          {!authenticated && (
            <Nav.Link
              className={classes.navLink}
              eventKey={10}
              as={Link}
              to="/login"
            >
              <LoginIcon></LoginIcon>
              &nbsp;&nbsp;Log In
            </Nav.Link>
          )}
          {!authenticated && (
            <Nav.Link
              className={[classes.navLink, classes.joinNow].join(" ")}
              eventKey={10}
              as={Link}
              to="/join"
              data-cy={'joinButton'}
            >
              Join Now
            </Nav.Link>
          )}
          {authenticated && (
            <Nav.Link
              className={classes.navLink}
              eventKey={10}
              as={Link}
              to="/login?logout=true"
              onSelect={handleLogout}
              data-cy={'logout'}
            >
              Logout
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
