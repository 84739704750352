import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  loadingMessage: {
    fontFamily: 'Arial',
  },
});

const LoadingView = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingMessage}>
      Loading...
    </div>
  );
};

export default LoadingView;