import {
  EMPLOYER_FETCH_CANDIDATE_PROFILE, EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED,
  EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS,
} from '../../actionTypes';
import { errorMessageFromPayload } from '../../utils/errors';

const defaultState = {
  loaded: false,
  loading: false,
  error: '',
  candidateId: '',
  thumbUrl: '',
  videoUrl: '',
  name: '',
  description: '',
  videoId: '',
  rate: 0,
  location: '',
  availability: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case EMPLOYER_FETCH_CANDIDATE_PROFILE: {
      const { candidateId } = action.payload;
      return { ...defaultState, candidateId, loading: true };
    }
    case EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS: {
      return { ...state, loading: false, loaded: true, ...action.payload };
    }
    case EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED: {
      return { ...state, loading: false, error: errorMessageFromPayload(action.payload) };
    }
    default:
      return state;
  }
}
