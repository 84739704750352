import {
  AUTH_INVALID_ERROR,
  AUTH_REFRESH_SUCCESS,
  AUTH_RESTORE,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS, MODIFY_CANDIDATE_DETAILS_SUCCESS,
} from '../actionTypes';
import { parseJwtToken } from '../utils/parseJwtToken';

const defaultState = Object.freeze({
  loggedIn: false,
  access_token: null,
  created_at: null,
  expires_in: null,
  refresh_token: null,
  token_type: '',
  jwt: null,
  loginError: '',
});

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case AUTH_RESTORE: {
      const auth = action.payload;
      return { loggedIn: true, ...auth, jwt: parseJwtToken(auth.access_token) };
    }
    case AUTH_REFRESH_SUCCESS:
    case LOGIN_USER_SUCCESS: {
      const { token } = action.payload;
      return { ...state, ...token, loggedIn: true, jwt: parseJwtToken(token.access_token) };
    }
    case MODIFY_CANDIDATE_DETAILS_SUCCESS: {
      const { token } = action.meta;
      return { ...state, ...token, loggedIn: true, jwt: parseJwtToken(token.access_token) };
    }
    case LOGIN_USER_FAILED: {
      return { ...state, loggedIn: false, loginError: errorMessage(action.payload) };
    }
    case AUTH_INVALID_ERROR:
    case LOGOUT_USER_SUCCESS: {
      return { ...defaultState };
    }
    default:
      return state;
  }
}

function errorMessage (err) {
  let msg = err;
  if (typeof err === 'object') msg = err.message;
  return msg || 'wrong username or password';
}
