import { Alert } from 'react-bootstrap';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHideNewContentAlert, getNewAppContentAvailable } from './selectors';
import { hideAlert } from './actions';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 5,
    left: 5,
    right: 5,
    width: 'auto'
  },
  heading: {
    color: ({ theme }) => theme.colorSecondary
  },
  message: {
    color: ({ theme }) => theme.colorDark
  }
});

const AppUpdateAlert = ({ forceShow }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const newAppContentAvailable = useSelector(getNewAppContentAvailable);
  const hideNewContentAlert = useSelector(getHideNewContentAlert);
  const showAlert = forceShow || (newAppContentAvailable && !hideNewContentAlert);
  const handleAlertClick = (e) => {
    e.preventDefault();
    window.location.reload(true);
  };
  const handleDismissAlert = () => {
    dispatch(hideAlert());
  };
  return (
    <Alert className={classes.container} variant="info" show={showAlert}
           onClose={handleDismissAlert} dismissible>
      <Alert.Heading className={classes.heading}>Update Available!</Alert.Heading>
      <p className={classes.message}>
        Click <Alert.Link onClick={handleAlertClick}>here</Alert.Link> to update now.
      </p>
    </Alert>
  );
};

export default AppUpdateAlert;
