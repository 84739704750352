import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Menlo, monospace',
    fontSize: '100px',
    textShadow: '1px 2px rgba(0, 0, 0, 0.5)',
  },
});

const Countdown = ({
  countdownTime,
}) => {
  const classes = useStyles();
  const [number, setNumber] = useState(countdownTime / 1000);
  useEffect(() => {
    let timeout;
    const updateNumber = () => {
      setNumber(number => {
        const nextNumber = number - 1;
        if (nextNumber !== 0) {
          timeout = setTimeout(updateNumber, 1000);
        }
        return nextNumber;
      });
    };
    timeout = setTimeout(updateNumber, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className={classes.root}>{number || null}</div>
  );
};

export default Countdown;