import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  tabHeader: {
    fontWeight: 'bold',
    lineHeight: '1em',
    margin: 0,
    paddingBottom: '2.7778em',
    verticalAlign: 'text-top',
    [theme.responsiveXl]: {
      fontSize: '1rem',
    },
  },
}));

const TabHeader = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (<h4 className={classes.tabHeader} data-cy={'tabHeader'}>{children}</h4>);
};

export default TabHeader;