import HomePage from './HomePage';
import { Route, Switch } from 'react-router-dom';
import LoginPage from './LoginPage';
import React from 'react';
import CandidateRoutes from './candidate/Routes';
import EmployerRoutes from './employer/Routes';
import AdminRoutes from './admin/Routes';
import NotFound from './NotFound';
import JoinPage from './JoinPage';
import AboutUsPage from './AboutUsPage';
import ContactPage from './ContactPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfUsePage from './TermsOfUsePage';

const TopLevelRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage}/>
      <Route exact path="/login" component={LoginPage}/>
      <Route exact path="/join" component={JoinPage}/>
      <Route exact path="/about-us" component={AboutUsPage}/>
      <Route exact path="/contact" component={ContactPage}/>
      <Route exact path="/policy" component={PrivacyPolicyPage}/>
      <Route exact path="/terms-of-use" component={TermsOfUsePage}/>
      <Route path="/employer" component={EmployerRoutes}/>
      <Route path="/candidate" component={CandidateRoutes}/>
      <Route path="/admin" component={AdminRoutes}/>
      <Route component={NotFound}/>
    </Switch>
  );
};

export default TopLevelRoutes;
