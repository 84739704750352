import { useDispatch, useSelector } from 'react-redux'
import { getCandidateIntroVideo, getCandidateVideoStatusText } from './selectors'
import React, { useEffect } from 'react'
import { fetchCandidateVideos } from './actions'
import { createUseStyles, useTheme } from 'react-jss'
import VideoDisplay from '../../components/VideoDisplay'
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'
import TabHeader from '../../components/TabHeader'

const useStyles = createUseStyles({
  box: {
    padding: 10,
    maxWidth: 640,
    border: '1px solid gray',
    borderRadius: 5,
  },
  videoResume: {
    maxWidth: 854,
    '& > div': {
      marginBottom: 32,
    },
  },
})

const VideoDetails = ({ video }) => {
  const { rejectedReason } = video
  const statusText = useSelector(getCandidateVideoStatusText(video))
  return (
    <div data-cy={'videoStatus'}>
      <p>
        <b>Status:</b> {statusText}
        {rejectedReason && (
          <>
            {' '}
            <b>Reason:</b> {rejectedReason}
          </>
        )}
      </p>
      <VideoDisplay video={video}/>
    </div>
  )
}

const VideoResumeTab = () => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles({ theme })
  const dispatch = useDispatch()
  const video = useSelector(getCandidateIntroVideo)
  useEffect(() => {
    dispatch(fetchCandidateVideos())
  }, [dispatch])
  const handleRecordVideo = () => history.push('/dashboard/record-video')
  const IntroVideo = () => {
    return (
      <>
        <TabHeader>Your introduction video</TabHeader>
        {video && <VideoDetails video={video}/>}
        <Button onClick={handleRecordVideo} data-cy={'recordVideoButton'}>Record New Video</Button>
      </>
    )
  }
  return (
    <div className={classes.videoResume} data-cy={'permission'}>
      <IntroVideo/>
    </div>
  )
}

export default VideoResumeTab
