import React from 'react';
import Button from '../components/Button';

const Paginator = ({ page, pageSize, total, onChange }) => {
  const last = Math.ceil(total / pageSize);
  const isFirst = page === 1;
  const isLast = page === last;
  const buttons = [];
  buttons.push(
    <Button key="first" disabled={isFirst} onClick={() => onChange(1)}>
      &lt;&lt;
    </Button>,
    <Button key="prev" disabled={isFirst} onClick={() => onChange(page - 1)}>
      &lt;
    </Button>,
  );
  for (let i = 1; i <= last; i++) {
    buttons.push(
      <Button key={`page${i}`} disabled={page === i}
              onClick={() => onChange(i)}>
        {i}
      </Button>
    );
  }
  buttons.push(
    <Button key="next" disabled={isLast} onClick={() => onChange(page + 1)} data-cy={'firstPage'}>
      &gt;
    </Button>,
    <Button key="last" disabled={isLast} onClick={() => onChange(last)} data-cy={'lastPage'}>
      &gt;&gt;
    </Button>,
  );
  return buttons;
};

export default Paginator;
