import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  candidateDashboard: {
    padding: '0.5rem 0',
    [theme.responsiveMd]: {
      padding: theme.pageVerticalMargin + ' 0',
    }
  },
}));

const CandidateDashboardLayout = ({ AboveTheFold, BelowTheFold, Headline, BelowTheFoldHeaderText }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <main className={classes.candidateDashboard}>
        <article>
          <h2>{Headline}</h2>
          {AboveTheFold}
        </article>
        <aside>
          <h2>{BelowTheFoldHeaderText}</h2>
          {BelowTheFold}
        </aside>
      </main>
    </>
  );
};

export default CandidateDashboardLayout;
