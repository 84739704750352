import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { Row, Col, Form } from 'react-bootstrap';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import HeaderText from '../components/HeaderText';
import IAAMForm from '../components/IaamForm';
import FormGroup from '../components/FormGroup';
import FormControl from '../components/FormControl';
import Button from '../components/Button';
import { submitContactForm } from './actions';
import { getContactFormValidationSchema, getContactForm } from './selectors';

const reCaptchaKey = '6LcQ0gEVAAAAAJfGyEuodWXMwoSZ8cT4UbaLb9wI';

const useStyles = createUseStyles(theme => ({
  contact: {
    padding: theme.pageVerticalMargin + ' 0'
  },
  mapOuter: {
    position: 'relative',
    textAlign: 'right',
    width: '100%',
    height: '30vh',
    marginBottom: '1rem'
  },
  gmap_canvas: {
    overflow: 'hidden',
    background: 'none!important',
    width: '100%',
    height: '100%',
    '&>iframe': {
      width: '100%',
      height: '100%',
    }
  }
}));

const ContactPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState();
  const [reCaptchaToken, setReCaptchaToken] = useState();
  const { addToast } = useToasts();
  const { sent, error } = useSelector(getContactForm);

  useEffect(() => {
    loadReCaptcha(reCaptchaKey);
  }, [reCaptchaKey]);
  useEffect(() => {
    if (!submitted) return;
    if (error) {
      addToast(error, { appearance: 'error' });
    } else if (sent) {
      addToast('Sent', { appearance: 'success' });
    }
  }, [submitted, sent, error]);

  return (
    <div className={classes.contact} data-cy={'contactUs'}>
      <Row>
        <Col xs={12}>
          <p><HeaderText level={1}>Reach out to Us</HeaderText></p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <p><HeaderText level={2} color={theme.colorPrimary}>Drop us a line!</HeaderText></p>
          {(submitted && sent)
            ? <p data-cy={'messageSent'}>Thank you for your message! Someone on our team will get back to you within 48 hours.</p>
            : <Formik
              validationSchema={getContactFormValidationSchema}
              onSubmit={values => {
                const data = {
                  ...values,
                  reCaptchaToken
                };
                dispatch(submitContactForm(data));
                setSubmitted(true);
              }}
              initialValues={{
                name: '',
                email: '',
                message: '',
              }}>
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                values,
                touched,
                isInvalid,
                errors,
              }) => (
                <IAAMForm onSubmit={handleSubmit} noVerticalMargin fullWidth>
                  <FormGroup>
                    <FormControl type="text" name="name" placeholder="Name"
                                 value={values.name}
                                 onChange={handleChange} onBlur={handleBlur}
                                 isInvalid={touched.name && !!errors.name}
                                 data-cy={'name'}
                    />
                    <Form.Control.Feedback type="invalid" data-cy={'nameError'}>
                      {errors.name}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup>
                    <FormControl type="email" name="email" placeholder="Email"
                                 value={values.email}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 isInvalid={touched.email && !!errors.email}
                                 data-cy={'email'}
                    />
                    <Form.Control.Feedback type="invalid" data-cy={'emailError'}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup>
                    <FormControl as="textarea" rows="4" name="message" placeholder="Message"
                                 value={values.message}
                                 onChange={handleChange} onBlur={handleBlur}
                                 isInvalid={touched.message && !!errors.message}
                                 data-cy={'message'}
                    />
                    <Form.Control.Feedback type="invalid" data-cy={'messageError'}>
                      {errors.message}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <p>This site is protected by reCAPTCHA and the Google <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                  <ReCaptcha sitekey={reCaptchaKey} action="submit" verifyCallback={setReCaptchaToken}/>
                  <FormGroup>
                    <Button type="submit" width="100%" disabled={isInvalid} data-cy={'submit'}>Send</Button>
                  </FormGroup>
                </IAAMForm>
              )}
            </Formik>
          }
        </Col>
        <Col xs={12} md={6}>
          <p><HeaderText level={2} color={theme.colorPrimary}>Sign Up to our Platform today!</HeaderText></p>
          <p>
            We practice ethical recruitment at IAAM. That means all candidates get an equal opportunity to get in front of the Companies who use IAAM for their hiring.<br/>
            The result? We match only the best talent to the hottest opportunities on the market.
          </p>
          <p><HeaderText level={2} color={theme.colorPrimary}>Its All About Me (IAAM)</HeaderText></p>
          <div className={classes.mapOuter}>
            <div className={classes.gmap_canvas}>
              <iframe id="gmap_canvas"
                      src="https://maps.google.com/maps?q=1080%20Mainland%2C%20Vancouver%2C%20BC&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      frameBorder="0" scrolling="no" marginHeight="0"
                      marginWidth="0"/>
            </div>
          </div>
          <p>
            1080 Mainland Street,<br/>
            Vancouver, British Columbia V6B 2T4,<br/>
            Canada<br/>
            Email : <a href="mailto:info@iaam.ca">info@iaam.ca</a><br/>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default ContactPage;
