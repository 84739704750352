import React from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  form: {
    padding: ({ noVerticalMargin }) => noVerticalMargin ? '0 0' : theme.pageVerticalMargin + ' 0',
    [theme.responsiveSm]: {
      width: ({ fullWidth }) => fullWidth ? '100%' : 464,
    }
  }
}));

const IaamForm = ({ noVerticalMargin, fullWidth, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, noVerticalMargin, fullWidth });
  return (<Form className={classes.form} {...props} />);
};

export default IaamForm;
