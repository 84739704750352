import {
  ADMIN_BLOCK_EMPLOYER, ADMIN_DOWNLOAD_BILLING_REPORT, ADMIN_FETCH_BILLING_REPORT,
  ADMIN_FETCH_CANDIDATES,
  ADMIN_FETCH_EMPLOYERS,
  ADMIN_FIND_CANDIDATE_BY_VIDEO_ID, ADMIN_UNBLOCK_EMPLOYER,
  APPROVE_VIDEO,
  FETCH_PENDING_APPROVAL_VIDEOS,
  REJECT_VIDEO,
} from '../../actionTypes'

export function fetchPendingApprovalVideos (page = 1, pageSize = 20) {
  return {
    type: FETCH_PENDING_APPROVAL_VIDEOS,
    payload: { page, pageSize },
  };
}

export function approveVideo (videoId) {
  return {
    type: APPROVE_VIDEO,
    payload: { videoId },
  };
}

export function rejectVideo (videoId, reason) {
  return {
    type: REJECT_VIDEO,
    payload: { videoId, reason },
  };
}

export function fetchCandidates (page = 1, pageSize = 20) {
  return {
    type: ADMIN_FETCH_CANDIDATES,
    payload: { page, pageSize },
  };
}

export function findCandidateByVideoId (videoId) {
  return {
    type: ADMIN_FIND_CANDIDATE_BY_VIDEO_ID,
    payload: { videoId },
  };
}

export function fetchEmployers (page = 1, pageSize = 20) {
  return {
    type: ADMIN_FETCH_EMPLOYERS,
    payload: { page, pageSize },
  };
}

export function blockEmployer (employerId) {
  return {
    type: ADMIN_BLOCK_EMPLOYER,
    payload: { employerId }
  };
}

export function unblockEmployer (employerId) {
  return {
    type: ADMIN_UNBLOCK_EMPLOYER,
    payload: { employerId }
  };
}

export function fetchBillingReport (fromDate, toDate, page = 1, pageSize = 20) {
  return {
    type: ADMIN_FETCH_BILLING_REPORT,
    payload: { fromDate, toDate, page, pageSize },
  };
}

export function downloadBillingReport (fromDate, toDate) {
  return {
    type: ADMIN_DOWNLOAD_BILLING_REPORT,
    payload: { fromDate, toDate },
  };
}
