export const permissions = {
  canActAsAdmin: 'canActAsAdmin',
  canActAsCandidate: 'canActAsCandidate',
  canActAsEmployer: 'canActAsEmployer',
  // candidate
  canEditCandidateProfile: 'canEditCandidateProfile',
  canUploadVideo: 'canUploadVideo',
  canViewMyOffers: 'canViewMyOffers',
  // employer
  canEditEmployerProfile: 'canEditEmployerProfile',
  canMakeOffers: 'canMakeOffers',
  canViewCandidatesDetails: 'canViewCandidatesDetails',
  canShortlist: 'canShortlist',
};