import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  bold: ({ theme }) => ({
    color: theme.colorText
   })
 });

const BoldText = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <strong className={classes.bold}>
      {children}
    </strong>
  );
};

export default BoldText;