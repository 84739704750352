import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  video: props => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)${props.isFlipped ? ' scaleX(-1)' : ''}`,
    minHeight: '100%',
    minWidth: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: props.onClick ? 'pointer' : undefined,
  }),
});

const Video = React.forwardRef(
  ({ children, isFlipped, onClick, ...props }, ref) => {
    const classes = useStyles({ isFlipped, onClick });
    return (
      <video ref={ref}
             className={classes.video}
             onChange={onClick}
             {...props}/>
    );
  }
);

export default Video;