import React from 'react';
import CandidateDashboardLayout from './CandidateDashboardLayout';
import { Nav, Navbar, Tab, Tabs } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';
import VideoResumeTab from './VideoResumeTab';
import CandidateProfilePage from './CandidateProfilePage';
import RoleAndPaymentSecuredRoute
  from '../../components/RoleAndPaymentSecuredRoute';
import NoPaymentDisplay from '../../components/NoPaymentDisplay';
import CandidateOffers from './CandidateOffers';
import PaymentStatus from './PaymentStatus';
import CandidateEmployerShortlist from './CandidateEmployerShortlist';
import arrowHeadDownIcon from '../../icons/arrowhead-down.svg';
import RecordVideoPage from './RecordVideoPage';
import PermissionsRoute from '../../components/PermissionsRoute'
import { permissions } from '../../security/permissions'

const useStyles = createUseStyles(theme => ({
  navBar: {
    backgroundColor: 'white!important',
    padding: '0.5rem calc(50vw - 50%)',
    margin: '0 calc(0px - (50vw - 50%))',
    marginBottom: '2rem',
    textTransform: 'uppercase',
    '&>.navbar-brand': {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    '&>.navbar-toggler': {
      color: theme.colorSecondary,
      border: 0,
      paddingRight: 0,
      width: '4rem',
      textAlign: 'center',
    },
    '&>.navbar-toggler>span': {
      border: 0,
      backgroundImage: 'url(' + arrowHeadDownIcon + ')'
    },
    [theme.responsiveMd]: {
      display: 'none',
    }
  },
  tabs: {
    paddingBottom: '2rem',
    border: 0,
    display: 'none',
    '& .nav-link': {
      textTransform: 'uppercase',
      color: theme.colorText,
      fontWeight: 'bold',
      border: 0,
      background: 'transparent',
      padding: ['1px 1em', '!important'],
      '&:first-child': {
        paddingLeft: 0,
      },
      '&+.nav-link': {
        borderLeft: 'solid 1px gray',
        borderRadius: 0,
      },
      '&.active': {
        color: theme.colorText,
        backgroundColor: 'transparent',
        '& > span': {
          borderBottom: 'solid 2px ' + theme.colorPrimary,
        }
      },
      '&:hover': {
        color: theme.colorPrimary,
      }
    },
    [theme.responsiveMd]: {
      display: 'flex',
    }
  },
}));

const PageTitles = {
  "personal": "Personal",
  "video-resume": "Video Resume",
  "subscription": "Subscription",
  "shortlist": "Shortlist",
  "offers": "Offers",
};


const BelowTheFold = ({ history, location }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  let key = location.pathname.split('/')[2] || "personal";
  let VideoResumeTabComponent;
  if (key === 'record-video') {
    key = 'video-resume';
    VideoResumeTabComponent = RecordVideoPage;
  } else {
    VideoResumeTabComponent = VideoResumeTab;
  }
  function navigateTo(key) {
    history.push('/dashboard/' + key);
  }
  return (
    <>
      <Navbar bg="light" expand="md" collapseOnSelect className={classes.navBar}>
        <Navbar.Brand as="div">{PageTitles[key]}</Navbar.Brand>
        <Navbar.Toggle as="div" />
        <Navbar.Collapse>
          <Nav activeKey={key} onSelect={navigateTo} className="mr-auto">
            <Nav.Item>
              <Nav.Link eventKey="personal">Personal</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="video-resume">Video Resume</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="subscription">Subscription</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="shortlist">Shortlist</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="offers">Offers</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Tabs className={classes.tabs} id="candidate-dashboard-tabs" activeKey={key}
            onSelect={navigateTo} mountOnEnter={true} unmountOnExit={true}>
        <Tab eventKey="personal" title={<span>Personal</span>}>
          <RoleAndPaymentSecuredRoute component={CandidateProfilePage}/>
        </Tab>
        <Tab eventKey="video-resume" title={<span>Video Resume</span>}>
          <PermissionsRoute permissionsRequired={[permissions.canUploadVideo]} ForbiddenComponent={() => <p data-cy={'legallyForbidden'} >You must be legally allowed to work in Canada to upload videos.</p>}>
            <VideoResumeTabComponent />
          </PermissionsRoute>
        </Tab>
        <Tab eventKey="subscription" title={<span>Subscription</span>}>
          <PaymentStatus/>
        </Tab>
        <Tab eventKey="shortlist" title={<span>Shortlist</span>}>
          <RoleAndPaymentSecuredRoute component={CandidateEmployerShortlist} />
        </Tab>
        <Tab eventKey="offers" title={<span>Offers</span>}>
          <RoleAndPaymentSecuredRoute component={CandidateOffers}
                                      paymentSecurityOptions={{
                                        requiresPayment: true,
                                        NoPaymentDisplay: NoPaymentDisplay }}/>
        </Tab>
      </Tabs>
    </>
  );
};

const CandidateDashboard = (props) => {
  // TODO: define what sub-routes of /dashboard is ok to load the layout -
  // otherwise load notfound page
  return (
    <CandidateDashboardLayout BelowTheFold={<BelowTheFold {...props}/>}/>
  );
};

export default CandidateDashboard;
