import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  bold: () => ({
    fontWeight: 'bold'
   })
 });

const BoldLink = ({ children }) => {
  const classes = useStyles();
  return (
    <strong className={classes.bold}>
      {children}
    </strong>
  );
};

export default BoldLink;