import React from 'react'
import { Nav, Navbar, Tab, Tabs } from 'react-bootstrap'
import EmployerDashboardLayout from './EmployerDashboardLayout'
import { createUseStyles, useTheme } from 'react-jss'
import VideoResumeTab from '../employer/VideoResumeTab'
import EmployerProfilePage from '../employer/EmployerProfilePage'
import EmployerCandidateShortlist from './EmployerCandidateShortlist'
import RoleAndPaymentSecuredRoute from '../../components/RoleAndPaymentSecuredRoute'
import NoPaymentDisplay from '../../components/NoPaymentDisplay'
import PaymentStatus from './PaymentStatus'
import NotFound from '../NotFound'
import arrowHeadDownIcon from '../../icons/arrowhead-down.svg'
import OffersPage from './OffersPage'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
  navBar: {
    backgroundColor: 'white!important',
    padding: '0.5rem calc(50vw - 50%)',
    margin: '0 calc(0px - (50vw - 50%))',
    marginBottom: '2rem',
    textTransform: 'uppercase',
    '&>.navbar-brand': {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    '&>.navbar-toggler': {
      color: theme.colorSecondary,
      border: 0,
      paddingRight: 0,
      width: '4rem',
      textAlign: 'center',
    },
    '&>.navbar-toggler>span': {
      border: 0,
      backgroundImage: 'url(' + arrowHeadDownIcon + ')',
    },
    [theme.responsiveMd]: {
      display: 'none',
    },
  },
  tabs: {
    marginBottom: '2rem',
    border: 0,
    display: 'none',
    [theme.responsiveMd]: {
      display: 'flex',
    },
    '& .nav-link': {
      textTransform: 'uppercase',
      color: theme.colorText,
      fontWeight: 'bold',
      border: 0,
      background: 'transparent',
      padding: ['1px 1em', '!important'],
      '&:first-child': {
        paddingLeft: 0,
      },
      '&+.nav-link': {
        borderLeft: 'solid 1px gray',
        borderRadius: 0,
      },
      '&.active': {
        color: theme.colorText,
        backgroundColor: 'transparent',
        '& > span': {
          borderBottom: 'solid 2px ' + theme.colorPrimary,
        },
      },
      '&:hover': {
        color: theme.colorPrimary,
      },
    },
  },
}))

const TabsEnum = {
  Profile: 'profile',
  Payment: 'payment',
  SearchCandidates: 'search-candidates',
  Shortlisted: 'shortlisted',
  Offers: 'offers',
}
const PageTitles = {
  'profile': 'Profile',
  'payment': 'Payment',
  'search-candidates': 'Look for Candidates',
  'shortlisted': 'Shortlisted Candidates',
  'offers': 'Offers',
}

const BelowTheFold = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles({ theme })
  const { page } = useParams()
  const key = page || TabsEnum.Profile
  if (!Object.values(TabsEnum).includes(key)) {
    return <NotFound/>
  }

  function navigateTo (key) {
    history.push('/' + key)
  }

  return (
    <>
      <Navbar bg="light" expand="md" collapseOnSelect className={classes.navBar}>
        <Navbar.Brand as="div">{PageTitles[key]}</Navbar.Brand>
        <Navbar.Toggle as="div"/>
        <Navbar.Collapse>
          <Nav activeKey={key} onSelect={navigateTo} className="mr-auto">
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="payment">Payment</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="search-candidates">Look for Candidates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="shortlisted">Shortlisted Candidates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={TabsEnum.Offers}>Offers</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Tabs
        className={classes.tabs}
        id="employer-dashboard-tabs"
        activeKey={key}
        onSelect={navigateTo}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Tab
          eventKey={TabsEnum.Profile}
          title={<span>Profile</span>}
          className={classes.tab}
        >
          <RoleAndPaymentSecuredRoute component={EmployerProfilePage}/>
        </Tab>
        <Tab
          eventKey={TabsEnum.Payment}
          title={<span>Payment</span>}
          className={classes.tab}
        >
          <PaymentStatus/>
        </Tab>
        <Tab
          eventKey={TabsEnum.SearchCandidates}
          title={<span>Look for candidates</span>}
          className={classes.tab}
        >
          <RoleAndPaymentSecuredRoute component={VideoResumeTab}/>
        </Tab>
        <Tab
          eventKey={TabsEnum.Shortlisted}
          title={<span>Shortlisted Candidates</span>}
          className={classes.tab}
        >
          <RoleAndPaymentSecuredRoute
            component={EmployerCandidateShortlist}
            paymentSecurityOptions={{
              requiresPayment: true,
              NoPaymentDisplay: NoPaymentDisplay,
            }}
          />
        </Tab>
        <Tab
          eventKey={TabsEnum.Offers}
          title={<span>Offers</span>}
          className={classes.tab}
        >
          <RoleAndPaymentSecuredRoute
            component={OffersPage}
            paymentSecurityOptions={{
              requiresPayment: true,
              NoPaymentDisplay: NoPaymentDisplay,
            }}
          />
        </Tab>
      </Tabs>
    </>
  )
}

const EmployerDashboard = props => {
  return <EmployerDashboardLayout BelowTheFold={<BelowTheFold {...props} />}/>
}

export default EmployerDashboard
