import {
  ADMIN_FETCH_BILLING_REPORT,
  ADMIN_FETCH_BILLING_REPORT_FAILURE,
  ADMIN_FETCH_BILLING_REPORT_SUCCESS,
} from '../../actionTypes'

const defaultState = {
  loading: false,
  results: [],
  total: 0,
  error: '',
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case ADMIN_FETCH_BILLING_REPORT: {
      return { ...state, loading: true };
    }
    case ADMIN_FETCH_BILLING_REPORT_SUCCESS: {
      const { results, total } = action.payload;
      return { ...state, loading: false, error: '', results, total };
    }
    case ADMIN_FETCH_BILLING_REPORT_FAILURE: {
      const { message } = action.payload;
      return { ...state, loading: false, error: message };
    }
    default:
      return state;
  }
}
