import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getIntroVideos } from "./dashboardSelectors";
import { fetchCandidateIntroVideos } from "./actions";
import { createUseStyles, useTheme } from "react-jss";
import { getPaymentInfo, getUserId } from "../../auth/selectors";
import { useHistory } from "react-router-dom";
import CandidateVideoCard from './CandidateVideoCard';
import Button from '../../components/Button';

function normalizeTag(tag) {
  return tag
  .toLowerCase()
  .replace(/[^A-Z]/gi, "-")
  .replace(/[-]{2,}/gi, "-");
}

const useStyles = createUseStyles((theme) => ({
  sectionTitle: {
    fontSize: "20pt",
    fontWeight: "bold",
  },
  section: {
    marginTop: "2rem",
    marginBottom: 5,
    verticalAlign: "center",
    display: "block",
  },
  sectionBody: {
    minHeight: 270,
    paddingTop: 20,
    overflowX: "scroll",
    flexWrap: "inherit",
    [theme.responsiveXl]: {
      overflowX: "visible",
      flexWrap: "nowrap",
    }
  },
  link: {
    border: [0, '!important'],
    padding: 0,
    background: ['none', '!important'],
    color: [theme.colorText, '!important'],
    textDecoration: ["underline", '!important'],
    boxShadow: 'none !important',
  },
  pagination: {
    display: "none",
    marginBottom: 20,
    [theme.responsiveXl]: {
      display: "block",
    },
  },
  statusBox: {
    minHeight: 38,
    fontSize: "1rem",
    textAlign: "center",
    padding: "6px 12px",
  },
  button: {
    width: "100%",
  },
  pagButton: {
  },
  showMore: {
    [theme.responsiveXl]: {
      display: "none",
    },
  },
}));

const defaultPageSize = 4;
const VideoSection = ({ section, title, noNavigation = false, excludeCandidateId = '' }) => {
  const sectionRef = useRef(null);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const tag = normalizeTag(section);
  const { loading, results, total } = useSelector(getIntroVideos(tag));
  const { userPaid } = useSelector(getPaymentInfo);
  const isLastPage = page * pageSize >= total;
  const dispatch = useDispatch();
  const employerId = useSelector(getUserId);
  useEffect(refresh, [dispatch, page, pageSize]);

  function prevPage() {
    setPage(page - 1);
  }

  function nextPage() {
    setPage(page + 1);
  }

  function viewProfile(candidateId) {
    if (!userPaid) {
      return history.push("/paymentRequest");
    }
    history.push(`/search-candidates/${tag}/${candidateId}`);
  }

  function refresh() {
    if (loading) return;
    dispatch(fetchCandidateIntroVideos(tag, excludeCandidateId, page, pageSize));
  }

  function statusOrSaveButton({
    employerId,
    candidateId,
    shortlistedBy,
    acceptedOffer,
  }) {
    if (acceptedOffer)
      return <div className={classes.statusBox}>Not available</div>;
    if (shortlistedBy.length)
      return <div className={classes.statusBox}>Saved</div>;
    return (
      <Button
        className={classes.button}
        onClick={() => viewProfile(candidateId)}
        data-cy={'viewProfile'}
      >
        View Profile
      </Button>
    );
  }

  useEffect(() => {
    let ref = sectionRef.current;
    if (noNavigation) return;

    function loadMore() {
      setPageSize(pageSize => isLastPage ? total + 1 : pageSize + defaultPageSize);
    }

    function handleScroll(evt) {
      if (evt.target.offsetWidth + evt.target.scrollLeft !== evt.target.scrollWidth) return;
      loadMore();
    }

    ref.addEventListener('scroll', handleScroll);
    return () => ref.removeEventListener('scroll', handleScroll);
  }, [isLastPage, total, sectionRef, noNavigation]);

  const showNoVideos = !loading && results.length === 0;
  const showVideos = results.length !== 0;
  const showLoading = /*theme.fullView
    ? */loading && results.length === 0;
  //: loading;
  const showMore = !loading && results.length;

  return (
    <>
      <Row className={classes.section} data-cy={'jobCategories'}>
        <Col xs={12}>
          <span className={classes.sectionTitle}>{title || section}</span>
          {!noNavigation &&
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button className={classes.link} onClick={refresh} data-cy={'refreshSection'}>
            Refresh this section
            </Button>
            &nbsp;{loading && <Spinner animation="border" size="sm"/>}
          </>
          }
        </Col>
      </Row>
      <Row ref={sectionRef} className={classes.sectionBody} data-cy={'profiles'}>
        {showNoVideos && <Col xs={12}>No videos</Col>}
        {showVideos && results.map(data => (
          <Col key={data.videoId} xs={12} sm={6} lg={4} xl={3} data-cy={'candidateVideoCard'}>
            <CandidateVideoCard data={data}
                                actionsComponent={statusOrSaveButton({
                                  employerId, ...data,
                                })} />
          </Col>
        ))}
        <Col hidden={!showLoading} xs={12} className={classes.loading}>Loading...</Col>
        <Col hidden={!showMore} xs={1} className={classes.showMore}>...</Col>
      </Row>
      {!noNavigation &&
      <Row className={"clearfix " + classes.pagination}>
        <Col xs={12}>
          <Button
            className={"float-left " + classes.pagButton}
            disabled={page <= 1}
            onClick={prevPage}
          >
            &lt;
          </Button>
          <Button
            className={"float-right " + classes.pagButton}
            disabled={isLastPage}
            onClick={nextPage}
            data-cy={'nextPageButton'}
          >
            &gt;
          </Button>
        </Col>
      </Row>
      }
    </>
  );
};

export default VideoSection;
