import React from "react";
import CandidateProfileView from './CandidateProfileView';
import VideoSection from './VideoSection';
import { jobCategories } from "../../constants";

const sections = jobCategories.slice();

const VideoResumeTab = ({ match }) => {
  const { id, section } = match.params;
  if (id) {
    return <CandidateProfileView candidateId={id} section={section} />;
  }
  return (
    sections.map((x) => (<VideoSection key={x} section={x} />))
  );
};

export default VideoResumeTab;
