const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const Availability = ({ value }) => {
  const onlyDate = value && value.indexOf('T') === -1;
  const date = new Date(onlyDate ? value + 'T00:00:00' : value);
  const inThePast = date < new Date();
  if (!value || inThePast) return "Today";
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export default Availability;
