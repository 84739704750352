import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    background: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    borderRadius: '4px',
    padding: '17px 18px',
    border: 'none',
    margin: '5px',
    fontSize: '18px',
    fontWeight: 'bold',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#eee',
    },
  },
});

const Button = ({
  children,
  color = 'black',
  backgroundColor = 'white',
  ...props
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <button className={classes.button} {...props}>
      {children}
    </button>
  );
};

export default Button;