import decodeJWT from 'jwt-decode';

const emptyJwt = Object.freeze({
  aud: '',
  iss: '',
  exp: 0,
  sub: '',
  email: '',
  name: '',
  roles: [],
  privileges: []
});

export function parseJwtToken (token) {
  try {
    return Object.freeze(decodeJWT(token));
  } catch (err) {
    console.debug(err.message);
    return emptyJwt;
  }
}