import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ThemeProvider } from 'react-jss';
import { ToastProvider } from 'react-toast-notifications';
import AppLayout from './layouts/AppLayout';
import TopLevelRoutes from './pages/TopLevelRoutes';
import buildTheme from './theme';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.debug('env:', { ...process.env });
  }
  const theme = buildTheme();
  return (
    <Router basename="/">
      <ScrollToTop/>
      <ToastProvider autoDismiss autoDismissTimeout={3500} placement="top-center">
      <ThemeProvider theme={theme}>
        <AppLayout TopLevelRoutes={TopLevelRoutes} />
      </ThemeProvider>
      </ToastProvider>
    </Router>
  );
};

export default App;
