import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';
import apiClient from '../utils/api';
import {
  SUBMIT_CONTACT_FORM,
  SUBMIT_CONTACT_FORM_FAILED,
  SUBMIT_CONTACT_FORM_SUCCESS,
} from '../actionTypes';

function * submitContactForm(action) {
  try {
    yield call(apiClient.post, '/v1/contact/form', action.payload);
    yield put({
      type: SUBMIT_CONTACT_FORM_SUCCESS,
      payload: {}
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: SUBMIT_CONTACT_FORM_FAILED,
      payload: err
    });
  }
}

export function * watchSubmitContactForm () {
  yield takeLatest(SUBMIT_CONTACT_FORM, submitContactForm);
}
