import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import {
  Form,
  ProgressBar,
  Row,
  Col,
  ResponsiveEmbed,
  Alert,
} from 'react-bootstrap';
import { getCandidateVideoUpload, getSelectedQuestion } from './selectors';
import { uploadCandidateVideo, resetUploadCandidateVideo } from './actions';
import VideoRecorder from '../../components/VideoRecorder';
import HeaderText from '../../components/HeaderText';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';

const maxSeconds = 60;
const constraints = {
  audio: true,
  video: {
    facingMode: 'user',
    width: { min: 640, ideal: 1280, max: 1920 },
    height: { min: 480, ideal: 720, max: 1080 },
    aspectRatio: { min: 4/3, ideal: 16/9, max: 16/9 },
  },
};
const maxFileSize = 100 * 1024 * 1024;
const maxFileSizeDisplay = '100MB';

const useStyles = createUseStyles(theme => ({
  button: {
    marginTop: '1em',
    color: ({ theme }) => theme.colorBackground,
    backgroundColor: ({ theme }) => theme.colorPrimary,
    borderColor: ({ theme }) => theme.colorPrimary,
  },
  fileUpload: {
    margin: '1em auto',
    maxWidth: 852,
  },
  responsive: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    '&::before': {
      display: 'block',
      content: '',
    },
    '& > div': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
      minHeight: 'auto',
    },
    '&-21by9': {
      paddingTop: '42.857143%',
    },
    '&-16by9': {
      paddingTop: '56.25%',
    },
    '&-4by3': {
      paddingTop: '75%',
    },
    '&-1by1': {
      paddingTop: '100%',
    },
  },
  questionNumber: {
    color: theme.colorPrimary,
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  textPadding: {
    paddingTop: '1em',
  },
}));

const ErrorDisplay = ({ error }) => {
  return <Alert hidden={!error} variant="danger">{error}</Alert>;
};

const ProgressDisplay = ({ progress, uploading, error }) => {
  let variant;
  if (error) {
    variant = 'danger';
  } else if (progress === 100) {
    variant = 'success';
  }
  return <ProgressBar hidden={!uploading && progress < 100 && !error}
                      min={0} max={100} now={progress} variant={variant}/>;
};

const VideoRecorderDisplay = ({ prefix, onRecordingComplete }) => {
  const recorderRef = React.useRef();
  const [aspectRatio, setAspectRatio] = useState('16by9');
  const handleCameraOn = () => setTimeout(() => {
    const recorder = recorderRef.current;
    console.log('camera video resolution:', recorder.cameraVideo.videoWidth,
      recorder.cameraVideo.videoHeight);
    const aspectRatio = recorder.cameraVideo.videoWidth /
      recorder.cameraVideo.videoHeight;
    if (aspectRatio > 1.333 && aspectRatio < 1.334) {
      setAspectRatio('4by3');
    } else if (aspectRatio > 1.777 && aspectRatio < 1.778) {
      setAspectRatio('16by9');
    } else {
      setAspectRatio('1by1');
    }
  }, 200);
  return (
    <ResponsiveEmbed bsPrefix={prefix} aspectRatio={aspectRatio}>
      <VideoRecorder ref={recorderRef}
                     constraints={constraints}
                     onCameraOn={handleCameraOn}
                     onRecordingComplete={onRecordingComplete}
                     showReplayControls={true}
                     replayVideoAutoplayAndLoopOff={true}
                     isFlipped={true}
                     timeLimit={maxSeconds * 1000}/>
    </ResponsiveEmbed>
  );
};

const QuestionDisplay = ({ question, classes }) => {
  return (
    <div className="text-left py-3" >
      <HeaderText level={4}>{question.title}</HeaderText>
      <p className={classes.textPadding}>Here are some tips in order to make your first impression a success.</p>
      {question.description.split('\n').map((line, index) =>
        <div key={index}>
          <span className={classes.questionNumber}>{index+1}.</span>
          &nbsp;{line}
        </div>
      )}
      <HeaderText level={4} className={classes.textPadding}>Max {maxSeconds} seconds.</HeaderText>
      <p className={classes.textPadding}><strong>All videos should be recorded/uploaded in landscape mode to avoid rejection.</strong></p>
    </div>
  );
};

const RecordVideoPage = ({ className }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const { progress, uploading, error } = useSelector(getCandidateVideoUpload);
  const question = useSelector(getSelectedQuestion);
  const [state, setState] = useState({
    file: null,
    error: '',
    mirrorVideo: false,
  });
  useEffect(() => {
    dispatch(resetUploadCandidateVideo());
  }, [dispatch]);
  const buttonDisabled = !state.file || uploading;
  const handleUpload = (evt) => {
    evt.preventDefault();
    dispatch(uploadCandidateVideo(question.questionId, state.file,
      state.mirrorVideo, () => setTimeout(() =>
        history.push('/dashboard/video-resume'), 1000)));
  };
  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    if (!file.type.startsWith('video/')) {
      setState(prevState => ({
        ...prevState,
        error: 'must be a video file',
      }));
    } else if (file.size > maxFileSize) {
      setState(prevState => ({
        ...prevState,
        error: `file too large. max: ${maxFileSizeDisplay}.`,
      }));
    } else {
      evt.persist();
      setState(prevState => ({
        ...prevState,
        file,
        error: '',
        mirrorVideo: false,
      }));
      dispatch(resetUploadCandidateVideo());
    }
  };
  const handleVideoRecordingComplete = (videoBlob) => {
    setState(prevState => ({ ...prevState, file: videoBlob, mirrorVideo: true }));
    dispatch(resetUploadCandidateVideo());
  };
  return (
    <div className={className} data-cy={'recordVideoPage'}>
      <Row>
        <Col className="pb-3">
          <HeaderText level={4}>Instructions for recording your video</HeaderText>
          <div className={classes.textPadding}>
            <span className={classes.questionNumber}>1.</span>
            &nbsp;Record your video against a white/light background
          </div>
          <div>
            <span className={classes.questionNumber}>2.</span>
            &nbsp;Make sure your face is centered in the video
          </div>
          <div>
            <span className={classes.questionNumber}>3.</span>
            &nbsp;Be sure to look at the camera while you’re talking
          </div>
          <div>
            <span className={classes.questionNumber}>4.</span>
            &nbsp;Make sure you check your video before uploading
          </div>
          <p>Best of Luck!</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={5} xl={6}><QuestionDisplay question={question} classes={classes}/></Col>
        <Col sm={12} lg={7} xl={6} className="text-center">
          <VideoRecorderDisplay prefix={classes.responsive}
                                onRecordingComplete={handleVideoRecordingComplete}/>
          <p className="mt-3">- or -</p>
          <Form.Control className={classes.fileUpload} type="file"
                        name="video" accept="video/*"
                        onChange={handleFileChange}
                        data-cy={'fileUpload'}/>
          <ErrorDisplay error={error || state.error}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressDisplay progress={progress} uploading={uploading}
                           error={error}
                            data-cy={'progressDisplay'}/>
        </Col>
      </Row>
      <Row>
        <Col className={classes.center}>
          <Button className={classes.button} disabled={buttonDisabled}
                  onClick={handleUpload}
                  data-cy={'uploadButton'}>
            Upload
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RecordVideoPage;
