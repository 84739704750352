import React from 'react';
import { createUseStyles } from 'react-jss';
import Video from './Video';

const useStyles = createUseStyles({
  cameraView: {
    width: '100%',
    height: '100%',
  },
});

const CameraView = ({
// state
  isVideoInputSupported,
  isReplayingVideo,
  isInlineRecordingSupported,
  thereWasAnError,
  error,
  isCameraOn,
  isConnecting,
  isReplayVideoMuted,
// props
  replayVideoAutoplayAndLoopOff,
  DisconnectedComponent,
  VideoInputComponent,
  UnsupportedComponent,
  ErrorComponent,
  LoadingComponent,
  useVideoInput,
  isFlipped,
//
  videoInputRef,
  replayVideoRef,
  cameraVideoRef,
  handleVideoSelected,
  videoUrl,
  handleReplayVideoClick,
  handleDurationChange,
}) => {
  const classes = useStyles({});

  const shouldUseVideoInput =
    useVideoInput || (!isInlineRecordingSupported && isVideoInputSupported);

  const videoInput = shouldUseVideoInput ? (
    <input
      ref={videoInputRef}
      key='videoInput'
      type='file'
      accept='video/*'
      capture={useVideoInput ? undefined : 'user'}
      style={{ display: 'none' }}
      onChange={handleVideoSelected}
    />
  ) : null;

  if (isReplayingVideo) {
    return (
      <div className={classes.cameraView} key='replay'>
        <Video
          isFlipped={isFlipped}
          ref={replayVideoRef}
          src={videoUrl}
          loop
          muted={isReplayVideoMuted}
          playsInline
          autoPlay={!replayVideoAutoplayAndLoopOff}
          onClick={handleReplayVideoClick}
          onDurationChange={handleDurationChange}
        />
        {videoInput}
      </div>
    );
  }

  if (shouldUseVideoInput) {
    return <VideoInputComponent videoInput={videoInput} />;
  }

  if (!isInlineRecordingSupported) {
    return <UnsupportedComponent />;
  }

  if (thereWasAnError) {
    return <ErrorComponent error={error} />;
  }

  if (isCameraOn) {
    return (
      <div className={classes.cameraView} key='camera'>
        <Video
          isFlipped={isFlipped}
          ref={cameraVideoRef}
          autoPlay
          muted
        />
      </div>
    );
  }

  if (isConnecting) {
    return <LoadingComponent />;
  }

  return <DisconnectedComponent />;
};

export default CameraView;