import update from 'immutability-helper';
import {
  ADMIN_BLOCK_EMPLOYER_FAILURE,
  ADMIN_BLOCK_EMPLOYER_SUCCESS,
  ADMIN_FETCH_EMPLOYERS,
  ADMIN_FETCH_EMPLOYERS_FAILED,
  ADMIN_FETCH_EMPLOYERS_SUCCESS,
  ADMIN_UNBLOCK_EMPLOYER_FAILURE,
  ADMIN_UNBLOCK_EMPLOYER_SUCCESS,
} from '../../actionTypes';

const defaultState = Object.freeze({
  fetching: false,
  results: [],
  total: 0,
  errorMessage: '',
});

export default function candidatesReducer (state = defaultState, action) {
  switch (action.type) {
    case ADMIN_FETCH_EMPLOYERS: {
      return update(state, {
        $toggle: ['fetching'],
        error: { $set: '' },
      });
    }
    case ADMIN_FETCH_EMPLOYERS_SUCCESS: {
      return update(state, {
        $toggle: ['fetching'],
        $merge: action.payload,
      });
    }
    case ADMIN_FETCH_EMPLOYERS_FAILED: {
      return update(state, {
        $toggle: ['fetching'],
        errorMessage: { $set: action.payload.message },
      });
    }
    case ADMIN_BLOCK_EMPLOYER_SUCCESS:
    case ADMIN_UNBLOCK_EMPLOYER_SUCCESS: {
      const blocked = action.type === ADMIN_BLOCK_EMPLOYER_SUCCESS;
      const { employerId } = action.payload;
      const blockedEmployerIndex = state.results.findIndex(e => e.employerId === employerId);
      const result = update(state, {
        results: {
          [blockedEmployerIndex]: {
            blocked: { $set: blocked }
          }
        }
      });
      return result;
    }
    case ADMIN_BLOCK_EMPLOYER_FAILURE:
    case ADMIN_UNBLOCK_EMPLOYER_FAILURE: {
      const blocked = action.type === ADMIN_UNBLOCK_EMPLOYER_FAILURE;
      const { employerId } = action.payload;
      const blockedEmployerIndex = state.results.findIndex(e => e.employerId === employerId);
      const result = update(state, {
        results: {
          [blockedEmployerIndex]: {
            blocked: { $set: blocked }
          }
        }
      });
      return result;
    }
    default:
      return state;
  }
}