import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import RoleAndPaymentSecuredRoute from '../../components/RoleAndPaymentSecuredRoute';
import LoginPage from '../LoginPage';

const allowedRoles = ['admin'];

const Routes = () => {
  return (
    <Router basename="/admin">
      <Switch>
        {/* DO NOT REMOVE */}
        <Route exact path="/login" component={LoginPage} />
        <RoleAndPaymentSecuredRoute path="/" allowedRoles={allowedRoles} component={AdminDashboard} />
      </Switch>
    </Router>
  );
};

export default Routes;
