export function errorMessageFromPayload(payload) {
  if (payload.response && payload.response.data && payload.response.data.message) {
    return transformIfArray(payload.response.data.message);
  }
  return payload.message;
}

function transformIfArray(message) {
  if (Array.isArray(message)) {
    return message.map(x => `${x.field} ${x.message}`).join();
  }
  return message;
}

export function errorsFromPayload(payload) {
  if (payload.response && payload.response.data && payload.response.data.validationErrors) {
    return payload.response.data.validationErrors.reduce((a,c) => ({ ...a, [c.field]: c.message }), {});
  }
  const message = (payload.response && payload.response.data && payload.response.data.message) || payload.message;
  return {
    _: message
  };
}
