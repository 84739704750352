import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPaymentInfo, isInAllowedRoles, isLoggedIn } from '../auth/selectors';
import Forbidden from '../pages/Forbidden';

const bypassRouteSecurity = process.env.REACT_APP_BYPASS_ROUTE_SECURITY ===
  'true';

const RoleAndPaymentSecuredRoute = ({ component: Component, exact, path, allowedRoles, paymentSecurityOptions,
  ForbiddenComponent, NoAuthenticationComponent, ...rest }) => {
  const authenticated = useSelector(isLoggedIn);
  const userInAllowedRoles = useSelector(isInAllowedRoles(allowedRoles));
  const { userPaid } = useSelector(getPaymentInfo);
  const { requiresPayment, NoPaymentDisplay } = paymentSecurityOptions;

  const determineRender = (props) => {
    const satisfyRoleSecurity = !allowedRoles.length || userInAllowedRoles;
    const satisfyPaymentSecurity = !requiresPayment || userPaid;
    if (bypassRouteSecurity) {
      return <Component {...props} {...rest} />;
    } else if (authenticated && satisfyRoleSecurity && satisfyPaymentSecurity) {
      return (<Component {...props} {...rest} />);
    } else if (authenticated && satisfyRoleSecurity) {
      return (<NoPaymentDisplay/>);
    } else if (authenticated) {
      if (!ForbiddenComponent) {
        return (<Forbidden/>);
      }
      return (<ForbiddenComponent/>);
    }

    if (!NoAuthenticationComponent) {
      return (
        <Redirect
          to={{
            pathname: `/login`,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return (<NoAuthenticationComponent />);
    }
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        determineRender(props)
      }
    />
  );
};
RoleAndPaymentSecuredRoute.propTypes = {
  component: PropTypes.oneOfType(
    [PropTypes.func, PropTypes.node, PropTypes.object]),
  location: PropTypes.object,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  paymentSecurityOptions: PropTypes.object,
};
RoleAndPaymentSecuredRoute.defaultProps = {
  allowedRoles: [],
  paymentSecurityOptions: {},
};
export default RoleAndPaymentSecuredRoute;
