import update from 'immutability-helper';
import {
  ADMIN_FETCH_CANDIDATES, ADMIN_FETCH_CANDIDATES_FAILED,
  ADMIN_FETCH_CANDIDATES_SUCCESS,
} from '../../actionTypes';

const defaultState = Object.freeze({
  fetching: false,
  results: [],
  total: 0,
  errorMessage: '',
});

export default function candidatesReducer(state = defaultState, action) {
  switch (action.type) {
    case ADMIN_FETCH_CANDIDATES: {
      return update(state, {
        $toggle: ['fetching'],
        error: { $set: '' },
      });
    }
    case ADMIN_FETCH_CANDIDATES_SUCCESS: {
      return update(state, {
        $toggle: ['fetching'],
        $merge: action.payload,
      });
    }
    case ADMIN_FETCH_CANDIDATES_FAILED: {
      return update(state, {
        $toggle: ['fetching'],
        errorMessage: { $set: action.payload.message },
      });
    }
    default:
      return state;
  }
}