import React from 'react';
import HeaderText from '../components/HeaderText';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  termsOfUse: {
    padding: theme.pageVerticalMargin + ' 0'
  }
}));

const TermsOfUsePage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.termsOfUse} data-cy={'termsOfUse'}>
      <p><HeaderText level={1}>Terms of Use for IAAM Recruitment Inc.</HeaderText></p>

      <p><HeaderText level={4}>1. Introduction</HeaderText></p>
      <p>The IAAM Recruitment Inc. Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms and Conditions.</p>
      <p><b>This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.</b></p>
      <p><HeaderText level={4}>2. Intellectual Property Rights</HeaderText></p>
      <p>Other than content you own, which you may have opted to include on this Website, under these Terms, IAAM Recruitment Inc. and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>
      <p><HeaderText level={4}>3. Restrictions</HeaderText></p>
      <p>
        You are expressly and emphatically restricted from all the following:<br/>
      1. publishing any Website material in any media;<br/>
      2. selling, sublicensing and/or otherwise commercializing any Website material;<br/>
      3. publicly performing and/or showing any Website material;<br/>
      4. using this Website in any way that is, or may be, damaging to this Website;<br/>
      5. using this Website in any way that impacts user access to this Website;<br/>
      6. using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;<br/>
      7. engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;<br/>
      8. using this Website to engage in any advertising or marketing;<br/>
      Certain areas of this Website are restricted from access by you and IAAM Recruitment Inc. may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.
      </p>
      <p><HeaderText level={4}>4. Your Content</HeaderText></p>
      <p>
        In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant IAAM Recruitment Inc. a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
        Your Content must be your own and must not be infringing on any third party’s rights. IAAM Recruitment Inc. reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.
      </p>
      <p><HeaderText level={4}>5. No Warranties</HeaderText></p>
      <p>This Website is provided “as is,” with all faults, and IAAM Recruitment Inc. makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</p>
      <p><HeaderText level={4}>6. Limitation of Liability</HeaderText></p>
      <p>In no event shall IAAM Recruitment Inc., nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and IAAM Recruitment Inc., including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
      <p><HeaderText level={4}>7. Indemnification</HeaderText></p>
      <p>You hereby indemnify to the fullest extent IAAM Recruitment Inc. from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.</p>
      <p><HeaderText level={4}>8. Severability</HeaderText></p>
      <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>
      <p><HeaderText level={4}>9. Variation of Terms</HeaderText></p>
      <p>IAAM Recruitment Inc. is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>
      <p><HeaderText level={4}>10. Assignment</HeaderText></p>
      <p>IAAM Recruitment Inc. shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
      <p><HeaderText level={4}>11. Entire Agreement</HeaderText></p>
      <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between IAAM Recruitment Inc. and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>
      <p><HeaderText level={4}>12. Governing Law & Jurisdiction</HeaderText></p>
      <p>These Terms will be governed by and construed in accordance with the laws of British Columbia, and you submit to the non-exclusive jurisdiction of the state and federal courts located in British Columbia for the resolution of any disputes.</p>

    </div>
  );
};

export default TermsOfUsePage;
