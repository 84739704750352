import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { Redirect } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import {
  isEmailConfirmed,
  getEmailConfirmationError,
  getConfirmingEmail,
  getCandidateId,
} from './selectors';
import { confirmEmail } from './actions';
import Button from '../../components/Button';
import FormControl from '../../components/FormControl';

const useStyles = createUseStyles(theme => ({
  confirmEmail: {
    marginTop: theme.pageVerticalMargin,
    marginBottom: theme.pageVerticalMargin,
    '& > form': {
      marginBottom: 16,
    },
  },
  codeInput: {
    width: 120,
    textAlign: 'center',
    letterSpacing: 5,
    fontWeight: 'bold'
  },
  error: {
    color: 'red',
  },
}));

function ConfirmEmail ({ location, history }) {
  const [code, setCode] = useState('');
  const [touched, setTouched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const candidateId = useSelector(getCandidateId);
  const confirmingEmail = useSelector(getConfirmingEmail);
  const emailConfirmed = useSelector(isEmailConfirmed);
  const error = useSelector(getEmailConfirmationError);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles({ theme });

  function changeCode (code) {
    setTouched(true);
    setCode(code.toUpperCase());
  }

  function verifyCode (evt) {
    evt.preventDefault();
    if (confirmingEmail) return;
    setTouched(false);
    dispatch(confirmEmail({ candidateId, code }, { history }));
    setSubmitted(true);
    return false;
  }

  if (emailConfirmed) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={classes.confirmEmail} data-cy={'candidateConfirmEmail'}>
      <p>Welcome to IAAM,</p>
      <p>Before we can proceed we need to verify your email address. We sent you an email with a verification code.</p>
      <p>Please enter the code below</p>
      <form onSubmit={verifyCode}>
        <FormControl type="text" name="code" maxLength={6}
                     id="registration-confirmation-code"
                     autoFocus={isBrowser}
                     className={classes.codeInput}
                     value={code}
                     onChange={evt => changeCode(evt.target.value)}/>
      </form>
      {submitted && !touched && !!error && <p className={classes.error}>{error}</p>}
      <p>
        <Button disabled={confirmingEmail}
                style={{ width: 120 }}
                onClick={verifyCode}>Verify code</Button>
      </p>
    </div>
  );
}

export default ConfirmEmail;
