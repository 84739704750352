import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { isBrowser } from 'react-device-detect';
import { loginUser } from '../auth/actions';
import { getLoginError } from '../security/selectors';
import useQuery from '../hooks/useQuery';
import IAAMForm from '../components/IaamForm';
import FormGroup from '../components/FormGroup';
import FormLabel from '../components/FormLabel';
import FormControl from '../components/FormControl';
import Button from '../components/Button';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  subHeading: theme.subHeading,
}));

const LoginPage = ({ history, location }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [submitted, setSubmitted] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const loginError = useSelector(getLoginError);
  const dispatch = useDispatch();
  const query = useQuery(location);
  const isLogout = query.get('logout') === 'true';
  const handleFormValueChange = (evt) => {
    evt.persist();
    setCredentials(prevCreds => ({ ...prevCreds, [evt.target.name]: evt.target.value }));
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { from } = location.state || { from: { pathname: '/' } };
    dispatch(loginUser(credentials, history, from));
    setSubmitted(true);
  };
  return (
    <IAAMForm onSubmit={handleSubmit}>
      <h2 className={classes.subHeading}>Log In</h2>
      <FormGroup data-cy={'loginMessages'}>
        {isLogout && <Alert variant={'info'}>You have successfully logged out.</Alert>}
        <div>{submitted && loginError}</div>
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="username">Email</FormLabel>
        <FormControl type="text" name="username" id="username"
                     value={credentials.username}
                     autoFocus={isBrowser}
                     onChange={handleFormValueChange}
                     data-cy={'username'}/>
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="username">Password</FormLabel>
        <FormControl type="password" name="password" id="password"
                     value={credentials.password}
                     onChange={handleFormValueChange}
                     data-cy={'password'}/>
      </FormGroup>
      <FormGroup>
        <Button type="submit" data-cy={'submit'}>Login</Button>
      </FormGroup>
    </IAAMForm>
  );
};

export default LoginPage;
