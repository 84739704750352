import React from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "./HomeLayout";
import { useSelector } from "react-redux";
import { hasRole, getNeedToConfirmEmailUserType } from "../auth/selectors";
import { createUseStyles, useTheme } from "react-jss";
import cameraIcon from "../icons/camera.svg";
import browsingIcon from "../icons/browsing.svg";
import shortlistIcon from "../icons/shortlist.svg";
import HeaderText from "../components/HeaderText";
import homePageHeader1920 from "../images/Homepage_Header_1920.jpg";
import homePageHeader1280 from "../images/Homepage_Header_1280.jpg";
import { Row, Col } from "react-bootstrap";

const useStyles = createUseStyles((theme) => ({
  headline: {
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    alignItems: "flex-end",
    height: "calc(100vh - 6rem)",
    margin: "0 calc(0px - (50vw - 50%))",
    padding: "0.5rem calc(50vw - 50%)",
    backgroundImage: `linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 66%), url(${homePageHeader1280})`,
    [theme.responsiveSm]: {
      paddingBottom: '2rem'
    },
    [theme.responsiveXl]: {
      backgroundImage: `linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 66%), url(${homePageHeader1920})`,
    },
  },
  aboveTheFold: {
    textAlign: "center",
    margin: "auto",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-around",
    marginTop: 80,
    "@media (min-width: 1200px)": {
      marginTop: 96,
      width: 708,
    }
  },
  aboveTheFoldText: {},
  belowTheFold: {
    padding: "4rem 0",
    [theme.responsiveLg]: {
      padding: "4rem 2rem",
    }
  },
  boxStyle: {
    fontSize: "1rem",
    border: "1px solid #e0e0e0",
    borderBottom: "4px solid " + theme.colorPrimary,
    boxShadow: "0 0 40px rgba(0, 0, 0, 0.05)",
    padding: "1.5em 1.5em 0.5em 1.5em",
    maxWidth: "20em",
    height: "100%",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0 auto",
  },
  icons: {
    marginBottom: '1rem'
  }
}));

const Headline = ({ theme, classes }) => (
  <div className={classes.headline} data-cy={'headline'}>
    <HeaderText width={600} level={1}>
      With IAAM you can easily record and share your video resume with Employers across the Country
    </HeaderText>
  </div>
);

const AboveTheFold = ({ theme, classes }) => (
  <div className={classes.aboveTheFold} data-cy={'aboveTheFold'}>
    <HeaderText level={2} color={theme.colorPrimary}>
      IAAM “It’s All About Me” is all about empowering our candidates!
    </HeaderText>
    <div className={classes.aboveTheFoldText}>
      IAAM brings video resume technology to recruitment. You record your video and we share them with Employers. It’s that simple!
      IAAM allows candidates to speak about their experience through their unique personalities. Employers have access to a video library of talented candidates to hire for their next role.
    </div>
  </div>
);

const BelowTheFold = ({ classes }) => (
  <Row className={classes.belowTheFold} data-cy={'belowTheFold'}>
    <Col lg={4}>
      <div className={classes.boxStyle}>
        <img className={classes.icons} src={cameraIcon} alt="" />
        <p><HeaderText level={3}>Record a simple 60 second video resume</HeaderText></p>
        <p>
          With IAAM, you sign up and record a 60 second video intro of yourself.
          Your personal dashboard provides the guidelines you need to record an awesome introduction.
        </p>
      </div>
    </Col>
    <Col lg={4}>
      <div className={classes.boxStyle}>
        <img className={classes.icons} src={browsingIcon} alt="" />
        <p><HeaderText level={3}>Employers find top talent on IAAM</HeaderText></p>
        <p>
          Great candidates are often buried under a sea of resumes and easily missed.
          With IAAM, Employers can access a video resume library of talented professionals and view their video intros.
        </p>
      </div>
    </Col>
    <Col lg={4}>
      <div className={classes.boxStyle}>
        <img className={classes.icons} src={shortlistIcon} alt="" />
        <p><HeaderText level={3}>Hiring made easy and efficient</HeaderText></p>
        <p>
          Candidates won’t need to apply for 100’s of jobs or attend multiple interviews anymore.
          Upload your video resume, Employers can view and shortlist you for the right job! It’s that simple!
        </p>
      </div>
    </Col>
  </Row>
);

const HomePage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isAdmin = useSelector(hasRole("admin"));
  const isCandidate = useSelector(hasRole("candidate"));
  const isEmployer = useSelector(hasRole("employer"));
  const needToConfirmEmailUserType = useSelector(getNeedToConfirmEmailUserType);
  if (isAdmin) return <Redirect to="/admin" />;
  if (isCandidate) return <Redirect to="/candidate/dashboard" />;
  if (isEmployer) return <Redirect to="/employer" />;
  if (needToConfirmEmailUserType) return <Redirect to={`/${needToConfirmEmailUserType}/confirmEmail`} />;
  return (
    <HomeLayout
      Headline={<Headline theme={theme} classes={classes} />}
      AboveTheFold={<AboveTheFold theme={theme} classes={classes} />}
      BelowTheFold={<BelowTheFold classes={classes} />}
    />
  );
};

export default HomePage;
