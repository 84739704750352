import React from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from './HomeLayout';
import { useSelector } from 'react-redux';
import { hasRole } from '../auth/selectors';
import { createUseStyles, useTheme } from 'react-jss';
import candidateIcon from '../icons/candidate.svg';
import employerIcon from '../icons/employer.svg';
import Button from '../components/Button';
import HeaderText from '../components/HeaderText';
import signInHeader1920 from '../images/Signin_Header_1920.jpg';
import signInHeader1280 from '../images/Signin_Header_1280.jpg';
import { Col, Row } from 'react-bootstrap';

const useStyles = createUseStyles(theme => ({
  headline: {
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 10%",
    backgroundSize: "cover",
    alignItems: "flex-end",
    height: "calc(100vh - 6rem)",
    margin: "0 calc(0px - (50vw - 50%))",
    padding: "1rem calc(50vw - 50%)",
    backgroundImage: `linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 66%), url(${signInHeader1280})`,
    [theme.responsiveSm]: {
      paddingBottom: '2rem'
    },
    [theme.responsiveXl]: {
      backgroundImage: `linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 66%), url(${signInHeader1920})`,
    }
  },
  belowTheFold: {
    padding: "4rem 0",
    [theme.responsiveLg]: {
      padding: "4rem 2rem",
    }
  },
  boxStyle: {
    border: "1px solid #e0e0e0",
    borderBottom: "4px solid " + theme.colorPrimary,
    boxShadow: "0 0 40px rgba(0, 0, 0, 0.05)",
    padding: "26px",
    maxWidth: "320px",
    height: "362px",
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "1rem auto",
    [theme.responsiveLg]: {
      margin: "0.25rem",
    }
  },
}));

const Headline = ({ theme, classes }) => <div className={classes.headline}>
  <HeaderText level={1} data-cy={'headline'}>Sign Up to IAAM Today</HeaderText>
</div>;

const BelowTheFold = ({ classes, history }) => (
  <Row className={classes.belowTheFold} data-cy={'belowTheFold'}>
    <Col lg={{ span: 4, offset: 2 }}>
      <div className={classes.boxStyle}>
        <img className={classes.icons} src={candidateIcon} alt="Candidate" />
        <HeaderText level={3}>Candidate Sign Up</HeaderText>
        <p>Get ready to upload your video and get noticed by top employers!</p>
        <Button onClick={() => history.push('/candidate/register')} data-cy={'candidateRegister'}>Sign Up</Button>
      </div>
    </Col>
    <Col lg={4}>
      <div className={classes.boxStyle}>
        <img className={classes.icons} src={employerIcon} alt="Employer" />
        <HeaderText level={3}>Employer Sign Up</HeaderText>
        <p>View our amazing talent and make your next hire with IAAM!</p>
        <Button onClick={() => history.push('/employer/register')} data-cy={'employerRegister'}>Sign Up</Button>
      </div>
    </Col>
  </Row>
);

const JoinPage = ({ history }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isAdmin = useSelector(hasRole('admin'));
  const isCandidate = useSelector(hasRole('candidate'));
  const isEmployer = useSelector(hasRole('employer'));
  if (isAdmin) return <Redirect to="/admin" />;
  if (isCandidate) return <Redirect to="/candidate/dashboard" />;
  if (isEmployer) return <Redirect to="/employer" />;
  return (
    <HomeLayout Headline={<Headline theme={theme} classes={classes} />}
                BelowTheFold={<BelowTheFold classes={classes} history={history} />}/>
  );
};

export default JoinPage;
