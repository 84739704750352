/**
 * Building the theme based on the clientWidth
 * @returns object
 */
export default function () {
  const fullViewMediaQuery = "@media (min-width: 1200px)";
  const responsiveLg = "@media (min-width: 992px)";
  const colorText = "#121A45";
  const colorPrimary = "#0070C0";
  return {
    maxWidth: 1200,
    responsiveSm: "@media (min-width: 576px)",
    responsiveMd: "@media (min-width: 768px)",
    responsiveLg,
    responsiveXl: fullViewMediaQuery,
    pageVerticalMargin: "2rem",
    colorBackground: "#FAFAFA",
    colorText,
    colorPrimary,
    colorSecondary: "#00BFFF",
    colorAccent: "#697184",
    navbarBackgroundColor: "#ffffff",
    navBoxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    navigationText: {
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: "auto",
      activeLineBackground: "#0070C0",
    },
    displayText: {
      lineHeight: 2,
      [fullViewMediaQuery]: {
        fontSize: "1rem",
      },
    },
    bodyText: {
      lineHeight: 1.71,
      fontSize: "1rem",
      color: colorText,
    },
    bodyBoldText: {
      lineHeight: 1.71,
      fontWeight: "bold",
      fontSize: "1rem"
    },
    link: {
      color: ["#121A45", "!important"],
      "&:hover": {
        color: ["#0070C0", "!important"],
        "& svg": {
          fill: "#0070C0",
        },
      },
    },
    heading: {
      fontSize: "3rem",
      color: colorText,
    },
    subHeading: {
      fontSize: "2rem",
      color: colorPrimary,
    },
  };
}
