import { UNSHORTLIST_CANDIDATE } from '../../actionTypes';

export const SHORTLIST_CANDIDATE = 'SHORTLIST_CANDIDATE';
export const SHORTLIST_CANDIDATE_SUCCESS = 'SHORTLIST_CANDIDATE_SUCCESS';
export const SHORTLIST_CANDIDATE_FAILED = 'SHORTLIST_CANDIDATE_FAILED';
export const RATE_CANDIDATE = 'RATE_CANDIDATE';
export const RATE_CANDIDATE_SUCCESS = 'RATE_CANDIDATE_SUCCESS';
export const RATE_CANDIDATE_FAILED = 'RATE_CANDIDATE_FAILED';
export const RATE_CANDIDATE_VIDEO = 'RATE_CANDIDATE_VIDEO';
export const RATE_CANDIDATE_VIDEO_SUCCESS = 'RATE_CANDIDATE_VIDEO_SUCCESS';
export const RATE_CANDIDATE_VIDEO_FAILED = 'RATE_CANDIDATE_VIDEO_FAILED';
export const FAVORITE_CANDIDATE = 'FAVORITE_CANDIDATE';
export const FAVORITE_CANDIDATE_SUCCESS = 'FAVORITE_CANDIDATE_SUCCESS';
export const FAVORITE_CANDIDATE_FAILED = 'FAVORITE_CANDIDATE_FAILED';
export const POPULATE_CANDIDATE_DETAILS = 'POPULATE_CANDIDATE_DETAILS';
export const POPULATE_CANDIDATE_DETAILS_SUCCESS = 'FPOPULATE_CANDIDATE_DETAILS_SUCCESS';
export const POPULATE_CANDIDATE_DETAILS_FAILED = 'FPOPULATE_CANDIDATE_DETAILS_FAILED';

export function shortlistCandidate({ employerId, candidateId, categoryTag }, { history }) {
  return {
    type: SHORTLIST_CANDIDATE,
    payload: { employerId, candidateId, categoryTag },
    meta: { history }
  };
}

export function rateCandidate({ employerId, candidateId, rating }) {
  return {
    type: RATE_CANDIDATE,
    payload: { employerId, candidateId, rating },
  };
}

export function rateCandidateVideo({ employerId, candidateId, videoId, rating }) {
  return {
    type: RATE_CANDIDATE_VIDEO,
    payload: { employerId, candidateId, videoId, rating },
  };
}

export function favouriteCandidate({ employerId, candidateId }) {
  return {
    type: FAVORITE_CANDIDATE,
    payload: { employerId, candidateId },
  };
}

export function populateDetails({ candidateId }) {
  return {
    type: POPULATE_CANDIDATE_DETAILS,
    payload: { candidateId },
  };
}

export function unshortlistCandidate(employerId, candidateId) {
  return {
    type: UNSHORTLIST_CANDIDATE,
    payload: { employerId, candidateId },
  };
}
