import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import { Col, Container, Row } from "react-bootstrap";
import { getCandidateOffer } from "./selectors";
import { populateCandidateOffer } from "./actions";
import { useHistory } from 'react-router-dom';
import arrowLineLeft from '../../icons/arrow-left-line.svg';

const useStyles = createUseStyles({
  h4: {
    fontWeight: 600,
    marginBottom: 32,
    display: "flex",
    jutifyContent: "center",
    "& img": {
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  offerWrapper: {
    padding: "30px 15px",
  },
  marginBottomRow: {
    marginBottom: "15px",
  },
});

const CandidateOfferConfirmation = ({ location }) => {
  const history = useHistory();
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const offerId = query.get("id");
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const offer = useSelector(getCandidateOffer);

  useEffect(() => {
    dispatch(populateCandidateOffer({ offerId }));
  }, [dispatch, offerId]);

  function handleGoBack() {
    history.goBack();
  }

  const offerAccepted = offer && offer.offerStatus === "accepted";

  return (
    <Container className={classes.offerWrapper} fluid>
      <Row>
        <Col>
          <h4 className={classes.h4}>
            <img
              alt="arrow-left"
              onClick={handleGoBack}
              src={arrowLineLeft}
              width={16}
            />
            {" "}
            Offer by {offer.companyName}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col data-cy={'offerAccepted'}>
          <p hidden={!offerAccepted}>
            Congratulations on your new role - We will be in touch shortly contact <a href="mailto:info@iaam.ca">info@iaam.ca</a> for more information
          </p>
        </Col>
      </Row>
     <br/>
    </Container>
  );
};

export default CandidateOfferConfirmation;
