import * as yup from 'yup';

export const getEmployerId = state => (state.auth.jwt && state.auth.jwt.sub) || '';
export const isEmailConfirmed = state => (state.auth.jwt && state.auth.jwt.emailConfirmed) || false;
export const getEmailConfirmationError = state => state.employerConfirmEmail.error;
export const getConfirmingEmail = state => state.employerConfirmEmail.confirming;

export const getEmployerValidationSchema = state => yup.object({

  firstName: yup.string().required('First name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
  lastName: yup.string().required('Last name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
  companyName: yup.string().required('Company name is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
  emailAddress: yup.string().email('Invalid email').required('Company email address is required').max(255, yup => `Value cannot be more than ${yup.max} characters`),
  acceptedTOS: yup.bool().oneOf([true], 'Must Accept Terms and Conditions'),
  password: yup.string().required('Password is required').min(8, 'Too Short!'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords must match").required('Password confirm is required')
});

export const getEmployerDetailsValidationSchema = state => yup.object({
  firstName: yup.string().required('First name is required').max(20,yup => `Value cannot be more than ${yup.max} characters`),
  lastName: yup.string().required('Last name is required').max(20, yup => `Value cannot be more than ${yup.max} characters`),
  jobTitle: yup.string().max(40, yup => `Value cannot be more than ${yup.max} characters`),
  city: yup.string().required('City is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
  province: yup.string().required('State/Province is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
  country: yup.string().required('Country is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
  companyName: yup.string().required('Company name is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
  postalCode: yup
       .string()
       .when('country', {
           is: 'United States',
           then: yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid postal code'),
       })
       .when('country', {
           is: 'Canada',
           then: yup.string().matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Invalid postal code'),
       })
        .required('Postal code is required'),
   phoneNumber: yup
       .string()
       .when('country', {
           is: country => ["United States", "Canada"].includes(country),
           then: yup.string().matches(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/, 'Invalid phone number')
       })
       .required('Phone number is required'),
   industry: yup.string(),
   emailAddress: yup.string().email('Invalid email').required('Email address is required').max(255, yup => `Value cannot be more than ${yup.max} characters`),
   comments: yup.string().max(2000, yup => `Value cannot be more than ${yup.max} characters`)
});

export const getMakeOfferSchema = () => {
  const minRate = parseFloat(process.env.REACT_APP_EMPLOYER_MIN_RATE);
  return yup.object({
    proposedHourlyRate: yup.number().required('Hourly Rate is required').min(minRate, `Cannot offer below minimum of $${minRate}/hr`),
    jobTitle: yup.string().required('Job Title is required').max(40, yup => `Value cannot be more than ${yup.max} characters`),
    jobDescription: yup.string().required('Job Description is required').max(500, 'Value cannot be more than 500 characters'),
    startDate: yup.string().required('Start Date is required'),
  });
};

export const getMakingOffer = state => state.employer.makingOffer;

export const getMakeOfferErrors = state => state.employer.makeOfferErrors;

export const getEmployerCandidateShortlist = state => state.employer.shortlist;

export const getEmployerProfile = state => state.employerProfile;

export const getEmployerProfileAlert = state => {
  const error = state.employerProfile.error;
  let visible = false, variant = '', message = '';
  if (error) {
    visible = true;
    if (error.response && error.response.status === 404) {
      variant = 'info';
      message = "You don't have a profile. Set it up right now!";
    } else if (error.response && error.response.status === 409) {
      variant = 'warning';
      message = error.response.data.message;
    } else {
      variant = 'warning';
      message = error.message;
    }
  }
  return { visible, variant, message };
};

export const getRegistrationErrors = state => state.employer.registrationErrors;

export const getEmployerOffer = state => state.employer.offer || {};

export const getCandidateProfile = state => state.employerCandidateProfile;

export const getOffers = state => state.employerOffers;

export const getPromoInfo = state => state.promoInfo;
