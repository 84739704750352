import React from 'react';
import { Button as BsButton } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  button: {
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '0.25rem',
    backgroundColor: ['#0070C0', '!important'],
    '&:hover': {
      backgroundColor: ['#121A45', '!important']
    },
    width: ({ width }) => width
  }
}));

const Button = ({ width, className, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, width });
  const classNames = [classes.button];
  if (className) classNames.push(className);
  return (<BsButton className={classNames} {...props} />);
};

export default Button;
