import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import EmployerConfirmEmail from './ConfirmEmail'
import React from 'react'
import EmployerDashboard from './EmployerDashboard'
import CandidateDetailPage from '../candidateDetails/CandidateDetailPage'
import RegisterEmployer from './RegisterEmployer'
import MakeOffer from './MakeOffer'
import EmployerCandidateShortlist from './EmployerCandidateShortlist'
import PaymentRequest from './PaymentRequest'
import NoPaymentDisplay from '../../components/NoPaymentDisplay'
import LoginPage from '../LoginPage'
import EmployerViewOffer from './EmployerViewOffer'
import EmployerOfferConfirmation from './EmployerOfferConfirmation'
import { permissions } from '../../security/permissions'
import PermissionRoute from '../../components/PermissionsRoute'

const EmployerRoutes = () => {
  return (
    <Router basename="/employer">
      <Switch>
        {/* DO NOT REMOVE */}
        <Route exact path="/login" component={LoginPage}/>
        <Route exact path="/register" component={RegisterEmployer}/>
        <PermissionRoute exact path="/confirmemail"><EmployerConfirmEmail/></PermissionRoute>
        <PermissionRoute exact path="/makeOffer"
                         permissionsRequired={[permissions.canActAsEmployer, permissions.canMakeOffers]}
                         paymentSecurityOptions={{
                           NoPaymentDisplay: NoPaymentDisplay,
                           paymentPermissions: [permissions.canMakeOffers],
                         }}>
          <MakeOffer/>
        </PermissionRoute>
        <PermissionRoute exact path="/candidatedetails"
                         permissionsRequired={[permissions.canActAsEmployer, permissions.canViewCandidatesDetails]}
                         paymentSecurityOptions={{
                           NoPaymentDisplay: NoPaymentDisplay,
                           paymentPermissions: [permissions.canViewCandidatesDetails],
                         }}>
          <CandidateDetailPage/>
        </PermissionRoute>
        <PermissionRoute exact path="/shortlist"
                         permissionsRequired={[permissions.canActAsEmployer, permissions.canShortlist]}
                         paymentSecurityOptions={{
                           NoPaymentDisplay: NoPaymentDisplay,
                           paymentPermissions: [permissions.canShortlist],
                         }}>
          <EmployerCandidateShortlist/>
        </PermissionRoute>
        <PermissionRoute exact path="/paymentrequest" permissionsRequired={[permissions.canActAsEmployer]}>
          <PaymentRequest/>
        </PermissionRoute>
        <PermissionRoute exact path="/offer" permissionsRequired={[permissions.canActAsEmployer]}>
          <EmployerViewOffer/>
        </PermissionRoute>
        <PermissionRoute exact path="/offerconfirmation" permissionsRequired={[permissions.canActAsEmployer]}>
          <EmployerOfferConfirmation/>
        </PermissionRoute>
        {/* THIS HAS TO BE LAST */}
        <PermissionRoute path="/:page?/:section?/:id?" permissionsRequired={[permissions.canActAsEmployer]}>
          <EmployerDashboard />
        </PermissionRoute>
      </Switch>
    </Router>
  )
}

export default EmployerRoutes
