import {
  FETCH_CANDIDATE,
  FETCH_CANDIDATE_FAILED,
  FETCH_CANDIDATE_SUCCESS,
  MODIFY_CANDIDATE_DETAILS,
  MODIFY_CANDIDATE_DETAILS_FAILED,
  MODIFY_CANDIDATE_DETAILS_PROGRESS,
  MODIFY_CANDIDATE_DETAILS_SUCCESS,
} from '../../actionTypes';

const defaultState = {
  loaded: false,
  saved: false,
  fetching: false,
  saving: false,
  error: '',
  uploadProgress: 0,
  // data
  candidateId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  postalCode: '',
  country: '',
  jobCategories: [],
  phoneNumber: '',
  legalToWorkInCountry: null, // nullable string: yes, no
  openToRelocate: null,       // nullable string: yes, no
  expectedStartDate: '',
  expectedHourlyRate: 0,
  comments: '',
  resumeUrl: '',
};

const boolToYesNo = (value) => value === true
  ? 'yes'
  : value === false
    ? 'no'
    : null;

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case FETCH_CANDIDATE:
      return { ...defaultState, fetching: true };
    case FETCH_CANDIDATE_SUCCESS:
      const { legalToWorkInCountry, openToRelocate, ...rest } = action.payload;
      return {
        ...state,
        fetching: false,
        loaded: true,
        saved: false,
        ...rest,
        legalToWorkInCountry: boolToYesNo(legalToWorkInCountry),
        openToRelocate: boolToYesNo(openToRelocate),
      };
    case FETCH_CANDIDATE_FAILED: {
      const error = action.payload;
      return { ...state, fetching: false, error: error.message };
    }
    case MODIFY_CANDIDATE_DETAILS: {
      return {
        ...state,
        error: '',
        saved: false,
        saving: true,
        uploadProgress: 0,
      };
    }
    case MODIFY_CANDIDATE_DETAILS_SUCCESS: {
      return { ...state, saving: false, saved: true };
    }
    case MODIFY_CANDIDATE_DETAILS_FAILED: {
      const error = action.payload;
      return { ...state, saving: false, error: error.message };
    }
    case MODIFY_CANDIDATE_DETAILS_PROGRESS: {
      const { percentage } = action.payload;
      return { ...state, uploadProgress: percentage };
    }
    default:
      return state;
  }
}
