import React from 'react';
import HeaderText from '../components/HeaderText';

const Forbidden = () => {
  return (
    <>
      <HeaderText level={1} data-cy={'forbidden'}>Access is forbidden.</HeaderText>
    </>
  );
};

export default Forbidden;
