import React from 'react';
import HeaderText from '../components/HeaderText';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  privacyPolicy: {
    padding: theme.pageVerticalMargin + ' 0'
  }
}));

const PrivacyPolicyPage = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.privacyPolicy} data-cy={'privacyPolicy'}>
      <p><HeaderText level={1}>IAAM Privacy Policy</HeaderText></p>
      <p>
        <HeaderText level={4}>Our Commitment</HeaderText>
        At IAAM Recruitment Inc. (It’s All About Me), we are committed to protecting your privacy and maintaining strong practices in the handling of personal information in our care.<br/>
        IAAM Recruitment Inc., including its subsidiaries and affiliates (collectively, “IAAM”), referred to in this Privacy Policy as “IAAM”, “we”, “us” or “our”, has developed this Privacy Policy (this “Policy”) in accordance with applicable privacy legislation.
      </p>
      <p>
        <HeaderText level={4}>What is Personal Information?</HeaderText>
        This Policy applies to the personal information of IAAM’s members and other individuals we deal with. This Policy does not apply to personal information of our employees or employee applicants, or to information that is not personal information.<br/>
        When we say “Information” we are referring to information that is about you that applicable law classifies as personal information. It does not include information that is anonymous, aggregated, or is no longer identifiable to a specific person.
      </p>
      <p>
        <HeaderText level={4}>Privacy Policies 101</HeaderText>
        <HeaderText level={4}>Why we collect information</HeaderText>
        Let’s start with why we collect your Information. We collect Information for the purposes of managing and administering our business. This includes enabling us to verify and approve you for our awesome platform, completing your documentation when you receive a job offer and set you up for payroll when you successful accept a new job through the platform. We may collect Information directly from you or from third parties with your consent or as permitted or required by law.<br/>
        <HeaderText level={4}>What information do we collect</HeaderText>

        <li>Information we collect includes, but is not limited to:</li>
        <li>Name, address, email address, telephone number, mobile phone number and other contact details;</li>
        <li>Bank account information, including bank account, transit and branch numbers required for payroll;</li>
        <li>Secure payment details to keep your IAAM account active;</li>
        <li>Information relating to ongoing interactions with IAAM;</li>
        <li>Social Insurance Number, date of birth, and other personal identifiers (if you choose to provide them to us). If you give us your Social Insurance Number, we will use that information to set you up for payroll of and when you receive a job offer through the IAAM platform</li>
        <li>Information we collect automatically when you visit our website (see “Cookies and Similar Technologies” below);</li>
        <li>Other information with your consent or as permitted or required by law.</li>
        <HeaderText level={4}>How do we use your information</HeaderText>
        We’re transparent about how we collect, use, disclose, and safeguard Information in connection with our business in Canada.<br/>
        We may use your Information:<br/>

        <li>to activate, service and maintain your IAAM account;</li>
        <li>to communicate with you in respect of your IAAM Account or other IAAM product or service;</li>
        <li>to enforce the terms of the products or services you have obtained from us;</li>
        <li>to verify personal details required to set you up for payroll with IAAM;</li>
        <li>to protect you and us from theft, fraud, and unauthorized access to your account; to assess your ongoing needs for products or services;</li>
        <li>to inform you of special promotions or products (see “How We Use and Share Information for Marketing Purposes” below);</li>
        <li>to enter you in and administer promotional contests that we may offer from time to time, and if you win, to identify you for promotion and advertising purposes; and</li>
        <li>for other purposes with your consent or as permitted or required by law.</li>

        We may also exchange personal information with our affiliates as necessary for the purposes described above.
        <HeaderText level={4}>When is your information used</HeaderText>
        The following are examples of when we may use your Information:<br/>

        <li>We may use your personal details to maintain and service your IAAM account</li>
        <li>IAAM will use your information solely to assist you with your job search through the IAAM platform, to provide interested employers with additional information so they can shortlist you for a specific job opportunity, to promote your video resume to top employers and to set your account up for payroll if you receive and accept a suitable job offer</li>
        <li>We may disclose Information to our affiliates for internal audit, management, billing or administrative purposes including defending and bringing legal actions.</li>
        <li>We may disclose your Information as necessary to meet legal, regulatory, industry self-regulatory, insurance, audit, and security requirements, and otherwise with your consent or as permitted or required by applicable law (including foreign law applicable to our service providers)</li>

      </p>
      <p>
        <HeaderText level={4}>How We Use and Share Information for Marketing Purposes</HeaderText>
        We may use your Information in order to identify your preferences, determine your eligibility for special offers and discounts, and to contact you in regard to such offers and discounts, or to provide other information about our products, services, promotions and events.
        <HeaderText level={4}>Opt-out</HeaderText>
        If you no longer want us to use or share your Information for marketing purposes, as described above, please contact us by mail:<br/>
        IAAM Recruitment Inc. - Attn: IAAM Advertising Office<br/>
        419-1080 Mainland Street, Vancouver, BC, V6B 2T4<br/>
        To opt out of our e-mail communications, you may click the unsubscribe link at the bottom of the email message or email unsubscribe@IAAM.ca with the word “Unsubscribe” in the subject line. Please note that if you opt-out of marketing communications, we may still send you account-based information relating to your IAAM Account or related IAAM product(s) or service(s).
      </p>
      <p>
        <HeaderText level={4}>How We Protect Your Information Across Borders</HeaderText>
        <HeaderText level={4}>Information across borders</HeaderText>
        We may transfer Information to outside agents or service providers (including our affiliates acting in this capacity) that perform services on our behalf, for example mailing, call center, billing, collections, skip-tracing, marketing, analytics, information technology and/or data hosting or processing services or similar services, or otherwise to collect, use, disclose, store or process Information on our behalf for the purposes described in this Policy. Some of these service providers may be located outside of Canada, including in the United States, and as a result your Information may be processed and stored on servers outside of Canada for the purposes described in this Policy.<br/>
        Reasonable contractual and other measures we may take to protect your Information while processed, stored or handled by these service providers are subject to applicable Canadian and foreign legal requirements, including lawful requirements to disclose personal information to government and national security authorities in certain circumstances.
      </p>
      <p>
        <HeaderText level={4}>Cookies and Similar Technologies</HeaderText>
        When you visit the IAAM website, we may collect and use your internet protocol address to help diagnose problems with our server and to administer the website, to identify users and their activities on the IAAM website, and to gather broad demographic information.<br/>
        We may also use “cookies”, a technology that installs a small amount of information on a website user’s computer to permit our website to recognize future visits using that computer. Cookies enhance the convenience and use of our website. For example, the information provided through cookies is used to recognize you as a previous user of our website, to track your activity on our website, to respond to your needs, and to otherwise facilitate your experience on our website. You may choose to decline cookies if your browser permits but doing so may affect your use of our website and your ability to access certain features of our website or engage in transactions through our website.<br/>
        IAAM also uses web cookies and pixel tags to track user behavior on our websites and to compile reports regarding user demographics, website traffic patterns, and website purchases. We may then provide these reports to advertisers and others.<br/>
        Within our applications, we may also use analytic tools and automated tracking technology or methods. We use these tools to analyze and enhance the user experience. IAAM may use third party advertising networks to display advertising on our websites or other websites. These advertising networks use cookies or similar technologies to track your navigation habits on our websites and third-party websites to serve you advertising that is customized to your inferred interests and preferences. For example, a cookie may be placed on your computer when you visit a IAAM website, which is recognized and used by third party advertising networks to serve you with a IAAM ad when you visit other websites within their advertising network. As described above, you may be able to set your browser to decline cookies. If you decline cookies you may still receive online advertising; however, it may be less personalized. Some advertising networks participate in industry programs that include web pages to visit to opt out. For example: http://youradchoices.ca/choices.
      </p>
      <p>
        <HeaderText level={4}>Third Party Websites</HeaderText>
        We may provide links to other websites that are not operated or controlled by IAAM. These websites are not governed by this Policy. We recommend reviewing the privacy statement or privacy policy associated with any linked website prior to visiting the site or providing any Information.
      </p>
      <p>
        <HeaderText level={4}>Your Consent</HeaderText>
        IAAM collects, uses and discloses your Information with your consent, or as permitted or required by law. Generally, by providing us with your Information, we will assume that you consent to our collection, use and disclosure of such Information for the purposes identified or described in this Policy, if applicable, or otherwise at the time of collection.<br/>
        We may be required or permitted under statute or regulation to collect, use or disclose Information without your consent, for example to comply with a court order, to comply with local or federal regulations or a legally permitted inquiry by a government agency, or to collect a debt owed to us.<br/>
        You may withdraw your consent to our collection, use and disclosure of your Information at any time, subject to contractual and legal restrictions and reasonable notice. We will let you know the consequences of refusing or withdrawing consent. Note that if you withdraw your consent to certain uses of your Information, we may no longer be able to provide certain of our products or services.
      </p>
      <p>
        <HeaderText level={4}>Risks</HeaderText>
        No method of transmission over the Internet, or method of electronic storage, is 100% secure. We take reasonable measures to protect personal information in our care. On an ongoing basis, we assess the risks associated with the collection, use and disclosure of personal information and we update our privacy and security practices as necessary. Should an unforeseen event occur, such as a data breach or harm caused by bad actors, we have procedures in place to identify, assess and minimize any further risks and determine if there is a real risk of significant harm to individuals.
      </p>
      <p>
        <HeaderText level={4}>Access, Correction and Contacting Us</HeaderText>
        As part of delivering an optimal service experience, the accuracy of your Information is important to us.<br/>
        If you wish to request access to or correction of your Information you may write to:<br/>
        IAAM Recruitment Inc. - Attn: IAAM Admin<br/>
        419-1080 Mainland Street, Vancouver, BC, V6B 2T4<br/>
        Your right to access or correct your Information is subject to applicable legal restrictions. We may take reasonable steps to verify your identity before granting access or making corrections. If you wish to make inquiries or complaints or have other concerns about our personal information practices, you may write to us as described above or use the Live Chat option on our website.<br/>
        <HeaderText level={4}>Policy Update</HeaderText>
        This Policy may be revised from time to time. If we intend to use or disclose personal Information for purposes materially different than those described in this Policy, we will make reasonable efforts to notify affected individuals, if necessary, including by posting the revised Policy on our website at IAAM.ca. We encourage you to visit this website frequently to obtain the current version. Your continued provision of personal Information or use of our services following any changes to this Policy constitutes your acceptance of any such changes. This Policy is effective as of October 2019.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
