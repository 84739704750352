import React from 'react';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import PayPalSubscriptionButton from '../components/PayPalSubscriptionButton';
import HospitalitySubscriptionButton from '../components/HospitalitySubscriptionButton';
import FreeSubscriptionButton from '../components/FreeSubscriptionButton'

class PayPalProvider {
  get canCancel() {
    return true;
  }
  get canUpdate() {
    return true;
  }
  get UpdateButton() {
    return (
      <Button as={'a'} href={'https://paypal.com'} target={'_blank'} rel={'noopener noreferrer'}>
        Update Payment
      </Button>
    );
  }
  get canRenew() {
    return true;
  }
  get RenewButton() {
    return (
      <Link to={'/paymentRequest'}><Button>Renew Subscription</Button></Link>
    );
  }
  get canSubscribe() {
    return true;
  }
  get SubscribeButton() {
    return (
      <Link to={'/paymentRequest'}><Button data-cy={'subscriptionButton'}>Subscribe To IAAM</Button></Link>
    );
  }
  getPaymentButton(subscriptionPlan, promoCode, onPaymentSuccess) {
    return (
      <PayPalSubscriptionButton
        subscriptionPlan={subscriptionPlan}
        onPaymentSuccess={onPaymentSuccess}/>
    );
  }
}

class HospitalityProvider {
  get canCancel() {
    return false;
  }
  get canRenew() {
    return false;
  }
  get canUpdate() {
    return false;
  }
  get canSubscribe() {
    return true;
  }
  get SubscribeButton() {
    return (
      <Link to={'/paymentRequest'}><Button data-cy={'subscriptionButton'}>Subscribe to IAAM</Button></Link>
    );
  }
  getPaymentButton(subscriptionPlan, activationCode, onPaymentSuccess) {
    return (
      <HospitalitySubscriptionButton subscriptionPlan={subscriptionPlan} activationCode={activationCode} onPaymentSuccess={onPaymentSuccess} />
    );
  }
}

class FreeProvider {
  get canCancel() {
    return false;
  }
  get canRenew() {
    return false;
  }
  get canUpdate() {
    return false;
  }
  get canSubscribe() {
    return true;
  }
  get SubscribeButton() {
    return (
      <Link to={'/paymentRequest'}><Button data-cy={'subscriptionButton'}>Subscribe to IAAM</Button></Link>
    );
  }
  getPaymentButton(subscriptionPlan, promoCode, onPaymentSuccess) {
    return (
      <FreeSubscriptionButton subscriptionPlan={subscriptionPlan} promoCode={promoCode} onPaymentSuccess={onPaymentSuccess} />
    );
  }
}


export default function getPaymentProvider(type) {
  if (type === 'Free') {
    return new FreeProvider();
  }
  if (type === 'Hospitality') {
    return new HospitalityProvider();
  }
  if (type === 'PayPal') {
    return new PayPalProvider();
  }
  return null;
}
