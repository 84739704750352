export const INIT_STATE = 'INIT_STATE';

export const NEW_APP_CONTENT_AVAILABLE = 'NEW_APP_CONTENT_AVAILABLE';
export const APP_UPDATE_HIDE_ALERT = 'APP_UPDATE_HIDE_ALERT';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const AUTH_INVALID_ERROR = 'AUTH_INVALID_ERROR';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_ERROR = 'AUTH_REFRESH_ERROR';
export const AUTH_RESTORE = 'AUTH_RESTORE';
export const AUTH_FORCE_REFRESH = 'AUTH_FORCE_REFRESH';

export const EMPLOYER_CONFIRM_EMAIL = 'EMPLOYER_CONFIRM_EMAIL';
export const EMPLOYER_CONFIRM_EMAIL_SUCCESS = 'EMPLOYER_CONFIRM_EMAIL_SUCCESS';
export const EMPLOYER_CONFIRM_EMAIL_FAILED = 'EMPLOYER_CONFIRM_EMAIL_FAILED';
export const REGISTER_EMPLOYER = 'REGISTER_EMPLOYER';
export const REGISTER_EMPLOYER_SUCCESS = 'REGISTER_EMPLOYER_SUCCESS';
export const REGISTER_EMPLOYER_FAILED = 'REGISTER_EMPLOYER_FAILED';
export const MODIFY_EMPLOYER_DETAILS = 'MODIFY_EMPLOYER_DETAILS';
export const MODIFY_EMPLOYER_DETAILS_SUCCESS = 'MODIFY_EMPLOYER_DETAILS_SUCCESS';
export const MODIFY_EMPLOYER_DETAILS_FAILED = 'MODIFY_EMPLOYER_DETAILS_FAILED';
export const EMPLOYER_FETCH_SUBSCRIPTION = 'EMPLOYER_FETCH_SUBSCRIPTION_STATUS';
export const EMPLOYER_FETCH_SUBSCRIPTION_SUCCESS = 'EMPLOYER_FETCH_SUBSCRIPTION_SUCCESS';
export const EMPLOYER_FETCH_SUBSCRIPTION_FAILED = 'EMPLOYER_FETCH_SUBSCRIPTION_FAILED';

export const REGISTER_CANDIDATE = 'REGISTER_CANDIDATE';
export const REGISTER_CANDIDATE_SUCCESS = 'REGISTER_CANDIDATE_SUCCESS';
export const REGISTER_CANDIDATE_FAILED = 'REGISTER_CANDIDATE_FAILED';

export const MODIFY_CANDIDATE_DETAILS = 'MODIFY_CANDIDATE_DETAILS';
export const MODIFY_CANDIDATE_DETAILS_SUCCESS = 'MODIFY_CANDIDATE_DETAILS_SUCCESS';
export const MODIFY_CANDIDATE_DETAILS_FAILED = 'MODIFY_CANDIDATE_DETAILS_FAILED';
export const MODIFY_CANDIDATE_DETAILS_PROGRESS = 'MODIFY_CANDIDATE_DETAILS_PROGRESS';

export const UPLOAD_CANDIDATE_VIDEO = 'UPLOAD_CANDIDATE_VIDEO';
export const UPLOAD_CANDIDATE_VIDEO_SUCCESS = 'UPLOAD_CANDIDATE_VIDEO_SUCCESS';
export const UPLOAD_CANDIDATE_VIDEO_FAILED = 'UPLOAD_CANDIDATE_VIDEO_FAILED';
export const UPLOAD_CANDIDATE_VIDEO_PROGRESS = 'UPLOAD_CANDIDATE_VIDEO_PROGRESS';
export const UPLOAD_CANDIDATE_VIDEO_RESET = 'UPLOAD_CANDIDATE_VIDEO_RESET';

export const FETCH_PENDING_APPROVAL_VIDEOS = 'FETCH_PENDING_APPROVAL_VIDEOS';
export const FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS = 'FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS';
export const FETCH_PENDING_APPROVAL_VIDEOS_FAILED = 'FETCH_PENDING_APPROVAL_VIDEOS_FAILED';

export const APPROVE_VIDEO = 'APPROVE_VIDEO';
export const APPROVE_VIDEO_SUCCESS = 'APPROVE_VIDEO_SUCCESS';
export const APPROVE_VIDEO_FAILED = 'APPROVE_VIDEO_FAILED';

export const REJECT_VIDEO = 'REJECT_VIDEO';
export const REJECT_VIDEO_SUCCESS = 'REJECT_VIDEO_SUCCESS';
export const REJECT_VIDEO_FAILED = 'REJECT_VIDEO_FAILED';

export const MAKE_OFFER = 'MAKE_OFFER';
export const MAKE_OFFER_SUCCESS = 'MAKE_OFFER_SUCCESS';
export const MAKE_OFFER_FAILED = 'MAKE_OFFER_FAILED';

export const POPULATE_EMPLOYER_CANDIDATE_SHORTLIST = 'POPULATE_EMPLOYER_CANDIDATE_SHORTLIST';
export const POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS = 'POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS';
export const POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_FAILED = 'POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_FAILED';

export const EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS = 'EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS';
export const EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS = 'EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS';
export const EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED = 'EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED';

export const FETCH_CANDIDATE_VIDEOS = 'FETCH_CANDIDATE_VIDEOS';
export const FETCH_CANDIDATE_VIDEOS_SUCCESS = 'FETCH_CANDIDATE_VIDEOS_SUCCESS';
export const FETCH_CANDIDATE_VIDEOS_FAILED = 'FETCH_CANDIDATE_VIDEOS_FAILED';

export const SET_USER_PAID = 'SET_USER_PAID';

export const FETCH_CANDIDATE = 'FETCH_CANDIDATE';
export const FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS';
export const FETCH_CANDIDATE_FAILED = 'FETCH_CANDIDATE_FAILED';

export const POPULATE_CANDIDATE_EMPLOYER_SHORTLIST = 'POPULATE_CANDIDATE_EMPLOYER_SHORTLIST';
export const POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS = 'POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_SUCCESS';
export const POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_FAILED = 'POPULATE_CANDIDATE_EMPLOYER_SHORTLIST_FAILED';

export const FETCH_MY_EMPLOYER_PROFILE = 'FETCH_MY_EMPLOYER_PROFILE';
export const FETCH_MY_EMPLOYER_PROFILE_SUCCESS = 'FETCH_MY_EMPLOYER_PROFILE_SUCCESS';
export const FETCH_MY_EMPLOYER_PROFILE_FAILED = 'FETCH_MY_EMPLOYER_PROFILE_FAILED';

export const FETCH_EMPLOYER_OFFER = 'FETCH_EMPLOYER_OFFER';
export const FETCH_EMPLOYER_OFFER_SUCCESS = 'FETCH_EMPLOYER_OFFER_SUCCESS';
export const FETCH_EMPLOYER_OFFER_FAILED = 'FETCH_EMPLOYER_OFFER_FAILED';

export const UNSHORTLIST_CANDIDATE = 'UNSHORTLIST_CANDIDATE';
export const UNSHORTLIST_CANDIDATE_SUCCESS = 'UNSHORTLIST_CANDIDATE_SUCCESS';
export const UNSHORTLIST_CANDIDATE_FAILED = 'UNSHORTLIST_CANDIDATE_FAILED';

export const EMPLOYER_FETCH_CANDIDATE_PROFILE = 'EMPLOYER_FETCH_CANDIDATE_PROFILE';
export const EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS = 'EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS';
export const EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED = 'EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED';

export const ADMIN_FETCH_CANDIDATES = 'ADMIN_FETCH_CANDIDATES';
export const ADMIN_FETCH_CANDIDATES_SUCCESS = 'ADMIN_FETCH_CANDIDATES_SUCCESS';
export const ADMIN_FETCH_CANDIDATES_FAILED = 'ADMIN_FETCH_CANDIDATES_FAILED';

export const ADMIN_FIND_CANDIDATE_BY_VIDEO_ID = 'ADMIN_FIND_CANDIDATE_BY_VIDEO_ID';

export const ADMIN_FETCH_EMPLOYERS = 'ADMIN_FETCH_EMPLOYERS';
export const ADMIN_FETCH_EMPLOYERS_SUCCESS = 'ADMIN_FETCH_EMPLOYERS_SUCCESS';
export const ADMIN_FETCH_EMPLOYERS_FAILED = 'ADMIN_FETCH_EMPLOYERS_FAILED';

export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE = 'FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE';
export const FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS = 'FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS';
export const FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED = 'FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED';

export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
export const SUBMIT_CONTACT_FORM_FAILED = 'SUBMIT_CONTACT_FORM_FAILED';

export const FETCH_EMPLOYER_OFFERS = 'FETCH_EMPLOYER_OFFERS';
export const FETCH_EMPLOYER_OFFERS_SUCCESS = 'FETCH_EMPLOYER_OFFERS_SUCCESS';
export const FETCH_EMPLOYER_OFFERS_FAILED = 'FETCH_EMPLOYER_OFFERS_FAILED';

export const ADMIN_BLOCK_EMPLOYER = 'ADMIN_BLOCK_EMPLOYER';
export const ADMIN_BLOCK_EMPLOYER_SUCCESS = 'ADMIN_BLOCK_EMPLOYER_SUCCESS';
export const ADMIN_BLOCK_EMPLOYER_FAILURE = 'ADMIN_BLOCK_EMPLOYER_FAILURE';
export const ADMIN_UNBLOCK_EMPLOYER = 'ADMIN_UNBLOCK_EMPLOYER';
export const ADMIN_UNBLOCK_EMPLOYER_SUCCESS = 'ADMIN_UNBLOCK_EMPLOYER_SUCCESS';
export const ADMIN_UNBLOCK_EMPLOYER_FAILURE = 'ADMIN_UNBLOCK_EMPLOYER_FAILURE';

export const ADMIN_FETCH_BILLING_REPORT = 'ADMIN_FETCH_BILLING_REPORT';
export const ADMIN_FETCH_BILLING_REPORT_SUCCESS = 'ADMIN_FETCH_BILLING_REPORT_SUCCESS';
export const ADMIN_FETCH_BILLING_REPORT_FAILURE = 'ADMIN_FETCH_BILLING_REPORT_FAILURE';
export const ADMIN_DOWNLOAD_BILLING_REPORT = 'ADMIN_DOWNLOAD_BILLING_REPORT';
