import { delay, take, takeLatest, race, fork, call, cancel, put, select, retry } from 'redux-saga/effects';
// the select redux-saga effect allows you to get application state, and use selectors
import apiClient from '../utils/api';
import {
  AUTH_FORCE_REFRESH,
  AUTH_INVALID_ERROR,
  AUTH_REFRESH_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER, LOGOUT_USER_SUCCESS,
} from '../actionTypes';
import { getAuthentication } from './selectors';
import {
  authInvalidError,
  authRefreshError,
  authRestore,
  loginUserFailed,
} from './actions';
import { parseJwtToken } from '../utils/parseJwtToken';

const TOKEN_ENDPOINT = 'v1/auth/token';

//TODO using localStorage is subject to xss attack we should use secure cookies, this will do for now
function * restoreAuth () {
  const json = window.localStorage.getItem(authStorageKey);
  if (json) {
    const token = JSON.parse(json);
    yield put(authRestore(token));
  }
}
export function persistAuth (token) {
  if (!token) {
    window.localStorage.removeItem(authStorageKey);
  } else {
    const json = JSON.stringify(token);
    window.localStorage.setItem(authStorageKey, json);
  }
}

function * logoutUser () {
  const { refresh_token } = yield select(getAuthentication);
  try {
    yield call(apiClient.post, `v1/auth/revoke`, { refresh_token });
  } catch (err) {
    console.warn('could not revoke the refresh token:', err);
  }
  persistAuth();
  yield put({
    type: LOGOUT_USER_SUCCESS
  });
}

function tokenHasExpired({ expires_in, created_at }) {
  const MILLISECONDS_IN_MINUTE = 1000 * 60;

  // Set refreshBuffer to 10 minutes
  // so the token is refreshed before expiry
  const refreshBuffer = MILLISECONDS_IN_MINUTE * 2;

  // Expiry time
  // multiplied by 1000 as server time are return in seconds, not milliseconds
  const expiresAt = new Date((created_at + expires_in) * 1000).getTime();
  // The current time
  const now = new Date().getTime();
  // When we want the token to be refreshed
  const refreshAt = expiresAt - refreshBuffer;

  return now >= refreshAt;
}

function* getAccessToken() {
  const authentication = yield select(getAuthentication);

  if (tokenHasExpired(authentication)) {
    yield race({
      refreshError: take(AUTH_INVALID_ERROR),
      tokenRefreshed: take(AUTH_REFRESH_SUCCESS)
    });
  }

  const latestAuthentication = yield select(getAuthentication);
  return latestAuthentication.access_token;
}

export function * authenticateRequest (...args) {
  const accessToken = yield getAccessToken();

  if (accessToken) {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    };
    try {
      return yield call(...args, config);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        yield put(authInvalidError(err.response));
        throw new AuthenticationError("Unauthorized");
      } else {
        throw err;
      }
    }
  } else {
    throw new AuthenticationError("No access token");
  }
}

export function * authenticateRequestWithConfig (...args) {
  const accessToken = yield getAccessToken();

  if (accessToken) {
    const cfg = args.pop();
    const config = {
      ...cfg,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    };
    try {
      return yield call(...args, config);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        yield put(authInvalidError(err.response));
        throw new AuthenticationError("Unauthorized");
      } else {
        throw err;
      }
    }
  } else {
    throw new AuthenticationError("No access token");
  }
}

export function * callTokenEndpointWithRepeat(validate, params) {
  let result;
  const request = async () => {
    const response = await apiClient.post(TOKEN_ENDPOINT, params);
    const jwtToken = parseJwtToken(response.data.access_token);
    validate(jwtToken);
    return response;
  };
  result = yield retry(3, parseInt(process.env.REACT_APP_API_RETRY_DELAY, 10), request);

  return result;
}

function * refreshToken(refresh_token, validate = () => {}) {
  try {
    const params = {
      refresh_token,
      grant_type: 'refresh_token',
    };
    const { data: token } = yield call(callTokenEndpointWithRepeat, validate, params);
    yield put({
      type: AUTH_REFRESH_SUCCESS,
      payload: { token }
    });
    persistAuth(token);
    return true;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        yield put(authInvalidError(error.response));
      } else {
        yield put(authRefreshError(error.response));
      }
    } else {
      yield put(authRefreshError(error));
    }
    return false;
  }
}

function * forceRefreshToken (action) {
  const { refresh_token } = yield select(getAuthentication);
  yield call(refreshToken, refresh_token, action.payload.validate);
}

export function * watchForceRefresh () {
  yield takeLatest(AUTH_FORCE_REFRESH, forceRefreshToken);
}

function * refreshLoop() {
  const maxRetries = 5;
  let retries = 0;

  while (true) {
    const { expires_in, created_at, refresh_token } = yield select(getAuthentication);

    // if the token has expired, refresh it
    if (
      expires_in !== null &&
      created_at !== null &&
      tokenHasExpired({ expires_in, created_at })
    ) {
      const refreshed = yield call(refreshToken, refresh_token);

      // if the refresh succeeded set the retires to 0
      // if the refresh failed, log a failure
      if (refreshed) {
        // if the token has been refreshed, and their had been retries
        // let the user know everything is okay
        if (retries > 0) {
          // @TODO add hook
        }
        retries = 0;
      } else {
        retries = retries + 1;
      }

      if (retries > 0 && retries < maxRetries) {
        // @TODO add hook
      }

      if (retries === maxRetries) {
        // @TODO add hook
      }
    }

    // check again in 5 seconds
    // this will also replay failed refresh attempts
    yield delay(5000);
  }
}

function * authorize(action) {
  try {
    const { payload: { username, password, grant_type }, meta: { history, from } } = action;

    const params = {
      username,
      password,
      grant_type
    };

    const { data: token } = yield call(apiClient.post, TOKEN_ENDPOINT, params);
    yield put({
      type: LOGIN_USER_SUCCESS,
      payload: { token },
      meta: action.meta
    });
    persistAuth(token);
    history.push(from);
  } catch (error) {
    if (error.response) {
      yield put(loginUserFailed(error.response.data, action.meta));
    } else {
      yield put(loginUserFailed(error, action.meta));
    }
  }
}

export const authStorageKey = 'iaam_auth';

export function * authentication() {
  yield restoreAuth();

  while (true) {
    const { loggedIn } = yield select(getAuthentication);
    var authorizeTask = null;

    if (!loggedIn) {
      const actions = yield race({
        login: take(LOGIN_USER)
      });
      if (actions.login) {
        authorizeTask = yield fork(authorize, actions.login);
      }
    }

    const actions = yield race({
      logout: take(LOGOUT_USER),
      loginError: take(LOGIN_USER_FAILED),
      unauthorized: take(AUTH_INVALID_ERROR),
      refresh: call(refreshLoop)
    });

    if (authorizeTask !== null) {
      yield cancel(authorizeTask);
    }

    if (actions.logout) {
      yield call(logoutUser);
    }
  }
}

function AuthenticationError(msg) {
  this.message = msg;
  this.name = 'AuthenticationError';
}
