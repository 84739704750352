import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import apiClient from '../utils/api';
import { getAuthentication } from '../auth/selectors';
import { useSelector } from 'react-redux';

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PayPalSubscriptionButton = ({ subscriptionPlan, onPaymentSuccess }) => {
  const { access_token } = useSelector(getAuthentication);
  const handlePaymentSuccess = () => {
    onPaymentSuccess();
  };

  return (
    <PayPalButton
      options={{ clientId, vault: true }}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          plan_id: subscriptionPlan.planId,
        });
      }}
      onApprove={async(data, actions) => {
        // Capture the funds from the transaction
        const details = await actions.subscription.get();
        // this is one case where a direct call to api (and not dispatch via saga makes sense
        // since it's not an user triggered action, and it's also a fire and forget -
        // this is necessary since PayPal / react paypal library doesn't provide an easy means to pass custom data
        const payload = { data, details, uiInfo: subscriptionPlan };
        try {
          await apiClient.post(
            'v1/payment/PayPal/createPaymentSubscription', payload, {
              headers: {
                'Authorization': 'Bearer ' + access_token,
              },
            });
          handlePaymentSuccess();
        } catch (err) {
          console.error(err);
          throw(err);
        }
      }}
    />
  );

};

PayPalSubscriptionButton.defaultProps = {
  onPaymentSuccess: () => {},
};

export default PayPalSubscriptionButton;
