import React from 'react';
import { Col, Row } from 'react-bootstrap';
import VideoDisplay from '../../components/VideoDisplay';
import { createUseStyles, useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { getPaymentInfo } from '../../auth/selectors';
import Availability from '../../components/Availability';

const useStyles = createUseStyles({
  videoBox: {
    padding: 10,
    background: "white",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    border: "1px solid #E0E0E0",
    marginRight: 15,
    marginBottom: 10,
    "&:last-of-type": {
      marginRight: 0,
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'space-around',
  },
  videoTitle: {
    fontWeight: "bold",
    fontSize: '1.25rem',
    marginBottom: 5,
    marginTop: 10,
    '& > div': {
      overflowX: 'hidden'
    }
  },
  videoDetailsRow: {
    padding: "0.3rem 0",
    fontSize: '1rem',
    '& > div': {
      overflowX: 'hidden'
    }
  },
  label: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
});

const defaultData = Object.freeze({
  name: 'Big Buck Bunny',
  rate: 25,
  availability: '2020-05-01T00:00:00Z',
  videoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  thumbUrl: 'http://localhost:3000/images/video-placeholder.jpg',
});

const CandidateVideoCard = ({ data = defaultData, additionalFields = [], actionsComponent = null }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { userPaid } = useSelector(getPaymentInfo);
  const { videoUrl, thumbUrl, firstName, lastName, videoId = '', location, rate, availability, categoryTag } = data;
  return (
    <div className={classes.videoBox} data-cy={'videoCard'}>
      <VideoDisplay
        video={{ videoUrl, thumbUrl }}
        requireSubscription={!userPaid}
      />
      <Row className={classes.videoTitle}>
        <Col xs={12} className="text-truncate">{firstName}</Col>
        <Col xs={12} className="text-truncate">{lastName}</Col>
      </Row>
      {videoId !== undefined &&
      <Row className={classes.videoDetailsRow}>
        <Col xs={5} className={classes.label}>Video&nbsp;ID:</Col>
        <Col xs={7}>{videoId.split('-')[0]}</Col>
      </Row>
      }
      {rate !== undefined &&
      <Row className={classes.videoDetailsRow}>
        <Col xs={5} className={classes.label}>Rate:</Col>
        <Col xs={7}>{formatRate(rate)}</Col>
      </Row>
      }
      {location !== undefined &&
      <Row className={classes.videoDetailsRow}>
        <Col xs={5} className={classes.label}>Location:</Col>
        <Col xs={7} className="text-truncate"
             title={location || 'N/A'}>{location || 'N/A'}</Col>
      </Row>
      }
      {availability !== undefined &&
      <Row className={classes.videoDetailsRow}>
        <Col xs={5} className={classes.label}>Availability:</Col>
        <Col xs={7}><Availability value={availability}/></Col>
      </Row>
      }
      {additionalFields.map(({ label, key, render }) => (
        <Row key={key} className={classes.videoDetailsRow}>
          <Col xs={5} className={classes.label}>{label}:</Col>
          <Col xs={7}>{typeof render === 'function' ? render(data[key]) : data[key]}</Col>
        </Row>
      ))}
      <Row className={classes.videoDetailsRow} hidden={!categoryTag}>
        <Col xs={5} className={classes.label}>Category:</Col>
        <Col xs={7}>{categoryTag}</Col>
      </Row>
      <Row className={classes.videoDetailsRow}>
        <Col xs={12}>
          {actionsComponent}
        </Col>
      </Row>
    </div>
  );
};

export function formatRate(rate) {
  return (rate && `$${rate.toFixed(2)} / hour`) || "N/A";
}

export default CandidateVideoCard;
