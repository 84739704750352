import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPaymentInfo } from '../auth/selectors';

const bypassRouteSecurity = process.env.REACT_APP_BYPASS_ROUTE_SECURITY ===
  'true';

const PaymentRoute = ({ children, paymentSecurityOptions, ...rest }) => {
  const { userPaid } = useSelector(getPaymentInfo);
  const { NoPaymentDisplay } = paymentSecurityOptions;

  const determineRender = (props) => {
    const satisfyPaymentSecurity = userPaid;
    if (bypassRouteSecurity || satisfyPaymentSecurity) {
      return children;
    } else {
      return (<NoPaymentDisplay/>);
    }
  };

  return (
    <Route
      {...rest}
      render={props =>
        determineRender(props)
      }
    />
  );
};
PaymentRoute.propTypes = {
  component: PropTypes.oneOfType(
    [PropTypes.func, PropTypes.node, PropTypes.object]),
  location: PropTypes.object,
  paymentSecurityOptions: PropTypes.object,
};
PaymentRoute.defaultProps = {
  paymentSecurityOptions: {},
};
export default PaymentRoute;
