import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    //background: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    borderRadius: '4px',
    width: '40px',
    height: '40px',
    background: 'rgba(227, 73, 28, 0.8)',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: '20px',
    '&:hover': {
      background: '#fb6d42',
    }
  },
  border: {
    background: 'rgba(255, 255, 255, 0.4)',
    height: '80px',
    width: '80px',
    borderRadius: '50%',
  },
});

const StopButton = ({
  children,
  color = 'black',
  backgroundColor = 'white',
  ...props
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <div className={classes.border}>
      <button className={classes.button} {...props}>
        {children}
      </button>
    </div>
  );
};

export default StopButton;