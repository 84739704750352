import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Extracting this to custom component because Form.Switch has quirks that don't apply to checkboxes
 * or radio buttons and not obvious (ie. must define id and label)
 * @param id
 * @param label
 * @param rest
 * @returns {*}
 * @constructor
 */
const Switch = ({ id = 'switch', label = '', ...rest }) => {
  return (
    <Form.Switch id={id} label={label} {...rest}/>
  );
};

export default Switch;