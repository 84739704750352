export const getRating = state => state.candidateDetails.rating;
export const getRate = state => state.candidateDetails.rate;
export const getRateUnit = state => state.candidateDetails.rateUnit;
export const getAvailability = state => state.candidateDetails.availability;
export const getName = state => state.candidateDetails.name;
export const getMainVideo = state => state.candidateDetails.interestsVideoUrl;
export const getLocation = state => state.candidateDetails.location;
export const getCandidateId = state => state.candidateDetails.candidateId;
export const getIsFavorited = state => state.candidateDetails.favorited;
export const getIsShortlisted = state => state.candidateDetails.shortlisted;
