import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import arrowLineLeft from '../../icons/arrow-left-line.svg';

const useStyles = createUseStyles({
  h4: {
    fontWeight: 600,
    marginBottom: 32,
    display: "flex",
    jutifyContent: "center",
    "& img": {
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  offerWrapper: {
    padding: "30px 15px",
  },
  marginBottomRow: {
    marginBottom: "15px",
  },
});

const EmployerOfferConfirmation = () => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });

  function handleGoBack() {
    history.goBack();
  };

  return (
    <Container className={classes.offerWrapper} fluid>
      <Row>
        <Col>
          <h4 className={classes.h4}>
            <img
              alt="arrow-left"
              onClick={handleGoBack}
              src={arrowLineLeft}
              width={16}
            />{" "}
            Offer Made
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
            Offer has been made, We will contact you once the offer is accepted. If you need more information contact <a href="mailto:info@iaam.ca">info@iaam.ca</a>
        </Col>
      </Row>
     <br/>
    </Container>
  );
};

export default EmployerOfferConfirmation;
