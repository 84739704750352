import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  customization: {
    '& .vjs-big-play-button': {
      fontSize: '1em',
      height: '1.5em',
      width: '3em'
    }
  }
});

const VideoDisplay = ({ video, requireSubscription = false }) => {
  const classes = useStyles();
  let node;
  const history = useHistory();
  const { videoUrl, thumbUrl } = video;
  const onClick = requireSubscription
    ? (evt) => {
      evt.preventDefault();
      history.push('/paymentRequest');
    }
    : undefined;
  useEffect(() => {
    if (!videoUrl || !node) return;
    const player = videojs(node, {
      controls: true,
      preload: "none",
    });
    player.src(videoUrl);
    player.poster(thumbUrl);
    return () => player.dispose();
  }, [node, thumbUrl, videoUrl]);
  const handleContextMenu = (evt) => {
    evt.preventDefault();
    return false;
  };
  if (videoUrl) {
    return (
      <div data-vjs-player>
        <video ref={ref => node = ref} onContextMenu={handleContextMenu}
               className={`video-js vjs-16-9 ${classes.customization}`}>
        </video>
      </div>
    );
  } else if (thumbUrl) {
    return <img alt="" width="100%" src={thumbUrl} onClick={onClick}/>;
  }
  return null;
};

export default VideoDisplay;
