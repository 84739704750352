export const isInAllowedRoles = (allowedRoles) => (state) => {
  if (!state.auth.jwt) return false;
  return allowedRoles.some(e => state.auth.jwt.roles.includes(e));
};
export const getUserPermissionsInfo = (requiredPermissions) => (state) => {
  if (!state.auth.jwt) return {
    userHasPermissions: false,
    missingPermissions: requiredPermissions
  };
  const userPermissions = state.auth.jwt.permissions ?? [];
  const missingPermissions = requiredPermissions.filter(e => !userPermissions.includes(e));
  return {
    missingPermissions,
    userHasPermissions: missingPermissions.length === 0
  };
};
export const isLoggedIn = (state) => state.auth.loggedIn;
export const getUserId = (state) => state.auth.jwt && state.auth.jwt.sub;
export const hasRole = role => state => !!state.auth.jwt && state.auth.jwt.roles.includes(role);
export const getNeedToConfirmEmailUserType = state => state.auth.jwt && !state.auth.jwt.emailConfirmed && state.auth.jwt.user_type;

export const paymentStatuses = Object.freeze({
  no_subscription: 'no_subscription',
  active: 'active',
  paymentFailure: 'paymentFailure',
  cancelled: 'cancelled'
});
export const getPaymentInfo = (state) => {
  let result = {
    userPaid: false,
    subscriptionStatus: paymentStatuses.no_subscription
  };
  if (state.auth.jwt && state.auth.jwt.payment) {
    result = state.auth.jwt.payment;
  }
  return result;
};
export const getPaymentStatus = (state) => {
  const transformForDisplay = (subscriptionStatus) => {
    switch (subscriptionStatus) {
      case paymentStatuses.active: {
        return 'ACTIVE';
      }
      case paymentStatuses.paymentFailure: {
        return 'PAYMENT FAILED';
      }
      case paymentStatuses.cancelled: {
        return 'CANCELLED';
      }
      default: {
        return 'INACTIVE';
      }
    }
  };
  let result = {
    userPaid: false,
    subscriptionStatus: paymentStatuses.no_subscription,
    subscriptionStatusText: transformForDisplay(),
    paymentProvider: (state.auth.jwt && state.auth.jwt && state.auth.jwt.paymentProvider) || 'PayPal',
  };
  if (state.auth.jwt && state.auth.jwt && state.auth.jwt.payment) {
    result = { ...result, ...state.auth.jwt.payment };
    result.subscriptionStatusText = transformForDisplay(result.subscriptionStatus);
  }
  return result;
};
export const getAuthentication = (state) => state.auth;
