import React from 'react';
import { getPaymentStatus, paymentStatuses } from '../../auth/selectors';
import { useSelector } from 'react-redux';
import BoldText from '../../components/BoldText';
import TabHeader from '../../components/TabHeader';
import getPaymentProvider from '../../providers/payment';

const PaymentStatus = () => {
  const paymentStatusInfo = useSelector(getPaymentStatus);
  const paymentProvider = getPaymentProvider(paymentStatusInfo.paymentProvider);
  return (
    <section>
      <TabHeader>Subscription status</TabHeader>
      <article>
        <p data-cy={'publishStatus'}>
          Video Published:{' '}
          {paymentStatusInfo.userPaid ? (
            <BoldText>YES</BoldText>
          ) : (
            <BoldText>NO</BoldText>
          )}
        </p>
        <p data-cy={'subscriptionStatus'}>
          Subscription status:{' '}
          <BoldText>{paymentStatusInfo.subscriptionStatusText}</BoldText>
        </p>
      </article>
      <aside data-cy={'subscriptionStatusButton'}>
        {
          paymentStatusInfo.subscriptionStatus ===
          paymentStatuses.active && (
            <>
              <p>
                You have an active subscription.
              </p>
              {paymentProvider.canCancel && paymentProvider.CancelButton}
            </>
          )
        }
        {paymentStatusInfo.subscriptionStatus ===
        paymentStatuses.paymentFailure && (
          <>
            <p>
              Your subscription has been suspended due to payment failure.
            </p>
            {paymentProvider.canUpdate && paymentProvider.UpdateButton}
          </>
        )}
        {paymentStatusInfo.subscriptionStatus === paymentStatuses.cancelled && (
          <>
            <p>
              Your subscription has been cancelled.
            </p>
            {paymentProvider.canRenew && paymentProvider.RenewButton}
          </>
        )}
        {paymentStatusInfo.subscriptionStatus ===
        paymentStatuses.no_subscription && (
          <>
            <p>
              To get offers from employers you must have an active subscription.
            </p>
            {paymentProvider.canSubscribe && paymentProvider.SubscribeButton}
          </>
        )}
      </aside>
    </section>
  );
};

export default PaymentStatus;
