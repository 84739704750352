// Imports: Dependencies
import { all, spawn } from 'redux-saga/effects';

// Imports: Redux Sagas
import {
  authentication,
  watchForceRefresh,
} from './auth/sagas';
import {
  watchConfirmEmail as watchEmployerConfirmEmail,
  watchConfirmEmailSuccess as watchEmployerConfirmEmailSuccess,
  watchRegisterEmployer,
  watchRegisterEmployerSuccess,
  watchMakeOffer,
  watchPopulateEmployerCandidateShortlist,
  employerWatchLoginSuccess,
  watchEmployerFetchCandidateIntroVideos,
  watchModifyEmployerDetails,
  watchFetchMyEmployerProfile,
  watchFetchEmployerOffer,
  watchFetchCandidateProfile, watchFetchEmployerOffers,
} from './pages/employer/sagas';
import {
  watchConfirmEmail as watchCandidateConfirmEmail,
  watchConfirmEmailSuccess as watchCandidateConfirmEmailSuccess,
  watchPopulateCandidateOffers,
  candidateWatchLoginSuccess,
  watchFetchCandidateVideos,
  watchPopulateCandidateOffer,
  watchCandidateAcceptOffer,
  watchPopulateCandidateEmployerShortlist,
  watchFetchCandidate,
  watchModifyCandidateDetails,
  watchCandidateRejectOffer, watchFetchSubscriptionPlanForPromoCode,
} from './pages/candidate/sagas'
import {
  watchFavoriteCandidate,
  watchPopulateCandidateDetails,
  watchRateCandidate,
  watchRateCandidateVideo,
  watchShortlistCandidate, watchUnshortlistCandidate,
} from './pages/candidateDetails/sagas';
import { watchUploadCandidateVideo } from './pages/candidate/sagas';
import { watchRegisterCandidate, watchRegisterCandidateSuccess } from './pages/candidate/sagas';
import {
  watchFetchPendingApprovalVideos,
  watchApproveVideo,
  watchRejectVideo,
  watchFetchCandidates,
  watchFindCandidateByVideoId,
  watchFetchEmployers,
  watchBlockEmployer,
  watchUnblockEmployer,
  watchFetchBillingReport,
  watchDownloadBillingReport,
} from './pages/admin/sagas';
import { watchSubmitContactForm } from './pages/sagas';

// Redux Saga: Root Saga
export function * rootSaga () {
  yield all([
    spawn(authentication),
    spawn(watchForceRefresh),
    spawn(watchEmployerConfirmEmail),
    spawn(watchEmployerConfirmEmailSuccess),
    spawn(watchCandidateConfirmEmail),
    spawn(watchCandidateConfirmEmailSuccess),
    spawn(watchUploadCandidateVideo),
    spawn(watchRegisterEmployer),
    spawn(watchRegisterEmployerSuccess),
    spawn(watchRegisterCandidate),
    spawn(watchRegisterCandidateSuccess),
    spawn(watchFavoriteCandidate),
    spawn(watchPopulateCandidateDetails),
    spawn(watchRateCandidate),
    spawn(watchRateCandidateVideo),
    spawn(watchShortlistCandidate),
    spawn(watchFetchPendingApprovalVideos),
    spawn(watchApproveVideo),
    spawn(watchRejectVideo),
    spawn(watchMakeOffer),
    spawn(watchPopulateCandidateOffers),
    spawn(employerWatchLoginSuccess),
    spawn(candidateWatchLoginSuccess),
    spawn(watchEmployerFetchCandidateIntroVideos),
    spawn(watchFetchCandidateVideos),
    spawn(watchPopulateCandidateOffer),
    spawn(watchCandidateAcceptOffer),
    spawn(watchFetchCandidate),
    spawn(watchModifyCandidateDetails),
    spawn(watchPopulateEmployerCandidateShortlist),
    spawn(watchModifyEmployerDetails),
    spawn(watchFetchMyEmployerProfile),
    spawn(watchPopulateCandidateEmployerShortlist),
    spawn(watchFetchEmployerOffer),
    spawn(watchUnshortlistCandidate),
    spawn(watchFetchCandidateProfile),
    spawn(watchFetchCandidates),
    spawn(watchFindCandidateByVideoId),
    spawn(watchFetchEmployers),
    spawn(watchCandidateRejectOffer),
    spawn(watchFetchEmployerOffer),
    spawn(watchSubmitContactForm),
    spawn(watchFetchEmployerOffers),
    spawn(watchBlockEmployer),
    spawn(watchUnblockEmployer),
    spawn(watchFetchBillingReport),
    spawn(watchDownloadBillingReport),
    spawn(watchFetchSubscriptionPlanForPromoCode),
  ]);
}
