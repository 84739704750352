import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserPermissionsInfo, isLoggedIn } from '../auth/selectors'
import Forbidden from '../pages/Forbidden'
import PaymentRoute from './PaymentRoute'

const bypassRouteSecurity = process.env.REACT_APP_BYPASS_ROUTE_SECURITY ===
  'true'

/**
 *
 * @param Component
 * @param exact
 * @param path
 * @param permissionsRequired
 * @param ForbiddenComponent
 * @param NoAuthenticationComponent
 * @param paymentSecurityOptions - paymentPermissions property dictates
 *   what permissions would be granted had they paid. If all
 *   missing permissions are listed in paymentPermissions,
 *   NoDisplay component would be shown, otherwise Forbidden
 * @param rest
 * @returns {*}
 * @constructor
 */
const PermissionRoute = ({
  children, permissionsRequired = [],
  ForbiddenComponent, paymentSecurityOptions, ...rest
}) => {
  const authenticated = useSelector(isLoggedIn)
  const { userHasPermissions, missingPermissions } = useSelector(getUserPermissionsInfo(permissionsRequired))
  const { paymentPermissions } = paymentSecurityOptions || {}

  const determineRender = (props) => {
    const satisfyPermissions = !permissionsRequired.length || userHasPermissions
    const { location } = props
    if (bypassRouteSecurity) {
      return children
    } else if (authenticated && satisfyPermissions) {
      return children
    } else if (authenticated && paymentSecurityOptions &&
      missingPermissions.every((e) => paymentPermissions.includes(e))) {
      return <PaymentRoute
        paymentSecurityOptions={paymentSecurityOptions} {...props} {...rest}>{children}</PaymentRoute>
    } else if (authenticated) {
      return (<ForbiddenComponent/>)
    }
    if (!location.pathname.includes(`/login`)) {
      return (
        <Redirect
          to={{
            pathname: `/login`,
            state: { from: props.location },
          }}
        />
      )
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        determineRender(props)
      }
    />
  )
}
PermissionRoute.propTypes = {
  location: PropTypes.object,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  ForbiddenComponent: PropTypes.elementType,
  NoAuthenticationComponent: PropTypes.elementType,
}
PermissionRoute.defaultProps = {
  allowedRoles: [],
  ForbiddenComponent: Forbidden,
}
export default PermissionRoute
