import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    // background: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    background: 'rgba(227, 73, 28, 0.8)',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#fb6d42',
    },
  },
  border: {
    background: 'rgba(255, 255, 255, 0.4)',
    height: '80px',
    width: '80px',
    borderRadius: '50%',
    padding: '8px',
  },
  instructions: {
    fontFamily: 'Arial',
    fontSize: '14px',
    color: '#ffffff',
    letterSpacing: '1.75px',
    display: 'flex',
    marginBottom: '20px',
    textTransform: 'uppercase',
  },
  instructionsHighlight: {
    fontWeight: 700,
    color: '#dc6547',
    textTransform: 'uppercase',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const RecordButton = ({
  children,
  color = 'black',
  backgroundColor = 'white',
  ...props
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <div className={classes.wrapper}>
      <div className={classes.instructions}>
        Press
        <span className={classes.instructionsHighlight}>&nbsp;REC&nbsp;</span>
        when ready
      </div>
      <div className={classes.border}>
        <button className={classes.button} {...props}>
          {children}
        </button>
      </div>
    </div>
  );
};

export default RecordButton;