import {
  POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS,
  REGISTER_EMPLOYER_FAILED,
  REGISTER_EMPLOYER_SUCCESS,
  MAKE_OFFER,
  MAKE_OFFER_FAILED,
  MAKE_OFFER_SUCCESS,
  FETCH_EMPLOYER_OFFER,
  FETCH_EMPLOYER_OFFER_SUCCESS,
  FETCH_EMPLOYER_OFFER_FAILED,
  UNSHORTLIST_CANDIDATE_SUCCESS,
} from '../../actionTypes';
import { errorsFromPayload } from '../../utils/errors';

const defaultState = {
  candidates: {
    'id-1': {
      id: 'id-1',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4, // 0 - not given. Lowest rating is a 1.
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-2': {
      id: 'id-2',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-3': {
      id: 'id-3',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-4': {
      id: 'id-4',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-5': {
      id: 'id-5',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-6': {
      id: 'id-6',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
    'id-7': {
      id: 'id-7',
      name: 'Joe Test',
      favorite: false,
      rating: 5,
      interestsVideoRating: 4,
      interestsVideoUrl: 'http:/asdf',
      hobbiesVideoRating: 5,
      hobbiesVideoUrl: 'http:/asdf',
    },
  },
  browseTypes: ['latest', 'acctPayable', 'receptionists', 'favorite'],
  browseList: {
    'latest': ['id-7', 'id-5'],
    'accPayable': ['id-4', 'id-5', 'id-6'],
    'receptionists': ['id-1', 'id-4', 'id-2'],
    'favorite': ['id-1', 'id-6']
  },
  positions: {
    'pos-1': {
      id: 'pos-1',
      title: 'receptionist',
      description: '',
      categories: ['admin', 'clerical'],
      rate: 22,
      rateUnit: 'hr',
      startDate: '2020-04-15',
      shortList: ['id-2', 'id-3'],
      duration: 15 // number of days
    },
    'pos-2': {
      id: 'pos-2',
      title: 'janitor',
      description: '',
      categories: ['maintenance'],
      rate: 16.5,
      rateUnit: 'hr',
      startDate: '2020-04-15',
      shortList: ['id-2', 'id-3'],
      duration: 15 // number of days
    }
  },
  shortlist: [],
  registrationErrors: {},
  makingOffer: false,
  makeOfferErrors: {},
  fetchingOffer: false,
  offer: null,
  fetchOfferError: '',
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS: {
      const shortlist = action.payload.response.data;
      return { ...state, shortlist: shortlist };
    }
    case REGISTER_EMPLOYER_SUCCESS: {
      return { ...state, registrationErrors: {} };
    }
    case REGISTER_EMPLOYER_FAILED: {
      return { ...state, registrationErrors: errorsFromPayload(action.payload) };
    }
    case MAKE_OFFER: {
      return { ...state, makingOffer: true };
    }
    case MAKE_OFFER_SUCCESS: {
      return { ...state, makingOffer: false, makeOfferErrors: {} };
    }
    case MAKE_OFFER_FAILED: {
      return { ...state, makingOffer: false, makeOfferErrors: errorsFromPayload(action.payload) };
    }
    case FETCH_EMPLOYER_OFFER: {
      return {
        ...state,
        fetchingOffer: true,
        offer: action.payload,
        fetchOfferError: '',
      };
    }
    case FETCH_EMPLOYER_OFFER_SUCCESS: {
      return {
        ...state,
        fetchingOffer: false,
        offer: action.payload,
      };
    }
    case FETCH_EMPLOYER_OFFER_FAILED: {
      return {
        ...state,
        fetchingOffer: false,
        fetchOfferError: action.payload,
      };
    }
    case UNSHORTLIST_CANDIDATE_SUCCESS: {
      const { payload: { candidateId } } = action;
      return { ...state, shortlist: state.shortlist.filter(x => x.candidateId !== candidateId) };
    }
    default:
      return state;
  }
}
