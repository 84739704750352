import {
    EMPLOYER_CONFIRM_EMAIL,
    EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS,
    MAKE_OFFER,
    REGISTER_EMPLOYER,
    MODIFY_EMPLOYER_DETAILS,
    POPULATE_EMPLOYER_CANDIDATE_SHORTLIST,
    EMPLOYER_FETCH_SUBSCRIPTION,
    FETCH_MY_EMPLOYER_PROFILE,
    FETCH_EMPLOYER_OFFER,
    EMPLOYER_FETCH_CANDIDATE_PROFILE, FETCH_EMPLOYER_OFFERS, APPLY_PROMO_CODE, FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
} from '../../actionTypes'

export function fetchOffers ({ start, count, status }) {
    return {
        type: FETCH_EMPLOYER_OFFERS,
        payload: { start, count, status }
    };
}

export function confirmEmail({ employerId, code }, { history }) {
    return {
        type: EMPLOYER_CONFIRM_EMAIL,
        payload: { employerId, code },
        meta: { history }
    };
}

export function registerEmployer({ password, firstName, lastName, companyName, emailAddress, acceptedTOS }, { history }) {
    return {
        type: REGISTER_EMPLOYER,
        payload: { password, firstName, lastName, companyName, emailAddress, acceptedTOS },
        meta: { history }
    };
}

export function makeOffer({ employerId, candidateId, proposedHourlyRate, jobTitle, jobDescription, startDate }) {
    return {
        type: MAKE_OFFER,
        payload: { employerId, candidateId, proposedHourlyRate, jobTitle, jobDescription, startDate },
    };
}

export function fetchCandidateIntroVideos(tag, excludeCandidateId, page, pageSize) {
    return {
        type: EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS,
        payload: { tag, excludeCandidateId, page, pageSize }
    };
}

export function modifyEmployerDetails({ firstName, lastName, companyName, emailAddress, jobTitle, address1, address2, city, province, country,
    postalCode, phoneNumber, industry, comments }, { history }) {
    return {
        type: MODIFY_EMPLOYER_DETAILS,
        payload: { firstName, lastName, companyName, emailAddress, jobTitle, address1, address2, city, province, country,
            postalCode, phoneNumber, industry, comments },
        meta: { history }
    };
}

export function populateEmployerCandidateShortlist({ employerId }) {
    return {
        type: POPULATE_EMPLOYER_CANDIDATE_SHORTLIST,
        payload: { employerId }
    };
}

export function fetchEmployerSubscription({ userId, subscriptionId, status }) {
    return {
        type: EMPLOYER_FETCH_SUBSCRIPTION,
        payload: { userId, subscriptionId, status }
    };
}

export function fetchMyEmployerProfile() {
    return {
        type: FETCH_MY_EMPLOYER_PROFILE,
        payload: {},
    };
}

export function fetchEmployerOffer(offerId) {
    return {
        type: FETCH_EMPLOYER_OFFER,
        payload: { offerId },
    };
}

export function fetchCandidateProfile(candidateId) {
    return {
        type: EMPLOYER_FETCH_CANDIDATE_PROFILE,
        payload: { candidateId }
    };
}

export function applyPromoCode({ promoCode }) {
    return {
        type: APPLY_PROMO_CODE,
        payload: {
            promoCode, lastAttempt: Date.now()
        }
    };
}

export function fetchSubscriptionPlanForPromoCode({ promoCode }) {
    return {
        type: FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
        payload: {
            promoCode
        }
    };
}
