import React from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  group: {
    marginTop: 32
  }
});

const FormGroup = props => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (<Form.Group className={classes.group} {...props} />);
};

export default FormGroup;
