import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT, 10),
  headers: {
    'Accept': 'application/json',
  }
});

export default apiClient;
