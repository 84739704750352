import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateOffers } from "./selectors";
import { populateCandidateOffers } from "./actions";
import { Col, Row } from "react-bootstrap";
import OfferItem from "./OfferItem";
import { getUserId } from "../../auth/selectors";
import TabHeader from '../../components/TabHeader';

const CandidateOffers = () => {
  const candidateId = useSelector(getUserId);
  const dispatch = useDispatch();
  const offers = useSelector(getCandidateOffers);

  useEffect(() => {
    dispatch(populateCandidateOffers({ candidateId }));
  }, [dispatch, candidateId]);

  return (
    <section>
      <TabHeader>Your Offers</TabHeader>
      <p data-cy={'offersHeader'}>
        Your job offers will appear here. If you need further assistance, please
        reach out to our Customer Service Team at <a href="mailto:info@iaam.ca">
        info@iaam.ca</a>
      </p>
      <Row>
        {offers.map((item, key) => (
          <Col key={key} md={6} lg={4}>
            <OfferItem item={item}/>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default CandidateOffers;
