import {
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED,
  FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS, LOGOUT_USER_SUCCESS,
} from '../../actionTypes';

const defaultState = Object.freeze({
  isValid: false,
  promoCode: '',
  promoFeedback: '',
  subscriptionPlan: null,
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE: {
      const {promoCode} = action.payload;
      return {...state, promoCode};
    }
    case FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_SUCCESS: {
      const {promoCode, active, paymentProvider, planId, amount, frequency, description} = action.payload;
      return {...state, isValid: true, promoCode,
        promoFeedback: promoCode && active ? 'code applied': '',
        subscriptionPlan: {
          active,
          paymentProvider,
          planId,
          amount,
          frequency, description
        }
      };
    }
    case FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE_FAILED: {
      return {...state, isValid: false, promoFeedback: ''};
    }
    case LOGOUT_USER_SUCCESS: {
      return { ...defaultState };
    }
    default:
      return state;
  }
}
