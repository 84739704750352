import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import { getEmployerCandidateShortlist } from "./selectors";
import { getUserId } from "../../auth/selectors";
import { populateEmployerCandidateShortlist } from "./actions";
import { Col, Row } from "react-bootstrap";
import CandidateVideoCard from "./CandidateVideoCard";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { unshortlistCandidate } from '../candidateDetails/actions';
import TabHeader from '../../components/TabHeader';

const useStyles = createUseStyles({
  button: {
    marginTop: "0.5rem",
    width: "100%",
  },
  candidateCol: {
    marginBottom: 10,
  },
});

const EmployerCandidateShortlist = () => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const employerId = useSelector(getUserId);
  const shortlist = useSelector(getEmployerCandidateShortlist);

  useEffect(() => {
    dispatch(populateEmployerCandidateShortlist({ employerId }));
  }, [dispatch, employerId]);

  const handleMakeAnOffer = (candidateId) => () =>
    history.push("makeOffer?id=" + candidateId);

  const handleViewOffer = (offerId) => () =>
    history.push("offer?id=" + offerId);

  const handleRemove = (employerId, candidateId) => () =>
    dispatch(unshortlistCandidate(employerId, candidateId));

  const actionsComponent = ({ employerId, candidateId, offerId }) => {
    const actions = [
      <Button key="remove"
              className={classes.button}
              onClick={handleRemove(employerId, candidateId)}
              data-cy={'removeFromShortlist'}>
        Remove
      </Button>
    ];
    if (offerId) {
      actions.push(
        <Button key="view-offer"
                className={classes.button}
                onClick={handleViewOffer(offerId)}
                data-cy={'viewOffer'}>
          View Offer
        </Button>
      );
    } else {
      actions.push(
        <Button key="make-offer"
                className={classes.button}
                onClick={handleMakeAnOffer(candidateId)}
                data-cy={'makeOffer'}>
          Make an Offer
        </Button>
      );
    }
    return actions;
  };

  return (
    <section>
      <TabHeader>Your Shortlisted Candidates</TabHeader>
      <Row>
        <Col>
          <p>
            If you need further assistance, please reach out to our Customer
            Service Team at <a href="mailto:info@iaam.ca">info@iaam.ca</a>
          </p>
        </Col>
      </Row>
      <Row>
        {shortlist.map(
          ({ candidateId, offerId, ...rest }) => (
            <Col
              className={classes.candidateCol}
              key={candidateId}
              xs={12} sm={6} lg={4} xl={3}
            >
              <CandidateVideoCard
                data={rest}
                actionsComponent={actionsComponent({ candidateId, offerId, employerId })}
              />
            </Col>
          )
        )}
      </Row>
    </section>
  );
};

export default EmployerCandidateShortlist;
