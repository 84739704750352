import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getCandidateOfferStatusText } from "./selectors";
import { useHistory } from "react-router-dom";
import Button from '../../components/Button';

const useStyles = createUseStyles({
  offerRow: {
    margin: 0,
    padding: "30px 10px",
    background: "white",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    border: "1px solid #E0E0E0",
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  companyNameCol: {
    fontWeight: "bold",
    fontSize: '1.5rem',
    marginBottom: 20,
  },
  label: {
    fontWeight: "bold",
    minWidth: 120,
    display: "inline-block",
    padding: "0 10px 10px 0",
  },
  offerStatusCol: {},
  viewOfferCol: {},
  button: {
    width: "100%",
    marginTop: 10,
  },
  detailsRow: {
    fontSize: '1rem',
  },
});

const OfferItem = ({ item }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const offerStatus = useSelector(getCandidateOfferStatusText(item));
  const offerAccepted = offerStatus === "accepted";

  function handleRedirectToOffer() {
    history.push(`/offer?id=${item.offerId}`);
  }

  const offerId = item.offerId.split('-')[0];

  return (
    <Row className={classes.offerRow} data-cy={'offerItem'}>
      <Col className={`text-truncate ${classes.companyNameCol}`} sm={12} title={item.companyName}>
        {item.companyName}
      </Col>
      <Col className={classes.detailsRow} sm={12}>
        <span className={classes.label}>Offer ID:</span> {offerId}
      </Col>
      <Col className={classes.detailsRow} sm={12}>
        <span className={classes.label}>Job Position:</span> {item.jobTitle}
      </Col>
      <Col className={classes.detailsRow} sm={12}>
        <span className={classes.label}>Hourly rate:</span>&nbsp;${item.proposedHourlyRate}
      </Col>
      <Col className={classes.detailsRow} sm={12}>
        <span className={classes.label}>Starting date:</span> {item.startDate}
      </Col>
      <Col className={classes.detailsRow} sm={12} hidden={!offerAccepted}>
        <span className={classes.label}>Status:</span> Offer Accepted
      </Col>
      <Col className={classes.viewOfferCol} sm={12}>
        <Button className={classes.button} onClick={handleRedirectToOffer} data-cy={'viewOffer'}>
          View Offer
        </Button>
      </Col>
    </Row>
  );
};

export default OfferItem;
