import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useHistory } from "react-router-dom";
import Button from '../../components/Button';

const useStyles = createUseStyles({
  title: {
    fontWeight: "bold",
    fontSize: '1.5rem',
    lineHeight: 1.5,
    margin: 0,
  },
  button: {
    padding: "0.25rem 2rem",
  },
});

const ShortlistItem = ({ item }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <tr>
      <td data-cy={'employerName'}>
        <h4 className={classes.title}>{item.employerName}</h4>
      </td>
      <td hidden={item.offerId === null}>
        <Button
          className={classes.button}
          onClick={() => history.push(`../offer?id=${item.offerId}`)}
          data-cy={'viewOffer'}
        >
          View Offer
        </Button>
      </td>
    </tr>
  );
};

export default ShortlistItem;
