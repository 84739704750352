import React from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  label: {
    fontSize: '1rem',
    lineHeight: 1.36,
    fontWeight: ({ bold }) => bold ? 'bold' : undefined,
  },
  required: {
    color: theme.colorPrimary,
  }
}));

const FormLabel = ({ children, required, bold, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, bold });
  return (<Form.Label className={classes.label} {...props}>
    {children}{required && <span className={classes.required}>&nbsp;*</span>}
  </Form.Label>);
};

export default FormLabel;
