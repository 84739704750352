import React from "react";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  h1: {
    display: 'block',
    fontWeight: 800,
    width: ({ width }) => width,
    color: ({ color }) => color,
    fontSize: '1.75rem',
    lineHeight: 4/3,
    [theme.responsiveXl]: {
      fontSize: '3rem',
    },
  },
  h2: {
    display: 'block',
    fontWeight: "bold",
    width: ({ width }) => width,
    color: ({ color }) => color,
    fontSize: '1.5rem',
    lineHeight: 1.5,
    [theme.responsiveXl]: {
      fontSize: '2rem',
    },
  },
  h3: {
    display: 'block',
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 4/3,
    width: ({ width }) => width,
    color: ({ color }) => color,
    [theme.responsiveXl]: {
      fontSize: '1.5rem',
    },
  },
  h4: {
    display: 'block',
    fontWeight: "bold",
    width: ({ width }) => width,
    color: ({ color }) => color,
    lineHeight: 16/9,
    [theme.responsiveXl]: {
      fontSize: '1rem',
    },
  },
}));

const HeaderText = ({ level, width, color, className, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme, width, color });
  const classNames = [classes["h" + level]];
  if (className) classNames.push(className);
  return <span className={classNames.join(' ')} {...props} />;
};

export default HeaderText;
