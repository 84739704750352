import { select, race, takeLatest, take, takeEvery, call, put } from 'redux-saga/effects';
import {
    EMPLOYER_CONFIRM_EMAIL,
    EMPLOYER_CONFIRM_EMAIL_FAILED,
    EMPLOYER_CONFIRM_EMAIL_SUCCESS,
    REGISTER_EMPLOYER,
    REGISTER_EMPLOYER_SUCCESS,
    REGISTER_EMPLOYER_FAILED,
    POPULATE_EMPLOYER_CANDIDATE_SHORTLIST,
    POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS,
    POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_FAILED,
    MAKE_OFFER,
    MAKE_OFFER_SUCCESS,
    MAKE_OFFER_FAILED,
    MODIFY_EMPLOYER_DETAILS,
    MODIFY_EMPLOYER_DETAILS_SUCCESS,
    MODIFY_EMPLOYER_DETAILS_FAILED,
    AUTH_REFRESH_SUCCESS,
    AUTH_REFRESH_ERROR,
    LOGIN_USER_SUCCESS,
    EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS,
    EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS,
    EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED,
    FETCH_MY_EMPLOYER_PROFILE,
    FETCH_MY_EMPLOYER_PROFILE_SUCCESS,
    FETCH_MY_EMPLOYER_PROFILE_FAILED,
    FETCH_EMPLOYER_OFFER_SUCCESS,
    FETCH_EMPLOYER_OFFER_FAILED,
    FETCH_EMPLOYER_OFFER,
    EMPLOYER_FETCH_CANDIDATE_PROFILE,
    EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED,
    EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS,
    FETCH_EMPLOYER_OFFERS,
    FETCH_EMPLOYER_OFFERS_FAILED, FETCH_EMPLOYER_OFFERS_SUCCESS,
} from '../../actionTypes';
import apiClient from '../../utils/api';
import { authForceRefresh, loginUser } from '../../auth/actions';
import {
    authenticateRequest,
    authenticateRequestWithConfig,
} from '../../auth/sagas';
import { getAuthentication, getUserId } from '../../auth/selectors';

function* confirmEmail(action) {
    const { payload, meta } = action;
    try {
        const { employerId, code } = payload;

        yield authenticateRequest(apiClient.post, 'v1/employer/confirmEmail', { employerId, code });

        yield put({
            type: EMPLOYER_CONFIRM_EMAIL_SUCCESS,
            payload: { employerId, code },
            meta
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: EMPLOYER_CONFIRM_EMAIL_FAILED,
            payload: error,
            meta
        });
    }
}

function hasARole (jwt) {
    if (jwt.roles.length === 0) {
        console.log('User confirmed their email but have no roles yet');
        throw new Error('User confirmed their email but have no roles yet');
    }
}

function * confirmEmailSuccess(action) {
    const { history } = action.meta;
    yield put(authForceRefresh(hasARole));
    //TODO handle auth refresh failure
    yield race({
        success: take(AUTH_REFRESH_SUCCESS),
        error: take(AUTH_REFRESH_ERROR),
    });
    history.push('/');
}

function* registerEmployer(action) {
    let response;
    try {
        const { password, firstName, lastName, companyName, emailAddress, acceptedTOS } = action.payload;

        response = yield call(apiClient.post, 'v1/employer/register', { password, firstName, lastName, companyName, emailAddress, acceptedTOS });

        yield put({
            type: REGISTER_EMPLOYER_SUCCESS,
            payload: { password, firstName, lastName, companyName, emailAddress, acceptedTOS },
            meta: { ...action.meta, response }
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: REGISTER_EMPLOYER_FAILED,
            payload: error,
            meta: { ...action.meta, response }
        });
    }
}

function* modifyEmployerDetails(action) {
    try {
        const {
            firstName, lastName, companyName, emailAddress, jobTitle, address1,
            address2, city, province, country, postalCode, phoneNumber,
            industry, comments,
        } = action.payload;
        const employerId = yield select(getUserId);

        const response = yield authenticateRequest(
            apiClient.post,
            'v1/employer/modifyEmployerProfile',
            {
                employerId, firstName, lastName, companyName, emailAddress,
                jobTitle, address1, address2, city, province, country,
                postalCode, phoneNumber, industry, comments,
            },
        );

        yield put({
            type: MODIFY_EMPLOYER_DETAILS_SUCCESS,
            payload: { ...action.payload, ...response.data },
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: MODIFY_EMPLOYER_DETAILS_FAILED,
            payload: error,
        });
    }
}

export function* watchModifyEmployerDetails() {
    yield takeLatest(MODIFY_EMPLOYER_DETAILS, modifyEmployerDetails);
}

function* registerEmployerSuccess(action) {
    const { emailAddress: username, password } = action.payload;
    const { history } = action.meta;
    const from = '/confirmemail';
    yield put(loginUser({ username, password }, history, from));
}

export function* watchRegisterEmployerSuccess() {
    yield takeLatest(REGISTER_EMPLOYER_SUCCESS, registerEmployerSuccess);
}

export function* watchConfirmEmail() {
    yield takeLatest(EMPLOYER_CONFIRM_EMAIL, confirmEmail);
}

export function* watchConfirmEmailSuccess() {
    yield takeLatest(EMPLOYER_CONFIRM_EMAIL_SUCCESS, confirmEmailSuccess);
}

export function* watchRegisterEmployer() {
  yield takeLatest(REGISTER_EMPLOYER, registerEmployer);
}


function* makeOffer(action) {
    let response;
    try {
        const { employerId, candidateId, proposedHourlyRate, jobTitle, jobDescription, startDate } = action.payload;

        response = yield authenticateRequest(apiClient.post, 'v1/offers/makeoffer', { employerId, candidateId, proposedHourlyRate, jobTitle, jobDescription, startDate });

        yield put({
            type: MAKE_OFFER_SUCCESS,
            payload: { employerId, candidateId, proposedHourlyRate, jobTitle, jobDescription, startDate },
            meta: { response }
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: MAKE_OFFER_FAILED,
            payload: error,
            meta: { response }
        });
    }
}

export function* watchMakeOffer() {
    yield takeLatest(MAKE_OFFER, makeOffer);
}

function * loginEmployer(action) {
    try {
        const { history } = action.meta;
        const { jwt, access_token } = yield select(getAuthentication);
        if (!history) {
            // no page history = refresh, so we don't record the login again
            return;
        }
        const { user_type } = jwt;
        if ( user_type.toLowerCase() !== 'employer' ) {
            return;
        }
        // This call is recording the login for the user type
        yield authenticateRequest(apiClient.post, `v1/employer/login`, { access_token });
    } catch (error) {
        // This is only recording the login, so on failure we don't fail
        console.warn(error);
    }
}

export function * employerWatchLoginSuccess() {
    yield takeLatest(LOGIN_USER_SUCCESS, loginEmployer);
}

function * fetchCandidateIntroVideos(action) {
    const { page, excludeCandidateId, pageSize, tag } = action.payload;
    try {
        const skip = (page - 1) * pageSize;
        const limit = pageSize;
        const { data } = yield authenticateRequestWithConfig(apiClient.get, `v1/employerDashboard/introVideos/${tag}`, {
            params: {
                where: excludeCandidateId ? { candidateId: { neq: excludeCandidateId } } : {},
                skip, limit,
            }
        });
        yield put({
            type: EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_SUCCESS,
            payload: {
                tag,
                ...data
            }
        });
    } catch (error) {
        yield put({
            type: EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS_FAILED,
            payload: { tag, error }
        });
    }
}

export function * watchEmployerFetchCandidateIntroVideos() {
    yield takeEvery(EMPLOYER_FETCH_CANDIDATE_INTRO_VIDEOS, fetchCandidateIntroVideos);
}

function* populateEmployerCandidateShortlist() {
    let response;
    try {
        response = yield authenticateRequest(apiClient.get, 'v1/employer/queryEmployerCandidateShortlist');

        yield put({
            type: POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_SUCCESS,
            payload: { response },
            meta: { response }
        });
    } catch (error) {

        console.error(error);
        yield put({
            type: POPULATE_EMPLOYER_CANDIDATE_SHORTLIST_FAILED,
            payload: { error },
            meta: { response }
        });
    }
}

export function* watchPopulateEmployerCandidateShortlist() {
    yield takeLatest(POPULATE_EMPLOYER_CANDIDATE_SHORTLIST, populateEmployerCandidateShortlist);
}

function * fetchMyEmployerProfile() {
    try {
        const res = yield authenticateRequest(apiClient.get, 'v1/employer/myProfile');
        yield put({
            type: FETCH_MY_EMPLOYER_PROFILE_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        yield put({
            type: FETCH_MY_EMPLOYER_PROFILE_FAILED,
            payload: error,
        });
    }
}

export function * watchFetchMyEmployerProfile() {
    yield takeLatest(FETCH_MY_EMPLOYER_PROFILE, fetchMyEmployerProfile);
}


function * fetchEmployerOffer(action) {
    try {
        const { offerId } = action.payload;

        const result = yield authenticateRequestWithConfig(
          apiClient.get,
          'v1/offers/queryOffer',
          { params: { offerId } },
        );

        yield put({
            type: FETCH_EMPLOYER_OFFER_SUCCESS,
            payload: result.data,
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: FETCH_EMPLOYER_OFFER_FAILED,
            payload: error,
        });
    }
}

export function * watchFetchEmployerOffer() {
    yield takeLatest(FETCH_EMPLOYER_OFFER, fetchEmployerOffer);
}

function * fetchCandidateProfile(action) {
    try {
        const { candidateId } = action.payload;
        const res = yield authenticateRequest(apiClient.get, 'v1/employerCandidateProfile/' + candidateId);
        yield put({
            type: EMPLOYER_FETCH_CANDIDATE_PROFILE_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        yield put({
            type: EMPLOYER_FETCH_CANDIDATE_PROFILE_FAILED,
            payload: error,
        });
    }
}

export function * watchFetchCandidateProfile() {
    yield takeLatest(EMPLOYER_FETCH_CANDIDATE_PROFILE, fetchCandidateProfile);
}

export function * fetchEmployerOffers(action) {
    try {
        const { start, count, status } = action.payload;
        const res = yield authenticateRequestWithConfig(apiClient.get, 'v1/offers/queryEmployerOffers', {
            params: { start, count, status }
        });
        yield put({
            type: FETCH_EMPLOYER_OFFERS_SUCCESS,
            payload: { ...res.data }
        });
    } catch (error) {
        yield put({
            type: FETCH_EMPLOYER_OFFERS_FAILED,
            payload: error
        });
    }
}

export function * watchFetchEmployerOffers() {
    yield takeLatest(FETCH_EMPLOYER_OFFERS, fetchEmployerOffers);
}
