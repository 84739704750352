import { Link } from 'react-router-dom';
import React from 'react';
import BoldLink from './BoldLink';

const NoPaymentDisplay = () => {
  return (
    <div data-cy={'paymentLink'}>Click <Link
        to={'/paymentRequest'}><BoldLink>HERE</BoldLink></Link> to
      subscribe</div>
  );
};

export default NoPaymentDisplay;