import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from './Button';
import StopButton from './StopButton';
import RecordButton from './RecordButton';
import Timer from './Timer';
import Countdown from './Countdown';

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1rem',
    paddingBottom: '2rem',
  },
});

const ActionsView = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStartReplaying,
  onStopReplaying,
  onConfirm
}) => {
  const classes = useStyles();
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return [
        <Button key="play" onClick={onStartReplaying}>
          Play
        </Button>,
        <Button key="use-another-video" onClick={onStopReplaying}
                data-qa='start-replaying'>
          Use another video
        </Button>
      ];
    }

    if (isRecording) {
      return <StopButton onClick={onStopRecording} data-qa='stop-recording' data-cy={'stopRecording'}/>;
    }

    if (isCameraOn && streamIsReady) {
      return (
        <RecordButton onClick={onStartRecording} data-qa='start-recording' data-cy={'startRecording'} />
      );
    }

    if (useVideoInput) {
      return (
        <Button onClick={onOpenVideoInput} data-qa='open-input'>
          Upload a video
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button onClick={onOpenVideoInput} data-qa='open-input'>
        Record a video
      </Button>
    ) : (
      <Button onClick={onTurnOnCamera} data-qa='turn-on-camera' data-cy={'recordVideo'}>
        Turn my camera ON
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <div className={classes.wrapper}>{renderContent()}</div>
    </div>
  );
};

export default ActionsView;