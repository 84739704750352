import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Row, Col, Spinner } from 'react-bootstrap';
import VideoSection from './VideoSection';
import VideoDisplay from '../../components/VideoDisplay';
import { fetchCandidateProfile } from './actions';
import { getCandidateProfile, getEmployerId } from './selectors';
import Button from '../../components/Button';
import HeaderText from '../../components/HeaderText';
import { shortlistCandidate } from '../candidateDetails/actions';
import { getPaymentInfo } from '../../auth/selectors';
import Availability from '../../components/Availability';

function normalizeTag(tag) {
  return tag
  .toLowerCase()
  .replace(/[^A-Z]/gi, "-")
  .replace(/[-]{2,}/gi, "-");
}

const useStyles = createUseStyles(theme => ({
  profileRow: {
    marginBottom: theme.pageVerticalMargin,
  },
  button: {
    width: '100%'
  },
  bio: {
    whiteSpace: 'pre-line',
  },
  label: {
    fontWeight: 'bold',
  },
  card: {
  },
  cardBody: {
    backgroundColor: 'white',
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  }
}));

const CandidateProfileView = ({ candidateId, section })  => {
  const history = useHistory();
  const profileRef = useRef();
  const { addToast } = useToasts();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const employerId = useSelector(getEmployerId);
  const { userPaid } = useSelector(getPaymentInfo);
  const categoryTag = normalizeTag(section);
  const {
    loading, error,
    candidateId: id, videoId, thumbUrl, videoUrl,
    name, bio, rate, location, availability, resumeUrl,
  } = useSelector(getCandidateProfile);
  useEffect(() => {
    dispatch(fetchCandidateProfile(candidateId));
  }, [dispatch, candidateId]);
  useEffect(() => {
    if (profileRef.current) window.scrollTo(0, profileRef.current.offsetTop - 30);
  }, [profileRef, candidateId]);
  useEffect(() => {
    if (error && id === candidateId) {
      addToast(error, { appearance: 'error' });
    }
  }, [error, id, candidateId, addToast]);

  function shortlistProfile() {
    if (!userPaid) {
      return history.push("/paymentRequest");
    }
    dispatch(shortlistCandidate({ employerId, candidateId, categoryTag }, { history }));
  }

  const handleDownloadResume = () => {
    window.open(resumeUrl, '_blank');
  };

  return (
    <>
      <Row ref={profileRef} className={classes.profileRow} data-cy={'candidateProfileView'}>
        <Col xs={12} lg={8}>
          <VideoDisplay
            video={{ thumbUrl, videoUrl }}
            requireSubscription={!userPaid}
          />
        </Col>
        <Col xs={12} lg={4} className={classes.card}>
          <Col xs={12} className={classes.cardBody}>
            {loading
              ? <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              : <p><HeaderText level={3} className="text-truncate">{name}</HeaderText></p>
            }
            <div><p className={classes.bio}>{bio || <i>Candidate did not provide a bio</i>}</p></div>
            <Row>
              <Col className={classes.label} xs={4}>Video ID</Col><Col xs={8}><p>{videoId.split('-')[0]}</p></Col>
              <Col className={classes.label} xs={4}>Rate</Col><Col xs={8}><p>{formatRate(rate)}</p></Col>
              <Col className={classes.label} xs={4}>Location</Col><Col xs={8} className="text-truncate"><p>{location || 'N/A'}</p></Col>
              <Col className={classes.label} xs={4}>Availability</Col><Col xs={8}><p><Availability value={availability}/></p></Col>
            </Row>
            <Button className={classes.button}
                    onClick={shortlistProfile}
                    data-cy={'shortlistCandidate'}>
              Shortlist Candidate
            </Button>
          </Col>
        </Col>
      </Row>
      {resumeUrl &&
      <Row>
        <Col xs={12} lg={4} xl={3}>
          <Button className={classes.button} onClick={handleDownloadResume} data-cy={'resumeDownload'}>
            Download Resume
          </Button>
        </Col>
      </Row>
      }
      {/* For now similar candidates displays the latest videos from the category */}
      <VideoSection section={section} title="Similar Candidates" noNavigation excludeCandidateId={candidateId} />
    </>
  );
};

function formatRate(rate) {
  if (!rate) return 'N/A';
  return `$${rate.toFixed(2)} / hour`;
}

export default CandidateProfileView;
