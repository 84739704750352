import {
  FETCH_CANDIDATE_VIDEOS,
  UPLOAD_CANDIDATE_VIDEO,
  UPLOAD_CANDIDATE_VIDEO_RESET,
  MODIFY_CANDIDATE_DETAILS,
  FETCH_CANDIDATE,
  POPULATE_CANDIDATE_EMPLOYER_SHORTLIST, APPLY_PROMO_CODE, FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
} from '../../actionTypes'
import { REGISTER_CANDIDATE } from "../../actionTypes";

export const CANDIDATE_CONFIRM_EMAIL = 'CANDIDATE_CONFIRM_EMAIL';
export const CANDIDATE_CONFIRM_EMAIL_SUCCESS = 'CANDIDATE_CONFIRM_EMAIL_SUCCESS';
export const CANDIDATE_CONFIRM_EMAIL_FAILED = 'CANDIDATE_CONFIRM_EMAIL_FAILED';

export const POPULATE_CANDIDATE_OFFERS = 'POPULATE_CANDIDATE_OFFERS';
export const POPULATE_CANDIDATE_OFFERS_SUCCESS = 'POPULATE_CANDIDATE_OFFERS_SUCCESS';
export const POPULATE_CANDIDATE_OFFERS_FAILED = 'POPULATE_CANDIDATE_OFFERS_FAILED';

export const POPULATE_CANDIDATE_OFFER = 'POPULATE_CANDIDATE_OFFER';
export const POPULATE_CANDIDATE_OFFER_SUCCESS = 'POPULATE_CANDIDATE_OFFER_SUCCESS';
export const POPULATE_CANDIDATE_OFFER_FAILED = 'POPULATE_CANDIDATE_OFFER_FAILED';

export const ACCEPT_CANDIDATE_OFFER = 'ACCEPT_CANDIDATE_OFFER';
export const ACCEPT_CANDIDATE_OFFER_SUCCESS = 'ACCEPT_CANDIDATE_OFFER_SUCCESS';
export const ACCEPT_CANDIDATE_OFFER_FAILED = 'ACCEPT_CANDIDATE_OFFER_FAILED';

export const REJECT_CANDIDATE_OFFER = 'REJECT_CANDIDATE_OFFER';
export const REJECT_CANDIDATE_OFFER_SUCCESS = 'REJECT_CANDIDATE_OFFER_SUCCESS';
export const REJECT_CANDIDATE_OFFER_FAILED = 'REJECT_CANDIDATE_OFFER_FAILED';

export function acceptCandidateOffer({ offerId }) {
  return {
    type: ACCEPT_CANDIDATE_OFFER,
    payload: { offerId }
  };
}

export function rejectCandidateOffer({ offerId }) {
  return {
    type: REJECT_CANDIDATE_OFFER,
    payload: { offerId }
  };
}

export function populateCandidateOffer({ offerId }) {
  return {
    type: POPULATE_CANDIDATE_OFFER,
    payload: { offerId }
  };
}

export function populateCandidateOffers({ candidateId }) {
  return {
    type: POPULATE_CANDIDATE_OFFERS,
    payload: { candidateId }
  };
}

export function confirmEmail({ candidateId, code }, { history }) {
  return {
    type: CANDIDATE_CONFIRM_EMAIL,
    payload: { candidateId, code },
    meta: { history }
  };
}

export function resetUploadCandidateVideo() {
  return {
    type: UPLOAD_CANDIDATE_VIDEO_RESET
  };
}

export function uploadCandidateVideo (questionId, file, mirrorVideo, onComplete) {
  return {
    type: UPLOAD_CANDIDATE_VIDEO,
    payload: { questionId, file, mirrorVideo },
    meta: { onComplete },
  };
}

export function registerCandidate({ password, firstName, lastName, emailAddress, acceptedTOS, jobCategories }, { history }) {
  return {
    type: REGISTER_CANDIDATE,
    payload: { password, firstName, lastName, emailAddress, acceptedTOS, jobCategories },
    meta: { history }
  };
}

export function fetchCandidateVideos () {
  return {
    type: FETCH_CANDIDATE_VIDEOS,
  };
}

const yesNoToBool = (value) => value === "yes" ? true : value === "no" ? false : null;

export function modifyCandidateDetails({
  candidateId, firstName, lastName, middleName, birthDate, emailAddress,
  address1, address2, city, province, country, postalCode, phoneNumber,
  legalToWorkInCountry, openToRelocate, expectedStartDate, expectedHourlyRate,
  SIN, comments, jobCategories, resumeFile,
}) {
  return {
      type: MODIFY_CANDIDATE_DETAILS,
      payload: {
        candidateId, firstName, lastName, middleName, birthDate, emailAddress,
        address1, address2, city, province, country, postalCode, phoneNumber,
        legalToWorkInCountry: yesNoToBool(legalToWorkInCountry),
        openToRelocate: yesNoToBool(openToRelocate), expectedStartDate,
        expectedHourlyRate, SIN, comments, jobCategories, resumeFile,
      }
  };
}

export function fetchCandidate(candidateId) {
  return {
    type: FETCH_CANDIDATE,
    payload: { candidateId }
  };
}

export function populateCandidateEmployerShortlist({ candidateId }) {
  return {
    type: POPULATE_CANDIDATE_EMPLOYER_SHORTLIST,
    payload: { candidateId }
  };
}

export function applyPromoCode({ promoCode }) {
  return {
    type: APPLY_PROMO_CODE,
    payload: {
      promoCode, lastAttempt: Date.now()
    }
  };
}

export function fetchSubscriptionPlanForPromoCode({ promoCode }) {
  return {
    type: FETCH_SUBSCRIPTION_PLAN_FOR_PROMO_CODE,
    payload: {
      promoCode
    }
  };
}
