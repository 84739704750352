import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from 'react-toast-notifications';
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { isBrowser } from 'react-device-detect';
import { fetchMyEmployerProfile, modifyEmployerDetails } from "./actions";
import FormikErrorFocus from 'formik-error-focus';
import IAAMForm from "../../components/IaamForm";
import {
  getEmployerDetailsValidationSchema,
  getEmployerProfile,
  getEmployerProfileAlert,
} from "./selectors";
import FormLabel from "../../components/FormLabel";
import EmphasisText from "../../components/EmphasisText";
import FormGroup from "../../components/FormGroup";
import FormControl from "../../components/FormControl";
import Button from "../../components/Button";
import { countries, jobCategories, statesOrProvincesPerCountry } from '../../constants';
import TabHeader from '../../components/TabHeader';

const availableIndustriesOptions = jobCategories.map((x) => ({
  text: x,
  value: x,
}));

const availableCountriesOptions = countries.map((x) => ({
  text: x,
  value: x,
}));

const defaultValues = Object.freeze({
  firstName: "",
  lastName: "",
  companyName: "",
  emailAddress: "",
  jobTitle: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  phoneNumber: "",
  industry: "",
  comments: "",
});

const EmployerProfilePage = ({ history }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { loading, profile, modifying, error } = useSelector(
    getEmployerProfile
  );
  const alert = useSelector(getEmployerProfileAlert);
  const [submitted, setSubmitted] = useState(false);
  const saved = !error && !modifying && submitted;
  useEffect(() => {
    dispatch(fetchMyEmployerProfile());
  }, [dispatch]);
  useEffect(() => {
    if (!submitted) return;
    if (saved) {
      addToast('Profile saved.', { appearance: 'success' });
    } else if (alert.visible) {
      addToast(alert.message, { appearance: alert.variant });
    }
  }, [addToast, submitted, saved, alert]);
  if (loading) return <span>Loading...</span>;
  return (
    <section>
      <TabHeader data-cy={'headerText'}>Profile Information</TabHeader>
      <Formik
        validationSchema={getEmployerDetailsValidationSchema}
        onSubmit={(values) => {
          dispatch(modifyEmployerDetails(values, { history }));
          setSubmitted(true);
        }}
        initialValues={{ ...defaultValues, ...profile }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          isInvalid,
          errors,
        }) => (
          <IAAMForm noVerticalMargin onSubmit={handleSubmit} data-cy={'form'}>

            <FormGroup controlId="formFirstName">
              <FormLabel required>First name</FormLabel>
              <FormControl
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.firstName && errors.firstName}
                autoFocus={isBrowser}
                data-cy={'firstName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'firstNameError'}>
                {errors.firstName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formLastName">
              <FormLabel required>Last name</FormLabel>
              <FormControl
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.lastName && errors.lastName}
                data-cy={'lastName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'lastNameError'}>
                {errors.lastName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formCompanyName">
              <FormLabel required>Company Name</FormLabel>
              <FormControl
                type="text"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.companyName && errors.companyName}
                data-cy={'companyName'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'companyNameError'}>
                {errors.companyName}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formJobTitle">
              <FormLabel>Your Job Title</FormLabel>
              <FormControl
                type="text"
                name="jobTitle"
                value={values.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.jobTitle && errors.jobTitle}
              />
              <Form.Control.Feedback type="invalid">
                {errors.jobTitle}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formEmail">
              <FormLabel required>Company Email address</FormLabel>
              <FormControl
                type="email"
                name="emailAddress"
                value={values.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.emailAddress && errors.emailAddress}
                data-cy={'emailAddress'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'emailAddressError'}>
                {errors.emailAddress}
              </Form.Control.Feedback>
              <EmphasisText>name@company.com</EmphasisText>
            </FormGroup>

            <FormLabel>Company Address</FormLabel>

            <FormGroup controlId="formStreetAddress1">
              <FormControl
                type="text"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <EmphasisText>Street Address</EmphasisText>
            </FormGroup>

            <FormGroup controlId="formStreetAddress2">
              <FormControl
                type="text"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <EmphasisText>Street Address Line 2</EmphasisText>
            </FormGroup>

            <FormGroup controlId="formCity">
              <FormLabel required>City</FormLabel>
              <FormControl
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.city && errors.city}
                data-cy={'city'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'cityError'}>
                {errors.city}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formCountry">
              <FormLabel required>Country</FormLabel>
              <FormControl
                as="select"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.country && errors.country}
                data-cy={'countryDropdown'}
              >
                <option value="">-- Choose a country --</option>
                {availableCountriesOptions.map((option) => (
                  <option key={option.text} value={option.value} data-cy={'countryOption'}>
                    {option.text}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'countryError'}>
                {errors.country}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formProvince">
              <FormLabel required>State / Province</FormLabel>
              <FormControl
                as="select"
                name="province"
                value={values.province}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.province && errors.province}
                data-cy={'provinceDropdown'}
              >
                <option value="">-- Choose a state/province --</option>
                {(statesOrProvincesPerCountry[values.country] || []).map((value) => (
                  <option key={value} value={value} data-cy={'provinceOption'}>
                    {value}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'provinceError'}>
                {errors.province}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formPostalCode">
              <FormLabel required>Zip / Postal Code</FormLabel>
              <FormControl
                type="text"
                name="postalCode"
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.postalCode && errors.postalCode}
                data-cy={'postalCode'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'postalCodeError'}>
                {errors.postalCode}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formPhoneNumber">
              <FormLabel required>Phone Number</FormLabel>
              <FormControl
                type="tel"
                name="phoneNumber"
                value={values.phoneNumber}
                placeholder="e.g. 6048123456"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.phoneNumber && errors.phoneNumber}
                data-cy={'phoneNumber'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'phoneNumberError'}>
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formIndustry">
              <FormLabel>Industry</FormLabel>
              <br />
              <FormControl
                as="select"
                name="industry"
                value={values.industry}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.industry && errors.industry}
                data-cy={'industryDropdown'}
              >
                <option value="">-- Choose an industry --</option>
                {availableIndustriesOptions.map((option) => (
                  <option key={option.text} value={option.value} data-cy={'industryOption'}>
                    {option.text}
                  </option>
                ))}
              </FormControl>
              <Form.Control.Feedback type="invalid" data-cy={'industryError'}>
                {errors.industry}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup controlId="formComments">
              <FormLabel>Comments</FormLabel>
              <FormControl
                type="text"
                name="comments"
                value={values.comments}
                as="textarea"
                rows="3"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.comments && errors.comments}
                data-cy={'comments'}
              />
              <Form.Control.Feedback type="invalid" data-cy={'commentsError'}>
                {errors.comments}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup>
              <Button variant="primary" type="submit" disabled={modifying} data-cy={'submit'}>
                Submit
              </Button>
            </FormGroup>

            <FormikErrorFocus offset={-10}
                              align={'top'}
                              focusDelay={200}
                              ease={'linear'}
                              duration={500}/>
          </IAAMForm>
        )}
      </Formik>
    </section>
  );
};

export default EmployerProfilePage;
