import {
  APPROVE_VIDEO,
  APPROVE_VIDEO_FAILED,
  APPROVE_VIDEO_SUCCESS,
  FETCH_PENDING_APPROVAL_VIDEOS,
  FETCH_PENDING_APPROVAL_VIDEOS_FAILED,
  FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS,
  REJECT_VIDEO,
  REJECT_VIDEO_FAILED,
  REJECT_VIDEO_SUCCESS,
} from '../../actionTypes';

const defaultState = {
  fetching: false,
  results: [],
  total: 0,
  error: null,
  posting: false,
};

export default function pendingApprovalVideosReducer(state = defaultState, action) {
  switch (action.type) {
    case "@@INIT": {
      return defaultState;
    }
    case FETCH_PENDING_APPROVAL_VIDEOS: {
      return {
        ...state,
        fetching: true,
        error: null,
        posting: false,
      };
    }
    case FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS: {
      const { results, total } = action.payload;
      return {
        ...state,
        fetching: false,
        results,
        total,
      };
    }
    case FETCH_PENDING_APPROVAL_VIDEOS_FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    }
    case APPROVE_VIDEO: {
      return {
        ...state,
        posting: true,
      };
    }
    case APPROVE_VIDEO_SUCCESS: {
      return {
        ...state,
        results: action.payload,
        posting: false,
      };
    }
    case APPROVE_VIDEO_FAILED: {
      return {
        ...state,
        posting: false,
      };
    }
    case REJECT_VIDEO: {
      return {
        ...state,
        posting: true,
      };
    }
    case REJECT_VIDEO_SUCCESS: {
      return {
        ...state,
        results: action.payload,
        posting: false,
      };
    }
    case REJECT_VIDEO_FAILED: {
      return {
        ...state,
        posting: false,
      };
    }
    default:
      return state;
  }
}
