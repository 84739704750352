import { call, put, takeLatest, select } from 'redux-saga/effects';
import apiClient from '../../utils/api';
import {
  ADMIN_BLOCK_EMPLOYER,
  ADMIN_BLOCK_EMPLOYER_FAILURE,
  ADMIN_BLOCK_EMPLOYER_SUCCESS, ADMIN_DOWNLOAD_BILLING_REPORT,
  ADMIN_FETCH_BILLING_REPORT,
  ADMIN_FETCH_BILLING_REPORT_FAILURE,
  ADMIN_FETCH_BILLING_REPORT_SUCCESS,
  ADMIN_FETCH_CANDIDATES,
  ADMIN_FETCH_CANDIDATES_FAILED,
  ADMIN_FETCH_CANDIDATES_SUCCESS,
  ADMIN_FETCH_EMPLOYERS,
  ADMIN_FETCH_EMPLOYERS_FAILED,
  ADMIN_FETCH_EMPLOYERS_SUCCESS,
  ADMIN_FIND_CANDIDATE_BY_VIDEO_ID,
  ADMIN_UNBLOCK_EMPLOYER,
  ADMIN_UNBLOCK_EMPLOYER_FAILURE,
  ADMIN_UNBLOCK_EMPLOYER_SUCCESS,
  APPROVE_VIDEO,
  APPROVE_VIDEO_FAILED,
  APPROVE_VIDEO_SUCCESS,
  FETCH_PENDING_APPROVAL_VIDEOS,
  FETCH_PENDING_APPROVAL_VIDEOS_FAILED,
  FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS,
  REJECT_VIDEO,
  REJECT_VIDEO_FAILED,
  REJECT_VIDEO_SUCCESS,
} from '../../actionTypes';
import {
  authenticateRequest,
  authenticateRequestWithConfig,
} from '../../auth/sagas';
import { getPendingApprovalVideoList } from './selectors';
import { getAuthentication } from '../../auth/selectors'

export function * fetchPendingApprovalVideos (action) {
  const { payload } = action;
  try {
    const { page, pageSize } = payload;
    const res = yield authenticateRequestWithConfig(apiClient.get, '/v1/candidateVideo/query', {
      params: {
        'where[status]': 'pendingApproval',
        order: 'uploaded_at ASC',
        skip: (page - 1) * pageSize,
        limit: pageSize,
        paging: true,
      },
    });
    yield put({
      type: FETCH_PENDING_APPROVAL_VIDEOS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: FETCH_PENDING_APPROVAL_VIDEOS_FAILED,
      payload: error.message,
    });
  }
}

export function * watchFetchPendingApprovalVideos () {
  yield takeLatest(FETCH_PENDING_APPROVAL_VIDEOS, fetchPendingApprovalVideos);
}

export function * approveVideo (action) {
  const { payload } = action;
  try {
    const { videoId } = payload;
    const pendingVideos = yield select(getPendingApprovalVideoList);
    yield authenticateRequest(apiClient.post, `/v1/candidateVideo/approve`, { videoId });
    yield put({
      type: APPROVE_VIDEO_SUCCESS,
      payload: pendingVideos.filter(x => x.videoId !== videoId),
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: APPROVE_VIDEO_FAILED,
      payload: error,
    });
  }
}

export function * watchApproveVideo () {
  yield takeLatest(APPROVE_VIDEO, approveVideo);
}

export function * rejectVideo (action) {
  const { payload } = action;
  try {
    const { videoId, reason } = payload;
    const pendingVideos = yield select(getPendingApprovalVideoList);
    yield authenticateRequest(apiClient.post, `/v1/candidateVideo/reject`, { videoId, reason });
    yield put({
      type: REJECT_VIDEO_SUCCESS,
      payload: pendingVideos.filter(x => x.videoId !== videoId),
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: REJECT_VIDEO_FAILED,
      payload: error,
    });
  }
}

export function * watchRejectVideo () {
  yield takeLatest(REJECT_VIDEO, rejectVideo);
}

export function * fetchCandidates (action) {
  const { payload } = action;
  try {
    const { page, pageSize } = payload;
    const res = yield authenticateRequestWithConfig(
      apiClient.get,
      `/v1/candidate/query`,
      {
        params: {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          paging: true,
        }
      },
    );
    yield put({
      type: ADMIN_FETCH_CANDIDATES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_FETCH_CANDIDATES_FAILED,
      payload: error,
    });
  }
}

export function * watchFetchCandidates () {
  yield takeLatest(ADMIN_FETCH_CANDIDATES, fetchCandidates);
}

export function * findCandidateByVideoId (action) {
  const { payload } = action;
  try {
    const { videoId } = payload;
    if (!videoId) return;
    const videoRes = yield authenticateRequestWithConfig(
      apiClient.get,
      `/v1/candidateVideo/query`,
      {
        params: {
          "where[videoId][ilike]": `${videoId}%`,
        }
      },
    );
    const video = videoRes.data[0];
    const candidateId = video && video.candidateId;
    if (!candidateId) {
      yield put({
        type: ADMIN_FETCH_CANDIDATES_SUCCESS,
        payload: { results: [], total: 0 },
      });
      return;
    }
    const candidateRes = yield authenticateRequestWithConfig(
      apiClient.get,
      `/v1/candidate/query`,
      {
        params: {
          "where[candidateId]": candidateId,
          skip: 0,
          limit: 1,
          paging: true,
        }
      },
    );
    yield put({
      type: ADMIN_FETCH_CANDIDATES_SUCCESS,
      payload: candidateRes.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_FETCH_CANDIDATES_FAILED,
      payload: error,
    });
  }
}

export function * watchFindCandidateByVideoId () {
  yield takeLatest(ADMIN_FIND_CANDIDATE_BY_VIDEO_ID, findCandidateByVideoId);
}


export function * fetchEmployers (action) {
  const { payload } = action;
  try {
    const { page, pageSize } = payload;
    const res = yield authenticateRequestWithConfig(
      apiClient.get,
      `/v1/employer/query`,
      {
        params: {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          paging: true,
        }
      },
    );
    yield put({
      type: ADMIN_FETCH_EMPLOYERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_FETCH_EMPLOYERS_FAILED,
      payload: error,
    });
  }
}

export function * watchFetchEmployers () {
  yield takeLatest(ADMIN_FETCH_EMPLOYERS, fetchEmployers);
}

export function * blockEmployer(action) {
  const { payload } = action;
  try {
    yield call(authenticateRequest, apiClient.post, `/v1/employer/block`, payload);
    yield put({
      type: ADMIN_BLOCK_EMPLOYER_SUCCESS,
      payload: { ...payload, message: 'Employer blocked.' }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_BLOCK_EMPLOYER_FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function * watchBlockEmployer () {
  yield takeLatest(ADMIN_BLOCK_EMPLOYER, blockEmployer);
}

export function * unblockEmployer(action) {
  const { payload } = action;
  try {
    yield call(authenticateRequest, apiClient.post, `/v1/employer/unblock`, payload);
    yield put({
      type: ADMIN_UNBLOCK_EMPLOYER_SUCCESS,
      payload: { ...payload, message: 'Employer unblocked.' }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_UNBLOCK_EMPLOYER_FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function * watchUnblockEmployer () {
  yield takeLatest(ADMIN_UNBLOCK_EMPLOYER, unblockEmployer);
}

function * fetchBillingReport (action) {
  const { payload } = action;
  try {
    const res = yield call(authenticateRequestWithConfig, apiClient.get, `/v1/reports/billingReport/query`, {
      params: payload,
    });
    yield put({
      type: ADMIN_FETCH_BILLING_REPORT_SUCCESS,
      payload: res.data,
    });
  } catch(error) {
    console.error(error);
    yield put({
      type: ADMIN_FETCH_BILLING_REPORT_FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function * watchFetchBillingReport () {
  yield takeLatest(ADMIN_FETCH_BILLING_REPORT, fetchBillingReport);
}

function * downloadBillingReport (action) {
  const { payload: { fromDate, toDate } } = action;
  const { access_token } = yield select(getAuthentication);
  window.open(`${apiClient.defaults.baseURL}/v1/reports/billingReport/download?fromDate=${fromDate}&toDate=${toDate}&access_token=${access_token}`);
}

export function * watchDownloadBillingReport () {
  yield takeLatest(ADMIN_DOWNLOAD_BILLING_REPORT, downloadBillingReport);
}
