import {
  UPLOAD_CANDIDATE_VIDEO, UPLOAD_CANDIDATE_VIDEO_FAILED,
  UPLOAD_CANDIDATE_VIDEO_PROGRESS,
  UPLOAD_CANDIDATE_VIDEO_SUCCESS, UPLOAD_CANDIDATE_VIDEO_RESET
} from "../../actionTypes";

const defaultState = {
  error: '',
  progress: 0,
  uploading: false,
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case UPLOAD_CANDIDATE_VIDEO_RESET: {
      return defaultState;
    }
    case UPLOAD_CANDIDATE_VIDEO: {
      return {
        error: '',
        progress: 0,
        uploading: true,
      };
    }
    case UPLOAD_CANDIDATE_VIDEO_PROGRESS: {
      return {
        ...state,
        progress: action.payload.percentage
      };
    }
    case UPLOAD_CANDIDATE_VIDEO_SUCCESS: {
      return {
        ...state,
        progress: 100,
        uploading: false,
      };
    }
    case UPLOAD_CANDIDATE_VIDEO_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        uploading: false,
      };
    }
    default:
      return state;
  }
}
