import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import React from 'react';
import RegisterCandidate from './RegisterCandidate';
import ConfirmEmail from './ConfirmEmail';
import CandidateDashboard from './CandidateDashboard';
import PaymentRequest from './PaymentRequest';
import RoleAndPaymentSecuredRoute from '../../components/RoleAndPaymentSecuredRoute';
import NotFound from '../NotFound';
import CandidateOffers from './CandidateOffers';
import CandidateViewOffer from './CandidateViewOffer';
import CandidateOfferConfirmation from './CandidateOfferConfirmation';
import NoPaymentDisplay from '../../components/NoPaymentDisplay';
import CandidateEmployerShortlist from './CandidateEmployerShortlist';
import LoginPage from '../LoginPage';

const allowedRoles = ['candidate'];

const candidateRoutes = () => {
  return (
    <Router basename="/candidate">
      <Switch>
        {/* DO NOT REMOVE */}
        <Route exact path="/login" component={LoginPage}/>
        <RoleAndPaymentSecuredRoute path="/confirmemail" component={ConfirmEmail}/>
        <RoleAndPaymentSecuredRoute path="/offers" component={CandidateOffers} allowedRoles={allowedRoles} paymentSecurityOptions={{ requiresPayment: true, NoPaymentDisplay: NoPaymentDisplay }}/>
        <RoleAndPaymentSecuredRoute path="/offer" component={CandidateViewOffer} allowedRoles={allowedRoles} paymentSecurityOptions={{ requiresPayment: true, NoPaymentDisplay: NoPaymentDisplay }}/>
        <RoleAndPaymentSecuredRoute path="/offerconfirmation" component={CandidateOfferConfirmation} allowedRoles={allowedRoles} paymentSecurityOptions={{ requiresPayment: true, NoPaymentDisplay: NoPaymentDisplay }}/>
        <RoleAndPaymentSecuredRoute path={'/paymentrequest'} allowedRoles={allowedRoles} component={PaymentRequest}/>
        <RoleAndPaymentSecuredRoute path={'/dashboard'} allowedRoles={allowedRoles} component={CandidateDashboard}/>
	      <RoleAndPaymentSecuredRoute path="/shortlist" allowedRoles={allowedRoles} component={CandidateEmployerShortlist}/>
        <Route path="/register" component={RegisterCandidate}/>
        <Route component={NotFound}/>
      </Switch>
    </Router>
  );
};

export default candidateRoutes;


