import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import HeaderText from '../components/HeaderText';

const useStyles = createUseStyles(theme => ({
  notFound: {
    margin: theme.pageVerticalMargin + ' auto',
  },
}));

const NotFound = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.notFound}>
      <HeaderText level={1}>Page not found.</HeaderText>
    </div>
  );
};

export default NotFound;
