import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  italic: {
    fontStyle: 'italic',
  },
});

const EmphasisText = ({ children }) => {
  const classes = useStyles();
  return (
    <em className={classes.italic}>
      {children}
    </em>
  );
};

export default EmphasisText;