import {
  FETCH_MY_EMPLOYER_PROFILE,
  FETCH_MY_EMPLOYER_PROFILE_FAILED,
  FETCH_MY_EMPLOYER_PROFILE_SUCCESS,
  MODIFY_EMPLOYER_DETAILS, MODIFY_EMPLOYER_DETAILS_FAILED,
  MODIFY_EMPLOYER_DETAILS_SUCCESS,
} from '../../actionTypes';

const defaultState = {
  loading: false,
  profile: null,
  error: null,
  modifying: false,
};

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case FETCH_MY_EMPLOYER_PROFILE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FETCH_MY_EMPLOYER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    }
    case FETCH_MY_EMPLOYER_PROFILE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case MODIFY_EMPLOYER_DETAILS: {
      return {
        ...state,
        modifying: true,
        error: '',
      };
    }
    case MODIFY_EMPLOYER_DETAILS_SUCCESS: {
      return {
        ...state,
        modifying: false,
        profile: action.payload,
      };
    }
    case MODIFY_EMPLOYER_DETAILS_FAILED: {
      return {
        ...state,
        modifying: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}