import React from 'react';

const PaymentProcessedNotifier = ({ userPaid }) => {
  return (
    <>
      {userPaid ? <h2>Payment Success!</h2> : <h2>Payment Processing. Please do not leave this page ...</h2>}
    </>
  );
};

export default PaymentProcessedNotifier;