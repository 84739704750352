import { combineReducers } from 'redux';
import auth from './auth/reducer';
import appUpdate from './appUpdate/reducer';
import employerProfile from './pages/employer/profileReducer';
import employer from './pages/employer/reducer';
import candidate from './pages/candidate/reducer';
import employerConfirmEmail from './pages/employer/confirmEmailReducer';
import candidateConfirmEmail from './pages/candidate/confirmEmailReducer';
import candidateVideoUpload from './pages/candidate/uploadVideoReducer';
import candidateDetails from './pages/candidateDetails/candidateDetailsReducer';
import pendingApprovalVideos from './pages/admin/pendingApprovalVideosReducer';
import employerDashboard from './pages/employer/dashboardReducer';
import candidatePersonalDetails from './pages/candidate/personalDetailsReducer';
import employerCandidateProfile from './pages/employer/candidateProfileReducer';
import adminCandidates from './pages/admin/candidatesReducer';
import adminEmployers from './pages/admin/employersReducer';
import contactForm from './pages/contactFormReducer';
import employerOffers from './pages/employer/offersReducer';
import billingReport from './pages/admin/billingReportReducer';
import promoInfo from './pages/candidate/promoInfoReducer';

export default combineReducers({
  auth,
  appUpdate,
  employer,
  employerProfile,
  employerConfirmEmail,
  candidate,
  candidateConfirmEmail,
  candidateVideoUpload,
  candidateDetails,
  pendingApprovalVideos,
  employerDashboard,
  candidatePersonalDetails,
  employerCandidateProfile,
  adminCandidates,
  adminEmployers,
  contactForm,
  employerOffers,
  billingReport,
  promoInfo,
});
