import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import { getCandidateEmployerShortlist } from "./selectors";
import { getUserId } from "../../auth/selectors";
import { populateCandidateEmployerShortlist } from "./actions";
import ShortlistItem from "./ShortlistItem";
import { Col, Row, Table } from 'react-bootstrap';
import TabHeader from '../../components/TabHeader';

const useStyles = createUseStyles({
  table: {
    width: 'inherit',
  },
});

const CandidateEmployerShortlist = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const candidateId = useSelector(getUserId);
  const shortlist = useSelector(getCandidateEmployerShortlist);

  useEffect(() => {
    dispatch(populateCandidateEmployerShortlist({ candidateId }));
  }, [dispatch, candidateId]);

  return (
    <section>
      <TabHeader>You&apos;ve been shortlisted by the following employers:</TabHeader>
      {shortlist.length === 0 && <Row><Col data-cy={'noEmployers'}>No employers have shortlisted you yet. Check back later.</Col></Row>}
      <Table borderless className={classes.table}>
        <tbody>
        {shortlist.map((item, key) => (
          <ShortlistItem item={item} key={key} />
        ))}
        </tbody>
      </Table>
    </section>
  );
};

export default CandidateEmployerShortlist;
