import {
  FETCH_EMPLOYER_OFFERS, FETCH_EMPLOYER_OFFERS_FAILED,
  FETCH_EMPLOYER_OFFERS_SUCCESS,
} from '../../actionTypes';
import { errorMessageFromPayload } from '../../utils/errors';

const defaultState = {
  loading: false,
  error: '',
  data: [],
  total: 0
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_EMPLOYER_OFFERS: {
      return { ...state, loading: true, error: '' };
    }
    case FETCH_EMPLOYER_OFFERS_SUCCESS: {
      const { results: data, total } = action.payload;
      return { ...state, loading: false, data, total };
    }
    case FETCH_EMPLOYER_OFFERS_FAILED: {
      return { ...state, loading: false, error: errorMessageFromPayload(action.payload) };
    }
    default: {
      return state;
    }
  }
}
