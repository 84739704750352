import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { getCandidateOffer, getShowAcceptedOfferConfirm } from "./selectors";
import {
  acceptCandidateOffer,
  populateCandidateOffer,
  rejectCandidateOffer,
} from './actions';
import arrowLineLeft from "../../icons/arrow-left-line.svg";
import { Redirect, useHistory } from 'react-router-dom';
import Button from '../../components/Button';

const useStyles = createUseStyles({
  button: {
    padding: "0.25em 2em",
    marginTop: "2em",
    "&:not(:first-child)": {
      marginLeft: "1em",
    }
  },
  h4: {
    fontWeight: 600,
    marginBottom: 32,
    display: "flex",
    jutifyContent: "center",
    "& img": {
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  offerWrapper: {
    padding: "30px 15px",
  },
  marginBottomRow: {
    marginBottom: "15px",
  },
});

const CandidateViewOffer = ({ location }) => {
  const history = useHistory();
  const theme = useTheme();
  const query = new URLSearchParams(location.search);
  const offerId = query.get("id");
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const offer = useSelector(getCandidateOffer);
  const showAcceptedOfferConfirm = useSelector(getShowAcceptedOfferConfirm);

  useEffect(() => {
    dispatch(populateCandidateOffer({ offerId }));
  }, [dispatch, offerId]);

  function handleAcceptOffer() {
    dispatch(acceptCandidateOffer({ offerId }));
  }
  function handleRejectOffer() {
    dispatch(rejectCandidateOffer({ offerId }));
  }

  function handleGoBack() {
    history.goBack();
  }

  const offerAccepted = offer && offer.offerStatus === "accepted";
  if (offerAccepted && showAcceptedOfferConfirm){
    const offerConfirmationUrl = `/offerconfirmation?id=${offerId}`;
    return <Redirect to={offerConfirmationUrl} />;
  }

  const offerRejected = offer && offer.offerStatus === "rejected";
  const offerIdDisplay = offerId.split('-')[0];
  const jobDescriptionLines =
    offer.jobDescription && offer.jobDescription.length
      ? offer.jobDescription.split("\n")
      : [];

  return (
    <Container className={classes.offerWrapper} fluid data-cy={'offerDetails'}>
      <Row>
        <Col>
          <h4 className={classes.h4}>
            <img
              alt="arrow-left"
              onClick={handleGoBack}
              src={arrowLineLeft}
              width={16}
            />
            {" "}
            Offer by {offer.companyName}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Offer ID: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offerIdDisplay}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Job Title: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offer.jobTitle}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Offered Rate: </strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>${offer.proposedHourlyRate} / hr</Col>
      </Row>
      <Row>
        <Col>
          <strong>Expected Start Date:</strong>
        </Col>
      </Row>
      <Row className={classes.marginBottomRow}>
        <Col>{offer.startDate}</Col>
      </Row>
      <Row>
        <Col>
          <strong>Description:</strong>
        </Col>
      </Row>
      <Row>
        <Col className="text-break">
          {jobDescriptionLines.map((line, idx) =>
            <React.Fragment key={idx}>
              {line}<br />
            </React.Fragment>
          )}
        </Col>
      </Row>
      <Row>
        <Col hidden={offerAccepted || offerRejected}>
          <Button className={classes.button} onClick={handleAcceptOffer} data-cy={'acceptOffer'}>
            Accept
          </Button>
          <Button className={classes.button} onClick={handleRejectOffer} data-cy={'rejectOffer'}>
            Reject
          </Button>
        </Col>
      </Row><br/>
      <Alert variant={"info"} show={offerRejected} data-cy={'offerRejectedAlert'}>Offer rejected.</Alert>
    </Container>
  );
};

export default CandidateViewOffer;
