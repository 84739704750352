import { errorMessageFromPayload } from '../../utils/errors';
import { CANDIDATE_CONFIRM_EMAIL, CANDIDATE_CONFIRM_EMAIL_FAILED, CANDIDATE_CONFIRM_EMAIL_SUCCESS } from './actions';

const defaultState = {
  confirming: false,
  error: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CANDIDATE_CONFIRM_EMAIL: {
      return { ...state, confirming: true, error: '' };
    }
    case CANDIDATE_CONFIRM_EMAIL_SUCCESS: {
      return { ...state, confirming: false };
    }
    case CANDIDATE_CONFIRM_EMAIL_FAILED: {
      return { ...state, confirming: false, error: errorMessageFromPayload(action.payload) };
    }
    default:
      return state;
  }
}
