import React from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles(theme => ({
  control: {
    border: 'solid 1px #121A45',
    borderRadius: '0'
  }
}));

const FormControl = ({ className, ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const classNames = [classes.control];
  if (className) classNames.push(className);
  return (<Form.Control className={classNames} {...props} />);
};

export default FormControl;
