import React from "react";
import { createUseStyles, useTheme } from 'react-jss';
import { Tab, Tabs } from 'react-bootstrap';
import VideoApprovalPage from './VideoApprovalPage';
import CandidatesPage from './CandidatesPage';
import EmployersPage from './EmployersPage';
import BillingReportPage from './BillingReportPage';
import { isHospitalityNetwork } from '../../config';

const hasBillingReport = isHospitalityNetwork;

const useStyles = createUseStyles(theme => ({
  tabs: {
    marginTop: theme.pageVerticalMargin,
    marginBottom: theme.pageVerticalMargin,
    border: 0,
    '& .nav-link': {
      textTransform: 'uppercase',
      color: theme.colorText,
      fontWeight: 'bold',
      border: 0,
      background: 'transparent',
      padding: ['1px 1em', '!important'],
      '&:first-child': {
        paddingLeft: 0,
      },
      '&+.nav-link': {
        borderLeft: 'solid 1px gray',
        borderRadius: 0,
      },
      '&.active': {
        color: theme.colorText,
        backgroundColor: 'transparent',
        '& > span': {
          borderBottom: 'solid 2px ' + theme.colorPrimary,
        }
      },
      '&:hover': {
        color: theme.colorPrimary,
      }
    }
  },
}));

const AdminDashboard = ({ history, location }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const key = location.pathname.split('/')[1] || 'videoApproval';
  return (
    <Tabs className={classes.tabs} id="admin-dashboard-tabs" activeKey={key}
          onSelect={key => history.push('/' + key)}
          mountOnEnter={false} unmountOnExit={true}>
      <Tab eventKey="videoApproval" title={<span>Video Approval</span>}>
        <VideoApprovalPage />
      </Tab>
      <Tab eventKey="candidates" title={<span>Candidates</span>}>
        <CandidatesPage />
      </Tab>
      <Tab eventKey="employers" title={<span>Employers</span>}>
        <EmployersPage />
      </Tab>
      {hasBillingReport &&
      <Tab eventKey="billing-report" title={<span>Billing Report</span>}>
        <BillingReportPage />
      </Tab>
      }
    </Tabs>
  );
};

export default AdminDashboard;
